import { dollarToCents, stringIsEmpty, stringNotEmpty, useSiteContext } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { EscapedHtmlSpan, Skeleton, Text, useTheme } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import type { FormValues } from "../../../../../lib/formValidation";
import { ValuePills } from "../../../../../components/ValuePills";
import {
  BookableResourceTypeDisplayMode,
  DestinationTierLevel,
  useBookableResourceTypesQuery,
} from "../../../../../generated/gateway-client";
import { resourceTypeToString } from "../../../../../lib/resourceTypeToString";
import { CurrencyAmount } from "../../../../../lib/CurrencyAmount";
import { convertInputMoneyToNumber } from "../../../../../lib/convertInputMoneyToNumber";

// eslint-disable-next-line complexity
export const ResourceCreateAndEditFormPreviewOverview: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const { colors, spacers } = useTheme(true);
  const site = useSiteContext();

  const { values } = useFormikContext<FormValues>();

  const { data, loading, error } = useBookableResourceTypesQuery({
    variables: { displayMode: BookableResourceTypeDisplayMode.Eq1 },
    fetchPolicy: "cache-and-network",
  });

  const selectedResourceType = (data?.bookableResourceTypes ?? []).find((rt) => rt.uuid === values.typeV2);

  const paymentRateHourly = useMemo(
    () => dollarToCents(convertInputMoneyToNumber(values.paymentRateHourly, true)) ?? 0,
    [values.paymentRateHourly],
  );
  const paymentRateHalfDay = useMemo(
    () => dollarToCents(convertInputMoneyToNumber(values.paymentRateHalfDay, true)) ?? 0,
    [values.paymentRateHalfDay],
  );
  const paymentRateFullDay = useMemo(
    () => dollarToCents(convertInputMoneyToNumber(values.paymentRateFullDay, true)) ?? 0,
    [values.paymentRateFullDay],
  );
  const paymentRateHourlyAfterHours = useMemo(
    () => dollarToCents(convertInputMoneyToNumber(values.paymentRateHourlyAfterHours, true)) ?? 0,
    [values.paymentRateHourlyAfterHours],
  );
  const paymentRateHourlyWeekend = useMemo(
    () => dollarToCents(convertInputMoneyToNumber(values.paymentRateHourlyWeekend, true)) ?? 0,
    [values.paymentRateHourlyWeekend],
  );

  const displayCapacity = useMemo(() => Number(values.displayCapacity ?? 0), [values.displayCapacity]);

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  if (
    paymentRateHourly === 0 &&
    paymentRateHalfDay === 0 &&
    paymentRateFullDay === 0 &&
    paymentRateHourlyAfterHours === 0 &&
    paymentRateHourlyWeekend === 0 &&
    (loading || selectedResourceType == null) &&
    displayCapacity === 0 &&
    stringIsEmpty(values.description)
  ) {
    return <Skeleton.Line width="100%" height="133px" />;
  }

  return (
    <div className="overview-cont">
      <Text variant="heading" size="small" className="mb-3">
        {t("bookings.resources.overview")}
      </Text>

      {site.tierLevel !== DestinationTierLevel.Essentials && (
        <div className="rates-body">
          {paymentRateHourly > 0 && (
            <div>
              <CurrencyAmount amount={paymentRateHourly} buildingUuid={values.building} className="font-weight-bold" />{" "}
              <span>/ {t("common.hour")}</span>
            </div>
          )}
          {paymentRateHalfDay > 0 && (
            <div>
              <CurrencyAmount amount={paymentRateHalfDay} buildingUuid={values.building} className="font-weight-bold" />{" "}
              <span>/ {t("common.halfDay")}</span>
            </div>
          )}
          {paymentRateFullDay > 0 && (
            <div>
              <CurrencyAmount amount={paymentRateFullDay} buildingUuid={values.building} className="font-weight-bold" />{" "}
              <span>/ {t("common.fullDay")}</span>
            </div>
          )}
          {paymentRateHourlyAfterHours > 0 && (
            <div>
              <CurrencyAmount
                amount={paymentRateHourlyAfterHours}
                buildingUuid={values.building}
                className="font-weight-bold"
              />{" "}
              <span>/ {t("common.hourAfterHours")}</span>
            </div>
          )}
          {paymentRateHourlyWeekend > 0 && (
            <div>
              <CurrencyAmount
                amount={paymentRateHourlyWeekend}
                buildingUuid={values.building}
                className="font-weight-bold"
              />{" "}
              <span>/ {t("common.hourWeekend")}</span>
            </div>
          )}
        </div>
      )}

      <ValuePills>
        {selectedResourceType != null && (
          <span className="resource-type-pill">{resourceTypeToString(selectedResourceType.name, t)}</span>
        )}
        {displayCapacity > 0 && (
          <span className="display-capacity-pill">
            {t("bookings.resources.personCapacity", { count: displayCapacity })}
          </span>
        )}
      </ValuePills>

      {stringNotEmpty(values.description) && (
        <div className="description">
          <EscapedHtmlSpan html={values.description} />
        </div>
      )}

      <style jsx>{`
        .rates-body {
          display: flex;
          gap: ${spacers.s0} ${spacers.s6};
          flex-wrap: wrap;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.5em;
        }
        .overview-cont :global(.value-pills) {
          color: ${colors.grayscale[60]};
          font-weight: 300;
          line-height: 1.5em;
        }
        .description {
          display: flex;
          flex-direction: column;
          margin-top: ${spacers.s3};
          font-size: 16px;
          font-weight: 300;
        }
        .description :global(p) {
          margin: auto;
        }
      `}</style>
    </div>
  );
};
