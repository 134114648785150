import type { FormValues } from "./types";

export const initialValues: FormValues = {
  isWalkIn: false,
  holdInLobby: false,
  title: "",
  description: "",
  date: "",
  startTime: 0,
  duration: "",
  organizer: {
    firstName: "",
    lastName: "",
    company: {
      uuid: "",
      name: "",
    },
    email: "",
    avatar: "",
    userUuid: "",
  },
  buildingReceptionNotes: "",
  companyReceptionNotes: "",
  visitorNotes: "",
  visitors: [],
  visitorTypeUuid: undefined,
  host: {
    firstName: "",
    lastName: "",
    company: {
      uuid: "",
      name: "",
    },
    email: "",
    avatar: "",
    userUuid: "",
  },
  location: "",
  notifyHost: true,
  notifyOrganizer: true,
  additionalDates: [],
  recurringSettings: undefined,
};
