import type { FC } from "react";
import React, { useContext } from "react";
import {
  FlexTenantStatus,
  MyFlexTenantsDocument,
  useSaveFlexTenantBillingCustomerMutation,
  type BillingCustomerInput,
  type FlexTenantFragmentFragment,
} from "../../generated/gateway-client";
import type { FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import { Button, Form as EqForm, Modal, ProgressCircle, Text } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { SideModalContext } from "../../contexts/SideModalContext";
import * as yup from "yup";
import { billingCustomerValidation } from "../lib/billingCustomerValidation";

interface Props {
  flexTenant: FlexTenantFragmentFragment;
  readonly?: boolean;
}

export const EditCompanyForm: FC<Props> = ({ flexTenant, readonly = false }) => {
  const { t } = useTranslation();
  const { closeModal } = useContext(SideModalContext);
  const [mutation] = useSaveFlexTenantBillingCustomerMutation();

  const validationSchema = () =>
    yup.object().shape({
      billingDetails: yup.object().shape({
        ...billingCustomerValidation(t),
      }),
    });

  const handleSubmit = async (
    values: BillingCustomerInput,
    { setSubmitting }: FormikHelpers<BillingCustomerInput>,
  ): Promise<boolean> => {
    setSubmitting(true);
    await mutation({
      variables: {
        input: values,
      },
      refetchQueries: [
        {
          query: MyFlexTenantsDocument,
        },
      ],
    });
    setSubmitting(false);
    closeModal();
    return true;
  };

  const initialValues: BillingCustomerInput = {
    flexTenantUuid: flexTenant.uuid,
    address: {
      line1: flexTenant.billingCustomer?.address?.line1 ?? "",
      postalCode: flexTenant.billingCustomer?.address?.postalCode ?? "",
      city: flexTenant.billingCustomer?.address?.city ?? "",
      state: flexTenant.billingCustomer?.address?.state ?? "",
    },
    email: flexTenant.billingCustomer?.email ?? "",
    contactName: flexTenant.billingCustomer?.contactName ?? "",
  };

  const canEdit = flexTenant.status === FlexTenantStatus.PreMoveIn && !readonly;

  return (
    <Formik<BillingCustomerInput>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, isValid, errors, dirty }) => (
        <>
          <Modal.Body>
            <Form>
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.address")}
              </Text>
              <EqForm.Group error={errors.address?.line1} label={t("flex.members.form.address")} required={canEdit}>
                <Field type="text" readOnly={!canEdit} name="address.line1" as={EqForm.Input} />
              </EqForm.Group>
              <div className="d-flex">
                <EqForm.Group
                  error={errors.address?.postalCode}
                  className="mr-4"
                  label={t("flex.members.form.postalCode")}
                  required={canEdit}
                >
                  <Field type="text" readOnly={!canEdit} name="address.postalCode" as={EqForm.Input} />
                </EqForm.Group>
                <EqForm.Group error={errors.address?.city} label={t("flex.members.form.city")} required={canEdit}>
                  <Field type="text" readOnly={!canEdit} name="address.city" as={EqForm.Input} />
                </EqForm.Group>
              </div>
              <EqForm.Group error={errors.address?.state} label={t("flex.members.form.state")}>
                <Field type="text" readOnly={!canEdit} name="address.state" as={EqForm.Input} />
              </EqForm.Group>
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.billingInformation")}
              </Text>
              <EqForm.Group error={errors.email} label={t("flex.members.form.contactEmail")} required={canEdit}>
                <Field type="email" readOnly={!canEdit} name="email" as={EqForm.Input} />
              </EqForm.Group>
              <EqForm.Group
                error={errors.contactName}
                label={t("flex.members.form.companyBusinessName")}
                required={canEdit}
              >
                <Field type="text" readOnly={!canEdit} name="contactName" as={EqForm.Input} />
              </EqForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal} variant="ghost">
              {t("common.cancel")}
            </Button>
            {canEdit && (
              <Button
                className="ml-2"
                disabled={isSubmitting || !isValid || !dirty}
                onClick={() => {
                  void submitForm();
                }}
              >
                {isSubmitting && <ProgressCircle size="xs" />} {t("common.saveChanges")}
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
