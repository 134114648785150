import { useRouter } from "next/router";
import type { ReactNode } from "react";
import React, { createContext, useContext, useState } from "react";
import { Role } from "../Role";
import { CurrentProfile } from "./ProfileContext";
import { CurrentRole } from "./RoleContext";
import type { FormValues } from "./types";

export interface AppointmentsMenuContext {
  isNewAppointment: boolean;
  isEditAppointment: boolean;
  hasEditableAccess: boolean;
  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;
  clearState: () => void;
  createAppointment: () => void;
  editAppointment: (uuid: string) => void;
  cacheAppointmentForm: (values: FormValues) => void;
  navigatePreviousPage: () => void;
  selectedAppointmentUuid?: string;
  isUserAppointmentOrganizer: boolean;
  isEndUserHasEditableAccess: boolean;
  cachedAppointmentForm?: FormValues;
}

export const AppointmentsMenuContext = createContext<AppointmentsMenuContext>({
  isNewAppointment: false,
  isEditAppointment: false,
  isPreview: true,
  setIsPreview: () => undefined,
  clearState: () => undefined,
  createAppointment: () => undefined,
  editAppointment: (_uuid: string) => undefined,
  cacheAppointmentForm: () => undefined,
  navigatePreviousPage: () => undefined,
  cachedAppointmentForm: undefined,
  selectedAppointmentUuid: undefined,
  hasEditableAccess: false,
  isEndUserHasEditableAccess: false,
  isUserAppointmentOrganizer: false,
});

export const AppointmentsMenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const { currentRole } = useContext(CurrentRole);
  const { profile } = useContext(CurrentProfile);
  const [isNewAppointment, setNewAppointment] = useState(false);
  const [isEditAppointment, setEditAppointment] = useState(false);
  const [isPreview, setIsPreview] = useState(true);
  const [cachedFormValues, setCachedFormValues] = useState<FormValues | undefined>();
  const [selectedAppointmentUuid, setSelectedAppointmentUuid] = useState<string | undefined>();

  const isEndUserHasEditableAccess = currentRole === Role.Unknown && profile?.isAppointmentOrganizer === true;
  const hasEditableAccess = currentRole !== Role.Unknown;
  const isUserAppointmentOrganizer = cachedFormValues?.organizer.userUuid === profile?.uuid;

  const clearState = () => {
    setNewAppointment(false);
    setEditAppointment(false);
    setCachedFormValues(undefined);
    setIsPreview(true);
    setSelectedAppointmentUuid(undefined);
  };

  const createAppointment = () => {
    setEditAppointment(false);
    setSelectedAppointmentUuid(undefined);
    setNewAppointment(true);
  };

  const editAppointment = (uuid: string) => {
    setNewAppointment(false);
    setEditAppointment(true);
    setSelectedAppointmentUuid(uuid);
  };

  const cacheAppointmentForm = (values: FormValues) => {
    setCachedFormValues(values);
  };

  const navigatePreviousPage = () => {
    void router.push({
      pathname: "/visitor-management",
      query: router.query,
    });
  };

  return (
    <AppointmentsMenuContext.Provider
      value={{
        isNewAppointment,
        isEditAppointment,
        hasEditableAccess,
        isPreview,
        isEndUserHasEditableAccess,
        isUserAppointmentOrganizer,
        setIsPreview,
        clearState,
        createAppointment,
        editAppointment,
        cacheAppointmentForm,
        navigatePreviousPage,
        selectedAppointmentUuid,
        cachedAppointmentForm: cachedFormValues,
      }}
    >
      {children}
    </AppointmentsMenuContext.Provider>
  );
};
