import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Field } from "formik";
import { DateTime } from "luxon";

import { notNullOrUndefined, Site, useSaferFormikContext, useSiteContext } from "@equiem/lib";
import { AppointmentRecurringType, AppointmentWeekday } from "@equiem/lib/context/types";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Form, Modal } from "@equiem/react-admin-ui";

import {
  useGenerateRecurringDatesLazyQuery,
  useGetOperationsCompanyQuery,
  useVisitorManagementSiteSettingsQuery,
} from "../../../../generated/visitors-client";
import { useWeekdayMapper } from "../../hooks/useWeekdayMapper";
import type { AppointmentRecurringSettings, FormValues } from "../../types";
import { getLocalisedDurationTimeShort } from "../../utils/date";
import { getMaxAllowedAppointmentDateTime } from "../../utils/recurringAppointmentHelpers";

import {
  GetReceptionTimezoneAdjustedStartOfDay,
  NumberToRecurringTypeGraphQLMap,
  NumberToRecurringWeekdayGraphQLMap,
} from "./RecurringSettingsAuxiliary";
import { getWeekdayOccuranceOfWeekdayInMonth } from "./RecurringSettingsAuxiliaryFunctions";
import { useRecurringSettingsWidgetContext } from "./RecurringSettingsModalContext";

interface SummaryTextParams {
  appointmentDate: string;
  recurringType: number | undefined;
  repeatEvery: number | undefined;
  appointmentStartTime: number;
  appointmentEndTime: string;
  recurringDates: DateTime[];
  repeatOn?: AppointmentWeekday[];
  repeatUntil?: DateTime;
  sameWeekDayEachMonth?: boolean;
  lastWeekDayEachMonth?: boolean;
}

export const RecurringSettingsModalView: React.FC = () => {
  const { recurringSettings, close, submit, recurringUuid } = useRecurringSettingsWidgetContext();
  const { t, i18n } = useTranslation();
  const { uuid } = useContext(Site);
  const { timezone } = useSiteContext();
  const { values } = useSaferFormikContext<FormValues>();

  const [recurringType, setRecurringType] = useState<number>(recurringSettings.recurringType);
  const [repeatEvery, setRepeatEvery] = useState<number | undefined>(recurringSettings.repeatEvery);
  const [repeatOnWeekdays, setRepeatOnWeekdays] = useState<number[]>(recurringSettings.repeatOn ?? []);
  const [endsOnType, setEndsOnType] = useState<"date" | "occurrences">(
    recurringSettings.repeatUntil != null ? "date" : "occurrences",
  );
  const [repeatUntil, setRepeatUntil] = useState<DateTime | undefined>(
    recurringSettings.repeatUntil != null ? DateTime.fromMillis(recurringSettings.repeatUntil) : undefined,
  );
  const [repeatTimes, setRepeatTimes] = useState<number | undefined>(recurringSettings.repeatTimes ?? 1);
  const [sameWeekDayEachMonth, setSameWeekDayEachMonth] = useState<boolean>(
    recurringSettings.sameWeekDayEachMonth ?? false,
  );
  const [lastWeekDayEachMonth, setLastWeekDayEachMonth] = useState<boolean>(
    recurringSettings.lastWeekDayEachMonth ?? false,
  );

  const { data: companySettings } = useGetOperationsCompanyQuery({
    variables: { companyUuid: values.host.uuid ?? values.organizer.uuid },
    fetchPolicy: "cache-and-network",
  });

  const { data: vmSiteSettings } = useVisitorManagementSiteSettingsQuery({
    variables: { siteUuid: uuid },
    fetchPolicy: "cache-and-network",
  });

  const maxAllowedDate = useMemo(
    () =>
      getMaxAllowedAppointmentDateTime(
        companySettings?.operationsCompany?.maxAppointmentCreationMonths,
        vmSiteSettings?.visitorManagementSiteSettings.maxAppointmentCreationMonths,
        timezone,
      ),
    [
      companySettings?.operationsCompany?.maxAppointmentCreationMonths,
      vmSiteSettings?.visitorManagementSiteSettings.maxAppointmentCreationMonths,
    ],
  );

  const recurringOpts = [
    {
      label: t("visitors.appointmentForm.appointmentRecurringOccursEveryDays").toLocaleLowerCase(),
      value: AppointmentRecurringType.Daily.toString(),
    },
    {
      label: t("visitors.appointmentForm.appointmentRecurringOccursEveryWeeks").toLocaleLowerCase(),
      value: AppointmentRecurringType.Weekly.toString(),
    },
    {
      label: t("visitors.appointmentForm.appointmentRecurringOccursEveryMonths").toLocaleLowerCase(),
      value: AppointmentRecurringType.Monthly.toString(),
    },
  ];
  const repeatEveryOpts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
    (i) =>
      ({
        label: i.toString(),
        value: i,
      } as const),
  );

  const repeatTimesOpts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
    (i) =>
      ({
        label: (i + 1).toString(),
        value: i,
      } as const),
  );

  const { weekDayMap, weekDayMapNumber } = useWeekdayMapper();
  const isEditMode = recurringUuid != null;

  const [fetchDatesQuery, { data: recurringDates }] = useGenerateRecurringDatesLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSaveModal = useCallback(() => {
    const endDate = recurringDates?.generateRecurringDates.at(recurringDates.generateRecurringDates.length - 1);

    if (endDate != null && recurringDates?.generateRecurringDates != null) {
      const newSettings: AppointmentRecurringSettings = {
        startDate: recurringSettings.startDate,
        endDate: endDate,
        recurringDates: recurringDates.generateRecurringDates,
        repeatEvery: repeatEvery,
        recurringType: recurringType,
        repeatTimes: endsOnType === "occurrences" ? repeatTimes : undefined,
        repeatUntil: endsOnType === "date" && repeatUntil != null ? repeatUntil.toMillis() : undefined,
      };

      if (recurringType === AppointmentRecurringType.Weekly) {
        newSettings.repeatOn = repeatOnWeekdays;
      }

      if (recurringType === AppointmentRecurringType.Monthly) {
        newSettings.lastWeekDayEachMonth = lastWeekDayEachMonth;
        newSettings.sameWeekDayEachMonth = sameWeekDayEachMonth;
      }

      submit(newSettings);
    }
  }, [
    endsOnType,
    lastWeekDayEachMonth,
    recurringDates?.generateRecurringDates,
    recurringSettings,
    recurringType,
    repeatEvery,
    repeatOnWeekdays,
    repeatTimes,
    repeatUntil,
    sameWeekDayEachMonth,
    submit,
  ]);

  const isValid = (): boolean => {
    return recurringDates?.generateRecurringDates != null && recurringDates.generateRecurringDates.length > 0;
  };

  const daySelected = (value: AppointmentWeekday) => {
    return repeatOnWeekdays.includes(value);
  };

  const onDayClicked = (value: AppointmentWeekday) => {
    if (repeatOnWeekdays != null) {
      if (!repeatOnWeekdays.includes(value)) {
        setRepeatOnWeekdays([...repeatOnWeekdays, value]);
      } else {
        setRepeatOnWeekdays(repeatOnWeekdays.filter((x) => x !== value));
      }
    }
  };

  const onAppointmentRecurringTypeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecurringType(Number(e.target.value));
  };

  const onRepeatEveryChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRepeatEvery(Number(e.target.value));
  };

  const onRepeatTimesChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRepeatTimes(Number(e.target.value));
  };

  const onEndsOnTypeChanged = (value: "occurrences" | "date") => {
    setEndsOnType(value);
  };

  const onRepeatUntilChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatUntil(DateTime.fromISO(e.target.value));
  };

  const onMonthlyRepeatSubtypeChanged = (value: "sameDay" | "sameOccurance" | "lastOccurance") => {
    setSameWeekDayEachMonth(value === "sameOccurance");
    setLastWeekDayEachMonth(value === "lastOccurance");
  };

  useEffect(() => {
    const isMissingSettings =
      repeatEvery == null ||
      (repeatTimes == null && repeatUntil == null) ||
      (endsOnType === "occurrences" && repeatTimes == null) ||
      (endsOnType === "date" && repeatUntil == null);

    if (!isMissingSettings) {
      const startDateAdjustedToReceptionTimezone = GetReceptionTimezoneAdjustedStartOfDay(
        recurringSettings.startDate,
        timezone,
      );

      const repeatUntilAdjustedToReceptionTimezone =
        repeatUntil != null && endsOnType === "date"
          ? GetReceptionTimezoneAdjustedStartOfDay(repeatUntil.toMillis(), timezone)
          : undefined;

      const calculateRecurringDates = async () => {
        const result =
          (await fetchDatesQuery({
            variables: {
              settings: {
                startDate: startDateAdjustedToReceptionTimezone,
                recurringType: NumberToRecurringTypeGraphQLMap[recurringType],
                repeatEvery: repeatEvery,
                lastWeekDayEachMonth:
                  recurringType === AppointmentRecurringType.Monthly
                    ? !lastWeekDayEachMonth
                      ? undefined
                      : true
                    : undefined,
                repeatOn:
                  recurringType === AppointmentRecurringType.Weekly
                    ? repeatOnWeekdays.map((x) => NumberToRecurringWeekdayGraphQLMap[x]).filter(notNullOrUndefined)
                    : undefined,
                repeatTimes: endsOnType === "occurrences" ? repeatTimes : undefined,
                repeatUntil: repeatUntilAdjustedToReceptionTimezone,
                sameDayEachMonth:
                  recurringType === AppointmentRecurringType.Monthly
                    ? !sameWeekDayEachMonth
                      ? undefined
                      : true
                    : undefined,
              },
              timezone: timezone,
            },
          }).then((x) => x.data?.generateRecurringDates)) ?? [];
        const date = DateTime.fromMillis(recurringSettings.startDate).setZone(timezone);
        console.log(
          `appointment date: \n${formatters.datelong(date, i18n.language)}\n\nreccurringDates:\n\n${`${result
            .map((x) => `${formatters.datelong(x, i18n.language)}\n`)
            .join("")}`}`,
        );

        return result;
      };
      void calculateRecurringDates();
    }
  }, [
    repeatEvery,
    repeatOnWeekdays,
    recurringType,
    endsOnType,
    repeatTimes,
    repeatUntil,
    sameWeekDayEachMonth,
    lastWeekDayEachMonth,
    fetchDatesQuery,
    recurringSettings.startDate,
    i18n.language,
  ]);

  const getSummaryText = (params: SummaryTextParams): string => {
    if (params.recurringType == null || params.repeatEvery == null || params.recurringDates.length === 0) {
      return "";
    }

    const date = DateTime.fromSeconds(Number(params.appointmentDate));
    const startTimeObj = DateTime.fromSeconds(Number(values.startTime)).toObject();
    const startTime = DateTime.fromObject({ ...date.toObject(), ...startTimeObj });

    const isSingular = params.repeatEvery === 1;

    let p1: string =
      params.recurringType === (AppointmentRecurringType.Daily as number)
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryDay").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryDays").toLocaleLowerCase()
        : params.recurringType === AppointmentRecurringType.Weekly
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryWeek").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryWeeks").toLocaleLowerCase()
        : params.recurringType === AppointmentRecurringType.Monthly
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryMonth").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryMonths").toLocaleLowerCase()
        : "placeholder";

    let repeatEveryStr = params.repeatEvery === 1 ? "" : params.repeatEvery;

    if (params.sameWeekDayEachMonth === true) {
      p1 = weekDayMapNumber[startTime.weekday];
      repeatEveryStr = getWeekdayOccuranceOfWeekdayInMonth(startTime);
    }

    if (params.lastWeekDayEachMonth === true) {
      p1 = weekDayMapNumber[startTime.weekday];
    }

    const p2 = params.repeatOn
      ?.sort((a, b) => a - b)
      .map((x, i, arr) =>
        i === arr.length - 1 && arr.length !== 1 ? `${t("common.and")} ${weekDayMap[x]}` : `${weekDayMap[x]} `,
      )
      .join("");

    const repeatType: string =
      params.repeatOn?.length === 7 || params.recurringType !== AppointmentRecurringType.Weekly
        ? `${
            params.lastWeekDayEachMonth === true
              ? t("visitors.appointmentForm.appointmentRecurringLast")
              : repeatEveryStr
          } ${p1}`
        : `${repeatEveryStr} ${p2}`;

    return t("visitors.appointmentForm.appointmentRecurringSettingsText", {
      repeatType: repeatType,
      startTime: formatters.timeshort(startTime, i18n.language),
      endTime: getLocalisedDurationTimeShort(params.appointmentEndTime, i18n.language).toUpperCase(),
      recurringEndDate: (params.recurringDates.at(-1) ?? date).toFormat("dd/MM/yyyy"),
      times: params.recurringDates.length + 1,
    });
  };

  return (
    <>
      <Modal.Body noPadding={true}>
        <div className="pl-6 pr-6 pt-4">
          <EqForm.Group
            label={t("visitors.appointmentForm.appointmentRecurringSettingsRepeatEvery")}
            labelWidth="auto"
            noHeaderMargin={true}
            inline={"sm"}
          >
            <div className="label-container">
              <div className="d-flex flex-row">
                <div className="pl-4 pr-2">
                  <EqForm.Select
                    id="vmSettingsSiteMaxAppCreationRepeatEverySelect"
                    onChange={onRepeatEveryChanged}
                    value={repeatEvery?.toString()}
                  >
                    {repeatEveryOpts.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </EqForm.Select>
                </div>
                <div className="month-select">
                  <EqForm.Select
                    id="vmSettingsSiteMaxAppCreationRepeatTypeSelect"
                    onChange={onAppointmentRecurringTypeChanged}
                    disabled={recurringUuid != null}
                    value={recurringType.toString()}
                  >
                    {recurringOpts.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </EqForm.Select>
                </div>
              </div>
            </div>
          </EqForm.Group>
          {recurringType === AppointmentRecurringType.Monthly && !isEditMode && (
            <div className="d-flex flex-row">
              <EqForm.Group>
                <div className="label-container">
                  <div className="d-flex flex-row">
                    <div className="label-container">
                      <label className="label-item">
                        <Field
                          as={() => (
                            <input
                              name="monthlySameDay"
                              className="radio-input"
                              checked={!sameWeekDayEachMonth && !lastWeekDayEachMonth}
                              onChange={(_) => onMonthlyRepeatSubtypeChanged("sameDay")}
                              type="radio"
                            />
                          )}
                        ></Field>
                        <span className="custom-select-text mr-6">
                          {t("visitors.appointmentForm.appointmentRecurringOccursEndsOnDay", {
                            dayNumber: DateTime.fromMillis(recurringSettings.startDate).day,
                          })}
                        </span>
                      </label>
                      <label className="label-item">
                        <Field
                          as={() => (
                            <input
                              name="monthlySameOccurance"
                              className="radio-input"
                              checked={sameWeekDayEachMonth}
                              onChange={(_) => onMonthlyRepeatSubtypeChanged("sameOccurance")}
                              type="radio"
                            />
                          )}
                        ></Field>
                        <span className="custom-select-text mr-6">
                          {t("visitors.appointmentForm.appointmentRecurringOccursEndsOnSameOccurance", {
                            number: getWeekdayOccuranceOfWeekdayInMonth(
                              DateTime.fromMillis(recurringSettings.startDate),
                            ),
                            weekday:
                              weekDayMap[
                                DateTime.fromMillis(recurringSettings.startDate).weekday as AppointmentWeekday
                              ],
                          })}
                        </span>
                      </label>
                      {getWeekdayOccuranceOfWeekdayInMonth(DateTime.fromMillis(recurringSettings.startDate)) > 4 ? (
                        <label className="label-item">
                          <Field
                            as={() => (
                              <input
                                name="monthlyLastOccurance"
                                className="radio-input"
                                checked={lastWeekDayEachMonth}
                                onChange={(_) => onMonthlyRepeatSubtypeChanged("lastOccurance")}
                                type="radio"
                              />
                            )}
                          ></Field>
                          <span className="custom-select-text mr-6">
                            {t("visitors.appointmentForm.appointmentRecurringOccursEndsOnLastOccurance", {
                              weekday:
                                weekDayMap[
                                  DateTime.fromMillis(recurringSettings.startDate).weekday as AppointmentWeekday
                                ],
                            })}
                          </span>
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </EqForm.Group>
            </div>
          )}
          {recurringType === AppointmentRecurringType.Weekly && !isEditMode && (
            <EqForm.Group label={t("visitors.appointmentForm.appointmentRecurringSettingsRepeatOn")}>
              <div className="d-flex flex-row justify-content-center">
                <EqForm.ToggleButton
                  value={daySelected(AppointmentWeekday.MONDAY)}
                  onClickCapture={() => onDayClicked(AppointmentWeekday.MONDAY)}
                  className="m-2"
                  label={t("visitors.appointmentForm.appointmentRecurringSettingsMondayShort")}
                ></EqForm.ToggleButton>
                <EqForm.ToggleButton
                  value={daySelected(AppointmentWeekday.TUESDAY)}
                  onClickCapture={() => onDayClicked(AppointmentWeekday.TUESDAY)}
                  className="m-2"
                  label={t("visitors.appointmentForm.appointmentRecurringSettingsTuesdayShort")}
                ></EqForm.ToggleButton>
                <EqForm.ToggleButton
                  value={daySelected(AppointmentWeekday.WEDNESDAY)}
                  onClickCapture={() => onDayClicked(AppointmentWeekday.WEDNESDAY)}
                  className="m-2"
                  label={t("visitors.appointmentForm.appointmentRecurringSettingsWednesdayShort")}
                ></EqForm.ToggleButton>
                <EqForm.ToggleButton
                  value={daySelected(AppointmentWeekday.THURSDAY)}
                  onClickCapture={() => onDayClicked(AppointmentWeekday.THURSDAY)}
                  className="m-2"
                  label={t("visitors.appointmentForm.appointmentRecurringSettingsThursdayShort")}
                ></EqForm.ToggleButton>
                <EqForm.ToggleButton
                  value={daySelected(AppointmentWeekday.FRIDAY)}
                  onClickCapture={() => onDayClicked(AppointmentWeekday.FRIDAY)}
                  className="m-2"
                  label={t("visitors.appointmentForm.appointmentRecurringSettingsFridayShort")}
                ></EqForm.ToggleButton>
                <EqForm.ToggleButton
                  value={daySelected(AppointmentWeekday.SATURDAY)}
                  onClickCapture={() => onDayClicked(AppointmentWeekday.SATURDAY)}
                  className="m-2"
                  label={t("visitors.appointmentForm.appointmentRecurringSettingsSaturdayShort")}
                ></EqForm.ToggleButton>
                <EqForm.ToggleButton
                  value={daySelected(AppointmentWeekday.SUNDAY)}
                  onClickCapture={() => onDayClicked(AppointmentWeekday.SUNDAY)}
                  className="m-2"
                  label={t("visitors.appointmentForm.appointmentRecurringSettingsSundayShort")}
                ></EqForm.ToggleButton>
              </div>
            </EqForm.Group>
          )}
          <EqForm.Group label={t("visitors.appointmentForm.appointmentRecurringSettingsEnds")}>
            <div className="label-container">
              <div className="d-flex flex-row">
                <div className="label-container">
                  <label className="label-item">
                    <div className="d-flex flex-row justify-content-center ends-on">
                      <Field
                        as={() => (
                          <input
                            name="permissionAllUsers"
                            className="radio-input"
                            checked={endsOnType === "date"}
                            onChange={(_) => onEndsOnTypeChanged("date")}
                            type="radio"
                          />
                        )}
                      ></Field>
                      <span className="custom-select-text pr-9">
                        {t("visitors.appointmentForm.appointmentRecurringOccursEndsOn")}
                      </span>
                    </div>
                    <div>
                      <Form.Input
                        type="date"
                        disabled={endsOnType !== "date"}
                        value={repeatUntil != null ? repeatUntil.toFormat("yyyy-MM-dd") : undefined}
                        calendarIconPosition={"left"}
                        min={DateTime.fromMillis(recurringSettings.startDate).toFormat("yyyy-MM-dd")}
                        max={maxAllowedDate.toFormat("yyyy-MM-dd")}
                        onChange={onRepeatUntilChanged}
                      />
                    </div>
                  </label>
                  <label className="label-item">
                    <div className="d-flex flex-row justify-content-center ends-on">
                      <Field
                        as={() => (
                          <input
                            name="permissionListUsers"
                            className="radio-input"
                            checked={endsOnType === "occurrences"}
                            onChange={() => onEndsOnTypeChanged("occurrences")}
                            type="radio"
                          />
                        )}
                      ></Field>
                      <span className="custom-select-text pr-7">
                        {t("visitors.appointmentForm.appointmentRecurringOccursEndsAfter")}
                      </span>
                    </div>
                    <div>
                      <EqForm.Select
                        id="vmSettingsSiteMaxAppCreationMonthsSelect"
                        disabled={endsOnType !== "occurrences"}
                        onChange={onRepeatTimesChanged}
                        value={endsOnType !== "occurrences" ? repeatTimes : repeatTimes?.toString()}
                      >
                        {repeatTimesOpts.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </EqForm.Select>
                    </div>

                    <span className="custom-select-text ml-3">
                      {t("visitors.appointmentForm.appointmentRecurringOccursEndsOccurrences")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="recurring-text">
              <label className="summary-label">
                {getSummaryText({
                  appointmentDate: DateTime.fromMillis(recurringSettings.startDate).toFormat("yyyy-MM-dd"),
                  appointmentStartTime: Number(values.startTime),
                  appointmentEndTime: values.duration,
                  recurringDates:
                    recurringDates?.generateRecurringDates != null
                      ? recurringDates.generateRecurringDates.map((date) => DateTime.fromMillis(date))
                      : [],
                  recurringType: recurringType,
                  repeatEvery: repeatEvery,
                  repeatOn: repeatOnWeekdays,
                  repeatUntil: repeatUntil,
                  sameWeekDayEachMonth: sameWeekDayEachMonth,
                  lastWeekDayEachMonth: lastWeekDayEachMonth,
                })}
              </label>
            </div>
          </EqForm.Group>
        </div>
      </Modal.Body>
      <Modal.Footer hideBreakline={true}>
        <Button type="button" className="mx-4" variant="ghost" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button type="button" variant="primary" disabled={!isValid()} onClick={handleSaveModal}>
          {t("common.done")}
        </Button>
      </Modal.Footer>
      <style jsx>
        {`
          .recurring-text > label:first-child {
            overflow-wrap: break-word;
          }

          .recurring-text {
            padding-top: 24px;
          }

          .label-item {
            display: flex;
            width: fit-content;
            margin-bottom: 12px;
          }

          .custom-select-text {
            align-self: center;
          }
          .ends-on {
            width: 90px;
          }
          .summary-label {
            flex: 1;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
          }
        `}
      </style>
    </>
  );
};
