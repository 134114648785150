import { CKEditorNoSSR, useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Table, Text, useTheme } from "@equiem/react-admin-ui";
import { RiCloseLine, RiEditLine } from "@equiem/react-admin-ui/icons";
import { Field, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { ResourceCreateAndEditFormCancellationPermissionsModal } from "./ResourceCreateAndEditFormCancellationPermissionsModal";
import {
  DestinationTierLevel,
  BookableResourceCancellationRateType as RateType,
} from "../../../../../generated/gateway-client";
import { useCurrencyCode } from "../../../../../hooks/useCurrency";
import type { CancellationRate } from "../../../../../lib/cancellationRate";
import type { FormValues } from "../../../../../lib/formValidation";

enum Units {
  HOURS = "hours",
  DAYS = "days",
}

interface TimeWithUnits {
  value: number;
  unit: Units;
}

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;

const HOURLY_UPPER_LIMIT = 23.75;
const DAILY_UPPER_LIMIT = 999;
const HOURLY_STEP = 0.25;
const DAILY_STEP = 1;

const MAX_RATES_NUMBER = 10;

const getTimeWithUnits = (minutes: number): TimeWithUnits => {
  let value: number;
  let unit: Units;
  if (minutes > 0 && minutes % MINUTES_IN_DAY === 0) {
    value = minutes / MINUTES_IN_DAY;
    unit = Units.DAYS;
  } else {
    value = minutes / MINUTES_IN_HOUR;
    unit = Units.HOURS;
  }
  return { value, unit };
};

const getTimeInMinutes = (time: TimeWithUnits): number => {
  return time.value * (time.unit === Units.DAYS ? MINUTES_IN_DAY : MINUTES_IN_HOUR);
};

const getWindowUpperLimit = (units: Units) => {
  return units === Units.HOURS ? HOURLY_UPPER_LIMIT : DAILY_UPPER_LIMIT;
};

const getWindowStep = (units: Units) => {
  return units === Units.HOURS ? HOURLY_STEP : DAILY_STEP;
};

export const ResourceCreateAndEditFormCancellationPermissions: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { tierLevel } = useSiteContext();
  const fm = useFormikContext<FormValues>();
  const { breakpoints, colors, spacers } = useTheme(true);
  const currency = useCurrencyCode(fm.values.building);

  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);

  const [noticePeriod, setNoticePeriod] = useState<TimeWithUnits>(
    getTimeWithUnits(Number(fm.values.editBookingNoticePeriodInMinutes)),
  );

  useEffect(() => {
    fm.setFieldValue("editBookingNoticePeriodInMinutes", getTimeInMinutes(noticePeriod)).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.setFieldValue, noticePeriod]);

  const addPaymentRateCancellation = useCallback(
    (paymentRateCancellation: CancellationRate) => {
      fm.setFieldValue("paymentRateCancellation", [
        ...fm.values.paymentRateCancellation,
        paymentRateCancellation,
      ]).catch(console.error);

      setShowModal(false);
    },
    [fm],
  );

  const editPaymentRateCancellation = useCallback(
    (paymentRateCancellation: CancellationRate, index: number) => {
      const newPaymentRateCancellation = [...fm.values.paymentRateCancellation];
      newPaymentRateCancellation[index] = paymentRateCancellation;
      fm.setFieldValue("paymentRateCancellation", newPaymentRateCancellation).catch(console.error);

      setShowModal(false);
      setModalIndex(null);
    },
    [fm],
  );

  const removePaymentRateCancellation = useCallback(
    (index: number) => {
      const newPaymentRateCancellation = [...fm.values.paymentRateCancellation];
      newPaymentRateCancellation.splice(index, 1);
      fm.setFieldValue("paymentRateCancellation", newPaymentRateCancellation).catch(console.error);
    },
    [fm],
  );

  return (
    <>
      <Form.Group error={fm.errors.userCanEditBookings}>
        <Field
          name="userCanEditBookings"
          label={t("bookings.resources.usersCanEditCancelTheBooking")}
          description={t("bookings.resources.usersCanEditHint")}
          as={Form.HeroCheckboxOld}
          disabled={fm.isSubmitting}
          value={fm.values.userCanEditBookings}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            fm.setFieldValue("userCanEditBookings", e.target.checked).catch(console.error);
          }}
        />
      </Form.Group>

      {fm.values.userCanEditBookings != null && fm.values.userCanEditBookings && (
        <>
          <div className="notice-period">
            <Form.Group
              error={fm.errors.editBookingNoticePeriodInMinutes}
              label={t("bookings.resources.noticePeriod")}
              showTooltip={true}
              tooltipText={t("bookings.resources.noticePeriodHint")}
            >
              <Field id="editBookingNoticePeriodInMinutes" name="editBookingNoticePeriodInMinutes">
                {() => (
                  <div className="form-field-with-units">
                    <Form.Input
                      name="noticePeriodValue"
                      min={0}
                      max={getWindowUpperLimit(noticePeriod.unit)}
                      step={getWindowStep(noticePeriod.unit)}
                      type="number"
                      disabled={fm.isSubmitting}
                      value={noticePeriod.value}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        setNoticePeriod({ value: !isNaN(newValue) ? newValue : 0, unit: noticePeriod.unit });
                      }}
                      onBlur={(e) => {
                        const step = getWindowStep(noticePeriod.unit);
                        const newValue = Math.round(parseFloat(e.target.value) / step) * step;
                        setNoticePeriod({
                          value: !isNaN(newValue) ? newValue : 0,
                          unit: noticePeriod.unit,
                        });
                      }}
                    />

                    <Form.Select
                      name="noticePeriodUnit"
                      disabled={fm.isSubmitting}
                      value={noticePeriod.unit}
                      onChange={(e) => {
                        const newUnit = e.target.value as Units;
                        const upperLimit = getWindowUpperLimit(newUnit);
                        const step = getWindowStep(newUnit);
                        const newValue = Math.round(noticePeriod.value / step) * step;
                        setNoticePeriod({ value: newValue > upperLimit ? upperLimit : newValue, unit: newUnit });
                      }}
                    >
                      <option value={Units.HOURS}>{t("common.hours")}</option>
                      <option value={Units.DAYS}>{t("common.days")}</option>
                    </Form.Select>
                  </div>
                )}
              </Field>
            </Form.Group>
          </div>

          <Form.Group
            error={fm.errors.editBookingTermsAndConditions}
            label={t("bookings.resources.editCancellationPolicy")}
            showTooltip
            tooltipText={t("bookings.resources.editCancellationPolicyHint")}
          >
            <Field
              id="editBookingTermsAndConditions"
              name="editBookingTermsAndConditions"
              placeholder={t("bookings.resources.termsAndConditions")}
              as={CKEditorNoSSR}
              toolbar={[
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "link",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "outdent",
                "indent",
                "|",
                "undo",
                "redo",
              ]}
              disabled={fm.isSubmitting}
            />
          </Form.Group>

          {tierLevel !== DestinationTierLevel.Essentials && (
            <>
              {fm.values.paymentRateCancellation.length > 0 && (
                <Form.Group
                  label={t("bookings.resources.cancellationRates")}
                  showTooltip
                  tooltipText={t("bookings.resources.cancelationRatesHint")}
                >
                  <div className="cancellation-rates-container">
                    <Table.Table className="w-100">
                      <thead>
                        <tr>
                          <Table.Header label={t("bookings.resources.rateType")} />
                          <Table.Header label={t("common.amount")} />
                          <Table.Header label={t("bookings.resources.daysBefore")} />
                          <Table.Header label="" />
                          <Table.Header label="" />
                        </tr>
                      </thead>
                      <tbody>
                        {fm.values.paymentRateCancellation.map((paymentRateCancellation, i) => (
                          <tr key={i}>
                            <td>
                              <Text variant="text" component="span" size="small">
                                {paymentRateCancellation.type === RateType.FixedRate
                                  ? t("bookings.resources.fixedRate")
                                  : t("common.percentage")}
                              </Text>
                            </td>
                            <td>
                              <Text variant="text" component="span" size="small">
                                {paymentRateCancellation.type === RateType.FixedRate
                                  ? formatters.currency(Number(paymentRateCancellation.amount), i18n.language, {
                                      currency,
                                    })
                                  : formatters.percentage(Number(paymentRateCancellation.amount) / 100, i18n.language)}
                              </Text>
                            </td>
                            <td>
                              <Text variant="text" component="span" size="small">
                                {paymentRateCancellation.daysBefore}
                              </Text>
                            </td>
                            <td>
                              <RiEditLine
                                size="16"
                                color={colors.primary}
                                cursor="pointer"
                                onClick={() => {
                                  setModalIndex(i);
                                  setShowModal(true);
                                }}
                              />
                            </td>
                            <td>
                              <RiCloseLine
                                size="16"
                                color={colors.danger}
                                cursor="pointer"
                                onClick={() => removePaymentRateCancellation(i)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table.Table>
                  </div>
                </Form.Group>
              )}

              {fm.values.paymentRateCancellation.length < MAX_RATES_NUMBER && (
                <Button
                  type="button"
                  variant="secondary"
                  size="lg"
                  style={{ width: "100%" }}
                  onClick={() => setShowModal(true)}
                >
                  {t("bookings.resources.addCancellationRate")}
                </Button>
              )}
            </>
          )}

          <ResourceCreateAndEditFormCancellationPermissionsModal
            showModal={showModal}
            setShowModal={setShowModal}
            modalIndex={modalIndex}
            setModalIndex={setModalIndex}
            building={fm.values.building}
            usedDaysBefore={fm.values.paymentRateCancellation.map((prc, i) =>
              prc.daysBefore !== "" && i !== modalIndex ? prc.daysBefore : null,
            )}
            paymentRateCancellation={modalIndex != null ? fm.values.paymentRateCancellation[modalIndex] : undefined}
            onAdd={addPaymentRateCancellation}
            onEdit={editPaymentRateCancellation}
          />
        </>
      )}

      <style jsx>
        {`
          .notice-period {
            width: 50%;
            min-width: 200px;
          }
          .form-field-with-units {
            display: flex;
            gap: ${spacers.s2};
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .notice-period {
              width: 100%;
            }
            .cancellation-rates-container :global(th),
            .cancellation-rates-container :global(td) {
              padding: ${spacers.s3};
            }
          }
        `}
      </style>
    </>
  );
};
