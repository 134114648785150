import React, { useEffect } from "react";
import { Field, Form } from "formik";

import type { CompanyFormValues } from "@equiem/lib";
import { useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm } from "@equiem/react-admin-ui";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { CompanyDetailsQuery } from "../../../generated/settings-client";

interface Props {
  accessGroups: Array<{ uuid: string; name: string }>;
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
}

export const DefaultAccessGroupPicker: React.FC<Props> = ({ accessGroups, company }) => {
  const { t } = useTranslation();
  const showError = useShowError();
  const { subscribe } = useWidgetContext();
  const { submitForm, setFieldValue, values } = useSaferFormikContext<CompanyFormValues>();
  const companyAccessGroups = accessGroups.filter((group) => {
    const companyAccessGroupsUuids = company.accessGroups.map((g) => g.uuid);
    return companyAccessGroupsUuids.includes(group.uuid);
  });

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm();
    });
  }, [submitForm, subscribe]);

  return (
    <Form>
      {values.addsCardsAutomatically != null && (
        <EqForm.Group required label={t("settings.editCompany.addingCardsLabel")}>
          <EqForm.RadioButton
            name="addsCardsAutomatically"
            className="mb-3 w-100"
            label={t("settings.editCompany.addingCardsManuallyLabel")}
            extraInfo={t("settings.editCompany.addingCardsManuallyTooltip")}
            checked={!values.addsCardsAutomatically}
            onChange={() => {
              setFieldValue("addsCardsAutomatically", false).catch(showError);
            }}
          />
          <EqForm.RadioButton
            name="addsCardsAutomatically"
            className="mb-3 w-100"
            label={t("settings.editCompany.addingCardsAutomaticallyLabel")}
            extraInfo={t("settings.editCompany.addingCardsAutomaticallyTooltip")}
            checked={values.addsCardsAutomatically}
            onChange={() => {
              setFieldValue("addsCardsAutomatically", true).catch(showError);
            }}
          />
        </EqForm.Group>
      )}
      <EqForm.Group
        required
        label={t("settings.editCompany.defaultGroupLabel")}
        showTooltip
        tooltipText={t("settings.editCompany.accessGroupTooltip")}
      >
        <Field as={EqForm.Select} name="defaultAccessGroupUuid">
          <option value="">{t("settings.editCompany.selectDefaultAccessGroupsPlaceholder")}</option>
          {companyAccessGroups.map((group) => (
            <option key={group.uuid} value={group.uuid}>
              {group.name}
            </option>
          ))}
        </Field>
      </EqForm.Group>
    </Form>
  );
};
