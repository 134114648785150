import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import { RiPercentLine } from "@equiem/react-admin-ui/icons";
import React from "react";

interface P {
  hasDiscounts: boolean;
  iconOnly?: boolean;
}
export const ResourceCardDiscountAvailableIndicator: React.FC<P> = ({ hasDiscounts, iconOnly = false }) => {
  const theme = useTheme(true);
  const { t } = useTranslation();

  return hasDiscounts ? (
    <>
      <div className={"resource-discount-indicator"}>
        <RiPercentLine height="13px" />
        {!iconOnly && <span>{t("bookings.discounts.discountAvailable")}</span>}
      </div>
      <style jsx>{`
        .resource-discount-indicator {
          display: flex;
          align-items: center;
          gap: ${theme.spacers.s1};
          background: ${theme.colors.status.positive.accent};
          border-radius: 20px;
          padding: ${theme.spacers.s2} ${theme.spacers.s4};
          height: 28px;
          color: ${theme.colors.status.positive.primary};
        }
        span {
          font-size: 12px;
          font-weight: 500;
        }
      `}</style>
    </>
  ) : null;
};
