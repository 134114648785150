import React, { useContext } from "react";
import { BreakWordDiv } from "../../../../lib/BreakWordDiv";
import { stringNotEmpty } from "@equiem/lib";
import { CurrencyAmount } from "../../../../lib/CurrencyAmount";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingChargesContext } from "../../contexts/BookingChargesProvider";
import { Skeleton } from "@equiem/react-admin-ui";

interface DiscountPercentageLineItemProps {
  name: string;
  percentage: number;
  className?: string;
}

interface LineItemProps {
  title?: string;
  item: string;
  description?: string | null;
  amount: number;
  currencyCode: string;
  isTax?: boolean;
  isTotal?: boolean;
  className?: string;
  resourcePriceIsDiscounted?: boolean;
}
const LineItem: React.FC<LineItemProps> = ({
  title,
  item,
  description,
  amount,
  currencyCode,
  className = "",
  isTax = false,
  isTotal = false,
  resourcePriceIsDiscounted = false,
}) => (
  <>
    <div
      className={`line-item flex-row d-flex justify-content-between ${className} ${isTax ? "text-muted" : ""} ${
        isTotal ? "font-weight-bold" : ""
      }`}
    >
      <BreakWordDiv className="flex-grow-1 mr-2">
        {stringNotEmpty(title) && <>{title}</>}
        {item}
        {description != null ? ` (${description})` : ""}
      </BreakWordDiv>
      {amount > 0 && (
        <CurrencyAmount
          className={"flex-shrink-0"}
          amount={amount}
          currencyCode={currencyCode}
          isDiscounted={resourcePriceIsDiscounted}
        />
      )}
    </div>
  </>
);

const DiscountPercentageLineItem: React.FC<DiscountPercentageLineItemProps> = ({
  name,
  percentage,
  className = "",
}) => (
  <>
    <div className={`line-item flex-row d-flex justify-content-between ${className}`}>
      <BreakWordDiv className="flex-grow-1 mr-2">{`${name} (${percentage}%)`}</BreakWordDiv>
    </div>
    <style jsx>{`
      .line-item {
        color: #008000;
      }
    `}</style>
  </>
);

const LineItemAddOn: React.FC<LineItemProps> = ({ item, description, amount, currencyCode, className }) => {
  const items = item.split(":");
  const rest = items.length === 1 ? "" : item.replace(`${items[0]}:`, "");

  return (
    <LineItem
      className={className}
      title={items[0]}
      item={stringNotEmpty(rest) ? `: ${rest}` : ""}
      description={description}
      amount={amount}
      currencyCode={currencyCode}
    />
  );
};

interface P {
  className?: string;
}
export const BookingPaymentSummary: React.FC<P> = ({ className }) => {
  const { t } = useTranslation();
  const { loading, charges } = useContext(BookingChargesContext);

  if (loading || charges == null) {
    return (
      <BreakWordDiv className={className}>
        <h4 className="font-weight-bold mb-4">{t("bookings.operations.costs")}</h4>
        <Skeleton.Line width="100%" height="24px" />
        <Skeleton.Line width="100%" height="24px" />
        <Skeleton.Line width="100%" height="24px" />
      </BreakWordDiv>
    );
  }

  const {
    resourcePriceDescription,
    resourcePrice,
    addOnPrices,
    taxes,
    subtotal,
    total,
    currencyCode,
    discountsV2: discounts,
    resourcePriceWithDiscount,
  } = charges;
  const hasDiscounts = discounts.length > 0;

  return (
    <BreakWordDiv className={className}>
      <h4 className="font-weight-bold mb-4">{t("bookings.operations.costs")}</h4>
      <LineItem
        className="pb-3"
        item={t("bookings.operations.roomBooking")}
        description={resourcePriceDescription}
        amount={resourcePrice}
        currencyCode={currencyCode}
        resourcePriceIsDiscounted={hasDiscounts}
      />
      {hasDiscounts && (
        <>
          {discounts.map((d, key) => (
            <DiscountPercentageLineItem
              key={key}
              className={`${className} ps-4 d-percentage`}
              name={d.title}
              percentage={d.percentage}
            />
          ))}
          <LineItem
            className={`${className} ps-4 resource-price-discounted`}
            item={t("bookings.discounts.priceWithDiscount")}
            amount={resourcePriceWithDiscount}
            currencyCode={currencyCode}
          />
        </>
      )}
      {addOnPrices.map((ao, i) => (
        <LineItemAddOn
          className="pb-3"
          key={i}
          item={ao.description}
          description={(ao.quantity ?? 0) > 0 ? `x${ao.quantity}` : null}
          amount={ao.price}
          currencyCode={currencyCode}
        />
      ))}
      {taxes.map((tax, i) => (
        <LineItem
          className="pb-3"
          key={i}
          isTax
          item={tax.description}
          amount={tax.price}
          currencyCode={currencyCode}
        />
      ))}
      {(total > 0 || subtotal > 0) && (
        <LineItem className="pb-3" isTotal item={t("common.total")} amount={total} currencyCode={currencyCode} />
      )}
    </BreakWordDiv>
  );
};
