import React, { useCallback, useMemo } from "react";
import {
  BookableResourceTypeDisplayMode,
  useBookableResourceTypesQuery,
  useCreateUpdateResourceTypeMutation,
} from "../../../../generated/gateway-client";
import { useToast } from "@equiem/react-admin-ui";
import type { FormValues } from "./helpers/FormValues";
import { ResourceTypeDelete } from "./ResourceTypeDelete";
import { ResourceConfigurationList } from "./ResourceConfigurationList";
import type { Item } from "./helpers/Item";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { resourceTypeToString } from "../../../../lib/resourceTypeToString";

export const ResourceTypeList: React.FC = () => {
  const { data, loading, refetch } = useBookableResourceTypesQuery({
    variables: { displayMode: BookableResourceTypeDisplayMode.Eq1 },
    fetchPolicy: "network-only",
  });
  const [mutation] = useCreateUpdateResourceTypeMutation();
  const toast = useToast();
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();

  const submitIt = useCallback(
    async (values: FormValues) => {
      try {
        await mutation({
          variables: {
            input: {
              name: values.name,
              uuid: values.uuid,
            },
          },
        });
        await refetch();

        return true;
      } catch (e: unknown) {
        toast.negative(tError(e), { autoDismiss: true });
      }

      return false;
    },
    [mutation, refetch, tError, toast],
  );

  const deleteComponent = (p: { item: Item; disable: boolean }) => {
    return (
      <ResourceTypeDelete
        item={p.item}
        disable={p.disable}
        refetch={async () => {
          await refetch();
        }}
      />
    );
  };

  const resourceTypes = useMemo(() => {
    return (data?.bookableResourceTypes ?? []).map((resourceType) => {
      return resourceType.locked
        ? {
            ...resourceType,
            name: resourceTypeToString(resourceType.name, t),
          }
        : resourceType;
    });
  }, [data?.bookableResourceTypes, t]);

  return (
    <ResourceConfigurationList
      data={resourceTypes}
      deleteComponent={deleteComponent}
      submitIt={submitIt}
      dataLoading={loading}
      createBtnTitle={t("bookings.settings.addType")}
    />
  );
};
