import type { ReportParams } from "./ReportParams";
import { formatDuration, formatDateTime, formatPaymentMethod, formatCurrency } from "./formatters";
import { getCsvFileName } from "./getCsvFileName";
import { downloadCsv } from "./downloadCsv";

interface CsvRow {
  company: string;
  userName: string;
  invoiceContactName: string;
  invoiceContactEmail: string;
  resource: string;
  reference: string;
  created: string;
  startDate: string;
  duration: string;
  resourceCost: string;
  addOnsCost: string;
  additionalCharges: string;
  refunds: string;
  cancellationCost: string;
  totalTax: string;
  totalCost: string;
  paymentMethod: string;
  cancelled: string;
  totalsRowLabel?: "Totals" | "";
}

export const downloadReconciliationReport = (params: ReportParams) => {
  const { timezone, bookings, totals, currencyCode } = params;

  downloadCsv<CsvRow>({
    fileName: getCsvFileName("reconciliation", params),
    columns: [
      { key: "company", header: "Company" },
      { key: "userName", header: "Booked by" },
      { key: "invoiceContactName", header: "Person invoiced" },
      { key: "invoiceContactEmail", header: "Invoicing email" },
      { key: "resource", header: "Resource" },
      { key: "reference", header: "Reference" },
      { key: "created", header: "Creation date" },
      { key: "startDate", header: "Date of booking" },
      { key: "duration", header: "Duration" },
      { key: "resourceCost", header: "Booking cost" },
      { key: "addOnsCost", header: "Extras cost" },
      { key: "additionalCharges", header: "Additional charges" },
      { key: "refunds", header: "Refunds" },
      { key: "cancellationCost", header: "Cancellation cost" },
      { key: "totalTax", header: "Tax" },
      { key: "totalCost", header: "Total cost" },
      { key: "paymentMethod", header: "Payment method" },
      { key: "cancelled", header: "Cancelled" },
      { key: "totalsRowLabel", header: "" },
    ],
    rows: [
      ...bookings.map((booking) => ({
        company: booking.companyName ?? "",
        userName: booking.userName ?? "",
        invoiceContactName: booking.invoiceContactName ?? "",
        invoiceContactEmail: booking.invoiceContactEmail ?? "",
        resource: booking.resourceName ?? "",
        reference: booking.reference,
        created: formatDateTime(booking.created, timezone),
        startDate: formatDateTime(booking.startDate, timezone),
        duration: formatDuration(booking.durationHours),
        resourceCost: formatCurrency(booking.resourcePrice, currencyCode),
        addOnsCost: formatCurrency(booking.addOnsPrice, currencyCode),
        additionalCharges: formatCurrency(booking.adjustmentsTotalPrice, currencyCode),
        refunds: formatCurrency(booking.allPartialRefundsTotalPrice, currencyCode),
        cancellationCost: formatCurrency(booking.cancellationPrice, currencyCode),
        totalTax: formatCurrency(booking.totalTax, currencyCode),
        totalCost: formatCurrency(booking.totalPrice, currencyCode),
        paymentMethod: formatPaymentMethod(booking.paymentMethod),
        cancelled: booking.cancelled ? "TRUE" : "FALSE",
      })),
      {
        company: "",
        userName: "",
        invoiceContactName: "",
        invoiceContactEmail: "",
        resource: "",
        reference: "",
        created: "",
        startDate: "",
        duration: formatDuration(totals.durationHours),
        resourceCost: formatCurrency(totals.resourcePrice, currencyCode),
        addOnsCost: formatCurrency(totals.addOnsPrice, currencyCode),
        additionalCharges: formatCurrency(totals.adjustmentsTotalPrice, currencyCode),
        refunds: formatCurrency(totals.allPartialRefundsTotalPrice, currencyCode),
        cancellationCost: formatCurrency(totals.cancellationPrice, currencyCode),
        totalTax: formatCurrency(totals.totalTax, currencyCode),
        totalCost: formatCurrency(totals.totalPrice, currencyCode),
        paymentMethod: "",
        cancelled: "",
        totalsRowLabel: "Totals",
      },
    ],
  });
};
