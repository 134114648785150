import type { PropsWithChildren } from "react";
import React, { createContext, useCallback, useRef, useState } from "react";
import type { BookableResourceFragmentFragment, BookingFragmentFragment } from "../../../generated/gateway-client";
import { Button, Modal } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

type Resource = BookableResourceFragmentFragment;
type Booking = BookingFragmentFragment;
type Expansion = { resource: Resource; booking?: Booking };
export type DisplayMode = "adminForm" | "selfForm" | "view";

export interface BookingModalContext {
  id?: string;
  start?: number;
  end?: number;
  open: (id: string, start?: number, end?: number) => void;
  close: (skipConfirmation?: boolean) => void;
  setConfirmationTextBeforeClose: (text: string) => void;
  setShowConfirmationBeforeClose: (value: boolean) => void;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  footerRef: React.RefObject<HTMLDivElement> | null;
  showExpansion?: Expansion;
  setShowExpansion: (val?: Expansion) => void;
  canCloseModal: boolean;
  setCanCloseModal: (value: boolean) => void;
  canSubmitForm: boolean;
  setCanSubmitForm: (value: boolean) => void;
  setDisplayMode: (mode: DisplayMode) => void;
  displayMode: DisplayMode;
  setStart: (val?: number) => void;
  setEnd: (val?: number) => void;
}

export const BookingModal = createContext<BookingModalContext>({
  title: "",
  setTitle: () => undefined,
  open: () => undefined,
  close: () => undefined,
  footerRef: null,
  setShowExpansion: () => undefined,
  canCloseModal: false,
  setCanCloseModal: () => undefined,
  canSubmitForm: false,
  setCanSubmitForm: () => undefined,
  setConfirmationTextBeforeClose: () => undefined,
  setShowConfirmationBeforeClose: () => undefined,
  setDisplayMode: () => undefined,
  displayMode: "selfForm",
  setStart: () => undefined,
  setEnd: () => undefined,
});

interface P extends PropsWithChildren {
  defaultId?: string;
  defaultDisplayMode?: DisplayMode;
}
export const BookingModalProvider: React.FC<P> = ({ children, defaultId, defaultDisplayMode = "selfForm" }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [id, setId] = useState<string | undefined>(defaultId);
  const [start, setStart] = useState<number | undefined>();
  const [end, setEnd] = useState<number | undefined>();
  const [showExpansion, setShowExpansion] = useState<Expansion | undefined>(undefined);
  const footerRef = useRef<HTMLDivElement>(null);
  const [canCloseModal, setCanCloseModal] = useState(true);
  const [canSubmitForm, setCanSubmitForm] = useState(false);

  const [displayMode, setDisplayMode] = useState(defaultDisplayMode);

  const [confirmationTextBeforeClose, setConfirmationTextBeforeClose] = useState(
    t("bookings.operations.cancelBookingEditConfirmation"),
  );
  const [showConfirmationBeforeClose, setShowConfirmationBeforeClose] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const open = useCallback((idInput: string, startInput?: number, endInput?: number) => {
    setId(idInput);
    setStart(startInput);
    setEnd(endInput);
  }, []);

  const closeModal = useCallback(() => {
    if (canCloseModal) {
      setCanSubmitForm(false);
      setCanCloseModal(true);
      closeConfirmationModal();
      setShowConfirmationBeforeClose(false);
      setTitle("");
      setShowExpansion(undefined);
      setId(undefined);
      setStart(undefined);
      setEnd(undefined);
    }
  }, [canCloseModal, closeConfirmationModal]);

  const close = useCallback(
    (skipConfirmation = false) => {
      if (!skipConfirmation && showConfirmationBeforeClose) {
        setShowConfirmationModal(true);
        return;
      }
      closeModal();
    },
    [closeModal, showConfirmationBeforeClose],
  );

  return (
    <BookingModal.Provider
      value={{
        displayMode,
        setDisplayMode,
        setConfirmationTextBeforeClose,
        setShowConfirmationBeforeClose,
        showExpansion,
        canCloseModal,
        setCanCloseModal,
        canSubmitForm,
        setCanSubmitForm,
        setShowExpansion,
        footerRef,
        title,
        setTitle,
        open,
        close,
        id,
        start,
        setStart,
        end,
        setEnd,
      }}
    >
      {children}
      {showConfirmationModal && (
        <Modal.Dialog
          title={t("common.areYouSure")}
          show
          onHide={closeConfirmationModal}
          hideOnEsc={true}
          supportsMobile={true}
          mobileView
          hideOnClick={false}
          focusTrapOptions={{ initialFocus: false }}
          size="md"
          className="cancel-action-modal"
          centered
        >
          <Modal.Header closeButton={false} noBorder={true} />
          <Modal.Body>{confirmationTextBeforeClose}</Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" className="mr-4" onClick={closeConfirmationModal}>
              {t("common.cancelNo")}
            </Button>
            <Button variant="danger" className="cancel-action" onClick={closeModal}>
              {t("common.yesCancel")}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      )}
    </BookingModal.Provider>
  );
};
