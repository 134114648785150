import type { Stripe } from "@stripe/stripe-js";
import { useContext, useEffect } from "react";
import { guardStripe, stringNotEmpty } from "@equiem/lib";
import { DateTime } from "luxon";
import type {
  BookingCreditCardInput,
  BookingCreditsInput,
  BookingInvoiceContactInput,
  BookableResourceFragmentFragment,
  BookResourceMutationMutation,
} from "../../../generated/gateway-client";
import {
  BookableResourceAddOnType,
  BookableResourcePaymentMethod,
  PaymentIntentStatus,
  useBookingChargesLazyQuery,
  useBookingChargesQuery,
  useBookResourceMutationMutation,
  useUpdateResourceBookingMutationMutation,
  useSiteBookingByReferenceQuery,
} from "../../../generated/gateway-client";
import { convertInputAddOnsToBookingAddOnInput } from "../libs/convertInputAddOnsToBookingAddOnInput";
import { convertBookingAddOnsToInputAddOns } from "../libs/convertBookingAddOnsToInputAddOns";
import type { BookingFormValue } from "../models/BookingFormValue";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { BookingModal } from "../contexts/BookingModalContext";
import { useErrorTranslation, useServerMessageTranslation, useTranslation } from "@equiem/localisation-eq1";
import type { FormikHelpers } from "formik";
import { useStripe } from "@stripe/react-stripe-js";
import { useToast } from "@equiem/react-admin-ui";
import { useLazyBookingAppointment } from "./useLazyBookingAppointment";

const getTimeMillis = (date: string, time: string, timezone: string) =>
  DateTime.fromFormat(`${date} ${time}`, "yyyy-MM-dd HH:mm", {
    zone: timezone,
  }).toMillis();

const toInvoiceContact = (values: BookingFormValue): BookingInvoiceContactInput | undefined => {
  const contact = values.contact;
  if (
    contact == null ||
    !stringNotEmpty(contact.type) ||
    values.totalCharges == null ||
    values.totalCharges === 0 ||
    values.paymentMethod !== BookableResourcePaymentMethod.Invoice
  ) {
    return undefined;
  }

  return {
    contactType: contact.type,
    contactUuid: contact.uuid,
    billingCustomerId: contact.billingCustomerId,
    email: contact.email,
    fullName: contact.fullName,
    saveDetails: contact.saveDetails ?? undefined,
  };
};

const toCreditCard = (values: BookingFormValue): BookingCreditCardInput | undefined => {
  if (
    values.creditcard?.paymentGatewayPaymentMethodId == null ||
    values.totalCharges == null ||
    values.totalCharges === 0 ||
    values.paymentMethod !== BookableResourcePaymentMethod.CreditCard
  ) {
    return undefined;
  }

  return values.creditcard;
};

const toCreditsInput = (values: BookingFormValue): BookingCreditsInput | undefined => {
  if (
    values.creditAccount?.uuid == null ||
    values.totalCharges == null ||
    values.totalCharges === 0 ||
    values.paymentMethod !== BookableResourcePaymentMethod.Credits
  ) {
    return undefined;
  }

  return {
    accountUuid: values.creditAccount.uuid,
  };
};

export function toPaymentInput(values: BookingFormValue, isUpdate = false) {
  const invoiceContact = toInvoiceContact(values);
  const creditCard = toCreditCard(values);
  const creditAccount = toCreditsInput(values);
  const paymentMethod =
    values.paymentMethod != null && (invoiceContact != null || creditCard != null || creditAccount != null)
      ? values.paymentMethod
      : undefined;

  return {
    paymentMethod,
    invoiceContact,
    creditCard,
    creditAccount: isUpdate ? undefined : creditAccount,
  };
}

export function toBookingInput(timezone: string, values: BookingFormValue) {
  return {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    startDate: getTimeMillis(values.date!, values.start!, timezone),
    endDate: getTimeMillis(values.date!, values.end!, timezone),
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
    roomConfiguration: stringNotEmpty(values.roomConfiguration) ? values.roomConfiguration : null,
    title: stringNotEmpty(values.title) ? values.title.trim() : null,
  };
}

export const useBookingCreate = () => {
  const [mutation, { loading }] = useBookResourceMutationMutation();
  const { resource, timezone } = useContext(BookingModalInfo);

  return {
    loading,
    book: async (values: BookingFormValue) => {
      const result = await mutation({
        variables: {
          input: {
            resourceUuid: resource.uuid,
            proxyBookingforUser: values.isProxyBooking ? values.host?.uuid : undefined,
            acceptTerms: values.termsAccepted,
            addOns: convertInputAddOnsToBookingAddOnInput(resource.addOns, values.addOns),
            ...toBookingInput(timezone, values),
            ...toPaymentInput(values),
          },
        },
      });

      return result.data?.bookResource;
    },
  };
};

export const useBookingEdit = () => {
  const { t } = useTranslation();
  const [mutation, { loading }] = useUpdateResourceBookingMutationMutation();
  const { resource, booking, timezone } = useContext(BookingModalInfo);

  return {
    loading,
    book: async (values: BookingFormValue) => {
      if (booking == null) {
        throw new Error(t("bookings.operations.bookingNotFound"));
      }
      const result = await mutation({
        variables: {
          uuid: booking.uuid,
          input: {
            addOns: convertInputAddOnsToBookingAddOnInput(resource.addOns, values.addOns),
            ...toBookingInput(timezone, values),
            ...toPaymentInput(values, true),
          },
        },
      });

      return result.data?.updateResourceBooking;
    },
  };
};

const handleEndBookingPaymentIntentResult = async (
  result: BookResourceMutationMutation["bookResource"] | undefined | null,
  stripe: Stripe,
) => {
  if (result == null || result.__typename !== "BookingSuccessResponse") {
    return;
  }
  if (result.paymentIntent != null && result.paymentIntent.status === PaymentIntentStatus.RequiresAction) {
    const confirmation = await stripe.confirmCardPayment(result.paymentIntent.clientSecret);
    if (confirmation.error != null) {
      console.error(confirmation.error);
      throw new Error(confirmation.error.message);
    }
  }
};

export const useBookingFormSubmit = () => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const { tServer } = useServerMessageTranslation();
  const { booking } = useContext(BookingModalInfo);
  const toast = useToast();
  const modal = useContext(BookingModal);
  const stripe = useStripe();
  const { goToAppointment } = useLazyBookingAppointment();
  const { book: create, loading: cLoading } = useBookingCreate();
  const { book: edit, loading: eLoading } = useBookingEdit();
  const { book, submitting } =
    booking != null ? { book: edit, submitting: eLoading } : { book: create, submitting: cLoading };

  return async (values: BookingFormValue, _formikHelpers: FormikHelpers<BookingFormValue>) => {
    try {
      if (submitting || !modal.canSubmitForm) {
        return;
      }
      modal.setCanCloseModal(false);
      modal.setCanSubmitForm(false);

      guardStripe(t, stripe);
      const result = await book(values);
      if (result == null) {
        throw new Error(
          booking == null ? t("bookings.operations.unableMakeBooking") : t("bookings.operations.unableEditBooking"),
        );
      }
      if (result.__typename === "BookingFailureResponse") {
        throw new Error(tServer(result.localisedReason));
      }
      await handleEndBookingPaymentIntentResult(result, stripe);

      if (booking != null) {
        toast.positive(t("bookings.operations.bookingReferenceUpdated", { bookingReference: booking.reference }));
      } else if (result.booking.status === "PENDING_APPROVAL") {
        toast.positive(t("bookings.operations.bookingHasBeenRequested"));
      } else {
        toast.positive(
          t("bookings.operations.bookingHasBeenConfirmed", { bookingReference: result.booking.reference }),
        );
      }

      if (
        process.env.bookingVisitorInvitesEnabled === "true" &&
        result.booking.status !== "PENDING_APPROVAL" &&
        values.createAppointmentOnSubmit
      ) {
        await goToAppointment(result.booking);
      }

      modal.close(true);
    } catch (e: unknown) {
      toast.negative(tError(e));
    }
    modal.setCanCloseModal(true);
    modal.setCanSubmitForm(true);
  };
};

export const useBookingCharges = ({
  resource,
  timezone,
  values,
  skip = false,
}: {
  resource: BookableResourceFragmentFragment;
  timezone: string;
  values: BookingFormValue;
  skip?: boolean;
}) => {
  // Default to displaying charges in Credits if the resource supports credits
  const paymentMethod =
    values.paymentMethod ??
    (resource.paymentMethods.includes(BookableResourcePaymentMethod.Credits)
      ? BookableResourcePaymentMethod.Credits
      : null);

  return useBookingChargesQuery({
    variables: {
      input: {
        resourceUuid: resource.uuid,
        proxyBookingforUser: values.isProxyBooking ? values.host?.uuid : undefined,
        ...toBookingInput(timezone, values),
        ...toPaymentInput(values, false),
        paymentMethod,
        // Don't need to request for free text changes.
        addOns: convertInputAddOnsToBookingAddOnInput(resource.addOns, values.addOns, [
          BookableResourceAddOnType.FreeText,
        ]),

        // don't re-request for input fields that won't affect the booking charges
        title: null,
        roomConfiguration: null,
        acceptTerms: false,
        invoiceContact: null,
        creditAccount: null,
        creditCard: null,
      },
    },
    skip,
  });
};

export const useCancelBookingCharges = ({
  bookingReference,
  skip = false,
}: {
  bookingReference: string;
  skip?: boolean;
}) => {
  const booking = useSiteBookingByReferenceQuery({ variables: { reference: bookingReference }, skip });
  const [loadCharges, charges] = useBookingChargesLazyQuery();

  useEffect(() => {
    if (skip || booking.loading || booking.data?.siteBookingByReference == null || booking.error != null) {
      return;
    }

    const { resource, startDate, endDate, addOns } = booking.data.siteBookingByReference;

    void loadCharges({
      variables: {
        input: {
          resourceUuid: resource.uuid,
          startDate,
          endDate,
          addOns: convertInputAddOnsToBookingAddOnInput(
            resource.addOns,
            convertBookingAddOnsToInputAddOns(resource.addOns, addOns),
          ),

          // don't re-request for input fields that won't affect the booking charges
          roomConfiguration: null,
          acceptTerms: false,
        },
      },
    });
  }, [skip, booking, loadCharges]);

  return {
    data: charges.data,
    error: booking.error ?? charges.error,
    loading: booking.loading || charges.loading,
  };
};
