import React, { useState } from "react";
import { useQueryState } from "@equiem/lib";
import type { RadioIconOption } from "@equiem/react-admin-ui";
import { Button, RadioIcons, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiListCheck, RiCalendar2Line } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import { useSiteBookingsFilters } from "./hooks/useSiteBookingsFilters";
import { BookingsTab } from "../../components/BookingsTab";
import { usePagedSiteBookings } from "../../hooks/usePagedSiteBookings";
import { matchesSearchText } from "../../lib/matchesSearch";
import { OperationsList } from "./components/OperationsList";
import { OperationsCalendar } from "./components/OperationsCalendar";
import { getSearchTokens } from "./models/BookingFilters";
import { usePrintableBookings } from "./hooks/usePrintableBookings";

enum View {
  LIST = "list",
  CALENDAR = "calendar",
}

const isView = (v: unknown): v is View => Object.values(View).includes(v as View);

export const BookingsManagement: React.FC = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const viewOptions: Array<RadioIconOption<View>> = [
    {
      icon: RiListCheck,
      value: View.LIST,
      className: View.LIST,
      tooltipText: t("bookings.operations.list"),
    },
    {
      icon: RiCalendar2Line,
      value: View.CALENDAR,
      className: View.CALENDAR,
      tooltipText: t("bookings.operations.calendar"),
    },
  ];

  const [{ view }, setQueryState] = useQueryState({
    initial: { view: View.LIST },
    parse: { view: (v) => (isView(v) ? v : View.LIST) },
    clearQueryOnChange: true,
    rememberLastState: true,
  });
  const { filterItems, queryFilter, setFilterValues } = useSiteBookingsFilters(view);
  const [searchText, setSearchText] = useState("");
  const { breakpoints } = useTheme();
  const listResult = usePagedSiteBookings(queryFilter, view !== View.LIST);
  const listBookingsToShow = listResult.bookings.filter((booking) =>
    matchesSearchText(searchText, getSearchTokens(booking, i18n.language)),
  );

  const [isPrinting, setIsPrinting] = useState(false);
  const { handlePrint, renderPrintTable } = usePrintableBookings();
  const onPrintClicked = async () => {
    if (isPrinting) {
      return;
    }

    setIsPrinting(true);
    try {
      const bookingsToPrint = (await listResult.fetchAll())?.filter((booking) =>
        matchesSearchText(searchText, getSearchTokens(booking, i18n.language)),
      );
      if (bookingsToPrint != null) {
        handlePrint(bookingsToPrint);
      }
    } catch (e: unknown) {
      console.error(e);
      toast.negative(t("common.unknownError"));
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <>
      <BookingsTab
        title={
          <RadioIcons
            options={viewOptions}
            value={view}
            onChange={(newView: View) => setQueryState({ view: newView })}
          />
        }
        search={{ searchText, setSearchText }}
        filters={{ items: filterItems, setValues: setFilterValues }}
        button={
          view === View.LIST && (
            <Button
              className="main-button"
              variant="primary"
              size="md"
              onClick={() => {
                void onPrintClicked();
              }}
            >
              {t("bookings.operations.printRunSheet")}
            </Button>
          )
        }
      >
        <div className="operations-list">
          {view === View.LIST && (
            <OperationsList {...listResult} bookings={listBookingsToShow} isPrinting={isPrinting} />
          )}
          {view === View.CALENDAR && <OperationsCalendar filters={queryFilter} searchText={searchText} />}
        </div>
      </BookingsTab>
      {renderPrintTable()}
      <style jsx>{`
        .main-button {
          text-transform: uppercase;
        }
        .operations-list {
          padding: 0 32px 16px;
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .operations-list {
            padding: 0 16px 16px;
          }
        }
      `}</style>
    </>
  );
};
