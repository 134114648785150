import { useState } from "react";
import type { FilterItem, FilterValue } from "@equiem/react-admin-ui";
import { FilterDateModifier, FilterTextModifier } from "@equiem/react-admin-ui";
import {
  RiBuilding4Line,
  RiTable2,
  RiArticleLine,
  RiInputMethodLine,
  RiRepeat2Line,
} from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import { DateTime } from "luxon";
import pickBy from "lodash/pickBy";
import {
  BookableResourceTypeDisplayMode,
  BookingStatus,
  useBookableResourceTypesQuery,
  useDestinationDetailsQuery,
} from "../../../generated/gateway-client";
import { resourceTypeToString } from "../../../lib/resourceTypeToString";

export interface SiteBookingsFilters {
  startDate?: number;
  endDate?: number;
  buildingUuid?: string[];
  levelUuid?: string[];
  resourceName?: string;
  resourceTypeUuid?: string[];
  status?: BookingStatus[];
}

const filterKeys = {
  list: ["building", "level", "resourceName", "resourceTypeUuid", "date", "bookingStatus"],
  calendar: ["building", "level", "resourceName", "resourceTypeUuid"],
} as const;

type View = keyof typeof filterKeys;
type FilterKey = (typeof filterKeys)[View][number] | (typeof filterKeys)[View][number];
type ConcreteFilterValues = Partial<Record<FilterKey, FilterValue>>;

interface Result {
  filterItems: Record<string, FilterItem>;
  queryFilter: SiteBookingsFilters;
  setFilterValues: (values: Record<string, FilterValue>) => void;
}

const getOptionValue = <T extends string = string>(v: FilterValue | undefined): T[] | undefined =>
  v?.type === "options" && v.value != null && v.value.length > 0 ? v.value.map(({ value }) => value as T) : undefined;
const getTextValue = (v: FilterValue | undefined) =>
  v?.type === "text" && stringNotEmpty(v.value) ? v.value.trim() : undefined;

function getFilter(values: ConcreteFilterValues, view: View, timezone: string): SiteBookingsFilters {
  // ComplexeFilter remembers filters for other views that might not be relevant
  // to this view. That's useful for the user, but we should exclude them from
  // the query filters.
  const viewValues: ConcreteFilterValues = pickBy(values, (_, key) =>
    (filterKeys[view] as ReadonlyArray<string>).includes(key),
  );

  let startDate: number | undefined = undefined;
  let endDate: number | undefined = undefined;

  if (viewValues.date?.type === "date" && viewValues.date.value != null) {
    const [fromIso, untilIso] = Array.isArray(viewValues.date.value)
      ? viewValues.date.value
      : ([viewValues.date.value, viewValues.date.value] as const);

    startDate = DateTime.fromISO(fromIso, { zone: timezone }).startOf("day").toMillis();
    endDate = DateTime.fromISO(untilIso, { zone: timezone }).endOf("day").toMillis();
  }

  return {
    startDate,
    endDate,
    buildingUuid: getOptionValue(viewValues.building),
    levelUuid: getOptionValue(viewValues.level),
    resourceName: getTextValue(viewValues.resourceName),
    resourceTypeUuid: getOptionValue(viewValues.resourceTypeUuid),
    status: getOptionValue<BookingStatus>(viewValues.bookingStatus),
  };
}

export function useSiteBookingsFilters(view: View): Result {
  const [values, setValues] = useState<ConcreteFilterValues>({});
  const { uuid: siteUuid, timezone } = useSiteContext();
  const { t } = useTranslation();

  const { data } = useDestinationDetailsQuery({
    variables: { uuid: siteUuid },
  });

  const { data: dataResourceType } = useBookableResourceTypesQuery({
    variables: { displayMode: BookableResourceTypeDisplayMode.Eq1 },
    fetchPolicy: "network-only",
  });

  const singleBuilding = data?.destination.buildings?.length === 1 || getOptionValue(values.building)?.length === 1;

  const building: FilterItem = {
    title: t("bookings.resources.resourceBuilding"),
    icon: RiBuilding4Line,
    type: "options",
    options: data?.destination.buildings?.map((b) => ({ value: b.uuid, label: b.name })) ?? [],
  };
  const level: FilterItem = {
    title: t("bookings.resources.resourceLevel"),
    icon: RiTable2,
    type: "options",
    options:
      data?.destination.buildings
        ?.filter((b) => getOptionValue(values.building)?.includes(b.uuid) ?? true)
        .flatMap((b) =>
          b.buildingLevels.map((l) => ({
            value: l.uuid,
            label: singleBuilding ? l.name : `${l.name} (${b.name})`,
          })),
        ) ?? [],
  };
  const resourceName: FilterItem = {
    title: t("bookings.resources.resourceName"),
    icon: RiArticleLine,
    type: "text",
    modifiers: [FilterTextModifier.includes],
  };
  const resourceTypeUuid: FilterItem = {
    title: t("bookings.resources.resourceTypeFull"),
    icon: RiInputMethodLine,
    type: "options",
    options: (dataResourceType?.bookableResourceTypes ?? [])
      .map((rt) => ({
        value: rt.uuid,
        label: resourceTypeToString(rt.name, t),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  };
  const date: FilterItem = {
    title: t("common.date"),
    type: "date",
    modifiers: [FilterDateModifier.is, FilterDateModifier.between],
  };
  const bookingStatus: FilterItem = {
    title: t("common.status"),
    icon: RiRepeat2Line,
    type: "options",
    options: [
      { value: BookingStatus.PendingApproval, label: t("bookings.operations.status.pendingApproval") },
      { value: BookingStatus.Approved, label: t("bookings.operations.status.approved") },
      { value: BookingStatus.Declined, label: t("bookings.operations.status.declined") },
      { value: BookingStatus.Cancelled, label: t("bookings.reports.cancelled") },
      { value: BookingStatus.PendingPayment, label: t("bookings.operations.status.pendingPayment") },
      { value: BookingStatus.PaymentFailed, label: t("bookings.operations.status.paymentFailed") },
    ],
  };

  const items: Record<View, Partial<Record<FilterKey, FilterItem>>> = {
    list: {
      building,
      level,
      resourceName,
      resourceTypeUuid,
      date,
      bookingStatus,
    },
    calendar: {
      building,
      level,
      resourceName,
      resourceTypeUuid,
    },
  };

  return {
    filterItems: items[view],
    queryFilter: getFilter(values, view, timezone),
    setFilterValues: setValues,
  };
}
