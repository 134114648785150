import type { ReactNode } from "react";
import React, { useState, type FC } from "react";
import { FlexTenantLocationContext } from "./FlexTenantLocationContext";
import type { Building } from "../components/lib/flattenLocations";

interface Props {
  children: ReactNode;
  defaultLocations?: Building[];
}

export const FlexTenantLocationProvider: FC<Props> = ({ children, defaultLocations = [] }) => {
  const [locations, setLocations] = useState<Building[]>(defaultLocations);

  const clearLocations = () => {
    setLocations([]);
  };

  return (
    <>
      <FlexTenantLocationContext.Provider
        value={{
          locations,
          setLocations,
          clearLocations,
        }}
      >
        {children}
      </FlexTenantLocationContext.Provider>
    </>
  );
};
