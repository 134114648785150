import { Calendar as CalendarView, Text, useDebounced, useTheme } from "@equiem/react-admin-ui";
import { DateTime } from "luxon";
import React, { useCallback, useContext } from "react";
import { useTakenTimesQuery } from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingCalendarContext } from "../contexts/BookingCalendarContext";
import { dateFormat } from "../libs/formatSelectedTime";
import { BookingModal } from "../contexts/BookingModalContext";

const debounceTimeout = 500;

interface P {
  className?: string;
}
export const BookingCalendar: React.FC<P> = ({ className = "" }) => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const modal = useContext(BookingModal);
  const { resource, booking, timezone } = useContext(BookingModalInfo);
  const { selectedDate, setSelectedDate, selectedTime } = useContext(BookingCalendarContext);

  const selectedTimeDebounced = useDebounced(selectedTime, debounceTimeout);
  const target = DateTime.fromFormat(selectedDate.date, dateFormat, {
    zone: timezone,
  });
  const timesQuery = useTakenTimesQuery({ fetchPolicy: "network-only", skip: true });

  const getEventsCb = useCallback(
    async (originalTimeOfTheWeek: DateTime) => {
      const [start, end] = [
        originalTimeOfTheWeek.startOf("week").toMillis(),
        originalTimeOfTheWeek.endOf("week").toMillis(),
      ];

      try {
        const fetchme = await timesQuery.fetchMore({
          variables: {
            uuid: resource.uuid,
            start,
            end,
            excludeBooking: booking?.uuid,
          },
        });
        const takenTimes = fetchme.data.bookableResource.takenTimes;

        return takenTimes;
      } catch (e: unknown) {
        console.log(e instanceof Error ? e.message : "Unknown error on refetching events.");

        return [];
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedDate, modal.displayMode, timesQuery, resource.uuid, selectedTime],
  );

  return (
    <div className={`calendar-outer-cont ${className}`}>
      <div className="pb-3">
        <Text variant="label" color={theme.colors.grayscale[60]}>
          {t("bookings.operations.availability")}
        </Text>
      </div>
      <div className="calendar-cont">
        <CalendarView
          getBlockedTimesCb={getEventsCb}
          timezone={timezone}
          selectedDate={target}
          selectedTime={selectedTimeDebounced}
        />
      </div>
      <style jsx>{`
        .calendar-cont {
          background: #fff;
          padding: ${theme.spacers.s5};
          border-radius: ${theme.borderRadius};
        }
      `}</style>
    </div>
  );
};
