import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Form, Material, ProgressCircle, Text, useTheme } from "@equiem/react-admin-ui";
import { Field, useFormikContext } from "formik";
import { groupBy } from "lodash";
import React from "react";
import {
  useBookableResourceFeaturesQuery,
  useBookableResourceRoomConfigurationsQuery,
} from "../../../../../generated/gateway-client";
import type { FormValues } from "../../../../../lib/formValidation";
import {
  resourceConfigurationToString,
  resourceFeatureGroupToString,
  resourceFeatureToString,
} from "../../../../../lib/resourceTypeToString";

export const ResourceCreateAndEditFormConfigurations: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const { colors, spacers } = useTheme(true);
  const fm = useFormikContext<FormValues>();

  const {
    data: roomConfigsData,
    loading: roomConfigsLoading,
    error: roomConfigsError,
  } = useBookableResourceRoomConfigurationsQuery();
  const { data: featuresData, loading: featuresLoading, error: featuresError } = useBookableResourceFeaturesQuery();

  if (roomConfigsLoading || featuresLoading) {
    return <ProgressCircle size="md" />;
  }

  if (roomConfigsError != null) {
    return <span>{tError(roomConfigsError)}</span>;
  }
  if (featuresError != null) {
    return <span>{tError(featuresError)}</span>;
  }

  const roomConfigs = roomConfigsData?.bookableResourceRoomConfigurations ?? [];
  const features = featuresData?.bookableResourceFeatures ?? [];
  if (roomConfigs.length === 0 && features.length === 0) {
    return null;
  }

  const groupFeatures = groupBy(features, "type");

  return (
    <>
      <div>
        <Form.Group error={fm.errors.roomConfigurations} label={t("bookings.resources.roomConfiguration")}>
          <div className="features-group-container">
            {roomConfigs.map((config) => (
              <Field
                id={config.uuid}
                key={config.uuid}
                type="button"
                name="roomConfigurations"
                disabled={fm.isSubmitting}
                label={resourceConfigurationToString(config.name, t)}
                as={Form.ToggleButton}
                value={fm.values.roomConfigurations?.includes(config.uuid) ?? false}
                onChange={(state: boolean) => {
                  const updatedRoomConfigurations = state
                    ? [...(fm.values.roomConfigurations ?? []), config.uuid]
                    : (fm.values.roomConfigurations ?? []).filter((uuid) => uuid !== config.uuid);
                  const filteredRoomConfigs = roomConfigs
                    .filter((roomConfig) => updatedRoomConfigurations.includes(roomConfig.uuid))
                    .map((roomConfig) => roomConfig.uuid);
                  fm.setFieldValue("roomConfigurations", filteredRoomConfigs).catch(console.error);
                }}
              />
            ))}
          </div>
        </Form.Group>
      </div>

      <Form.Group error={fm.errors.features}>
        <div className="features-container">
          {Object.keys(groupFeatures).map((featureType) => (
            <div key={featureType}>
              <div className="feature-group-label">
                <Text variant="label" color={colors.grayscale[60]}>
                  {resourceFeatureGroupToString(featureType, t)}
                </Text>
              </div>
              <div className="features-group-container">
                {groupFeatures[featureType].map((f) => (
                  <div key={f.uuid} className="feature-toggle">
                    {f.iconName != null && <Material.Icon className="feature-icon" size="sm" name={f.iconName} />}
                    <Field
                      id={f.uuid}
                      key={f.uuid}
                      type="button"
                      name="features"
                      disabled={fm.isSubmitting}
                      label={resourceFeatureToString(f.name, t)}
                      as={Form.ToggleButton}
                      value={fm.values.features?.includes(f.uuid) ?? false}
                      onChange={(state: boolean) => {
                        const updatedFeatures = state
                          ? [...(fm.values.features ?? []), f.uuid]
                          : (fm.values.features ?? []).filter((uuid) => uuid !== f.uuid);
                        const filteredFeatures = features
                          .filter((feat) => updatedFeatures.includes(feat.uuid))
                          .map((feat) => feat.uuid);
                        fm.setFieldValue("features", filteredFeatures).catch(console.error);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Form.Group>

      <style jsx>{`
        .features-container {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s7};
        }
        .feature-group-label {
          margin-bottom: ${spacers.s4};
        }
        .features-group-container {
          display: flex;
          flex-wrap: wrap;
          gap: ${spacers.s3};
        }
        .features-group-container :global(.feature-toggle) {
          position: relative;
        }
        .features-group-container :global(.feature-toggle button) {
          padding-left: ${spacers.s8};
        }
        .features-group-container :global(.feature-icon) {
          position: absolute;
          top: ${spacers.s4};
          left: ${spacers.s5};
        }
      `}</style>
    </>
  );
};
