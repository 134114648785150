import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Text, useTheme } from "@equiem/react-admin-ui";
import { RiCalendarLine, RiHashtag, RiTimeLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";

interface P {
  className?: string;
}
export const BookingViewBookingInfo: React.FC<P> = ({ className }) => {
  const { i18n } = useTranslation();
  const { booking } = useContext(BookingModalInfo);
  const { colors, spacers } = useTheme(true);

  if (booking == null) {
    return null;
  }

  return (
    <div className="view-booking-info">
      <div className="view-info pb-4">
        <RiHashtag size={16} color={colors.grayscale[50]} />
        <Text variant="text" component="label" size="small">
          {booking.reference}
        </Text>
      </div>
      <div className={`view-booking-time ${className}`}>
        <div className="view-info pb-4">
          <RiCalendarLine size={16} color={colors.grayscale[50]} />
          <Text variant="text" component="label" size="small">
            {formatters.datelong(booking.startDate, i18n.language, { timezone: booking.timezone })}
          </Text>
        </div>
        <div className="view-info">
          <RiTimeLine size={16} color={colors.grayscale[50]} />
          <Text variant="text" component="label" size="small">
            {formatters.timeshort(booking.startDate, i18n.language, { timezone: booking.timezone })}
            {" - "}
            {formatters.timeshort(booking.endDate, i18n.language, { timezone: booking.timezone })}
            {" ("}
            {formatters.durationshort(
              DateTime.fromMillis(booking.endDate).diff(DateTime.fromMillis(booking.startDate)).as("minutes"),
              i18n.language,
            )}
            {")"}
          </Text>
        </div>
      </div>
      <style jsx>{`
        .view-info {
          display: flex;
          gap: ${spacers.s4};
        }
      `}</style>
    </div>
  );
};
