import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { useSaferFormikContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useIsMobileWidth, useToast } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import {
  useUpdateVisitorAppointmentMutation,
  useVisitorAppointmentQuery,
  useVisitorAppointmentsQuery,
} from "../../../generated/visitors-client";
import { initialValues } from "../initialValues";
import { mapFormToDTO } from "../mappings";
import type { FormValues } from "../types";

export const AppointmentRecurringUpdateWidget: React.FC<{ uuid: string; isReceptionView: boolean }> = ({
  uuid,
  isReceptionView,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);
  const { values, resetForm, setSubmitting } = useSaferFormikContext<FormValues>();
  const toast = useToast();
  const [updateFollowing, setUpdateFollowing] = useState<boolean>(false);
  const [isProcessingUpdate, setIsProcessingUpdate] = useState<boolean>(false);
  const { refetch: refetchAppointments } = useVisitorAppointmentsQuery({ skip: true });
  const { refetch: refetchAppointment } = useVisitorAppointmentQuery({ variables: { uuid } });
  const showError = useShowError();
  const isMobile = useIsMobileWidth();
  const router = useRouter();

  useEffect(() => {
    if (modal.activeModal === "AppointmentRecurringUpdate") {
      setShowModal(true);
    }
  }, [modal.activeModal, setShowModal]);

  const [updateVisitorAppointment] = useUpdateVisitorAppointmentMutation();

  const handleCancel = () => {
    setShowModal(false);
    modal.close();
  };

  const handleConfirm = async () => {
    let isSuccessful = false;
    try {
      const result = await updateVisitorAppointment({
        variables: {
          uuid,
          input: mapFormToDTO(values),
          updateFollowingRecurringAppointments: updateFollowing,
        },
      });

      isSuccessful = result.data?.updateVisitorAppointment != null && result.errors == null;
      if (isSuccessful) {
        if (!isReceptionView) {
          toast.neutral(t("visitors.appointmentForm.changesHaveBeenSaved"));
        }

        void refetchAppointments();
        void refetchAppointment();
      } else {
        throw new Error("Failed to update appointment.");
      }
    } catch (e: unknown) {
      showError(e);
    }

    setSubmitting(false);
    setShowModal(false);
    modal.close();
    if (isSuccessful) {
      if (isReceptionView) {
        void router.replace(`/visitor-management/building-receptions/pre_booked?date=${values.date}`).then(() => {
          resetForm({ values: initialValues });
          toast.positive(t("visitors.reception.appointmentUpdated"));
        });
      } else {
        modal.open("AppointmentUpdated");
      }
    }
  };

  return (
    <>
      <Modal.Dialog
        title=""
        show={showModal}
        hideOnEsc
        size="sm"
        supportsMobile
        mobileView
        hideOnClick={false}
        className="appointment-recurring-update-widget"
        focusTrapOptions={{ initialFocus: false }}
        centered
      >
        <Modal.Header
          intro={t("visitors.appointmentForm.appointmentRecurringEditRecurringAppointmentHeader")}
          closeButton={false}
          noBorder
        />
        {isMobile && <hr className="mt-6 mb-4 m-0" />}
        <Modal.Body>
          <div className="label-container pt-4">
            <div className="d-flex flex-column">
              <label className="pb-2 pt-2">
                <input
                  name="updateFollowingFalse"
                  className="radio-input"
                  checked={!updateFollowing}
                  onChange={(_) => setUpdateFollowing(false)}
                  type="radio"
                />
                <span className="custom-select-text mr-6">
                  {t("visitors.appointmentForm.appointmentRecurringDeleteThisOne")}
                </span>
              </label>
              <label className="pb-2 pt-2">
                <input
                  name="updateFollowingTrue"
                  className="radio-input"
                  checked={updateFollowing}
                  onChange={(_) => setUpdateFollowing(true)}
                  type="radio"
                />
                <span className="custom-select-text mr-6">
                  {t("visitors.appointmentForm.appointmentRecurringDeleteThisAndFollowing")}
                </span>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" size={isMobile ? "lg" : "md"} onClick={handleCancel}>
            {t("visitors.appointmentForm.cancelNo")}
          </Button>
          <Button
            variant="primary"
            size={isMobile ? "lg" : "md"}
            disabled={isProcessingUpdate}
            className="delete-button"
            onClick={() => {
              if (!isProcessingUpdate) {
                setIsProcessingUpdate(true);
                void handleConfirm();
              }
            }}
          >
            {t("common.save")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          .body p {
            font-weight: 400;
            font-size: 16px;
          }
          :global(.appointment-recurring-update-widget .footer) {
            display: flex;
            gap: 8px;
            padding: ${isMobile ? "1rem !important" : "0"};
          }
          :global(.appointment-recurring-update-widget .footer button) {
            width: ${isMobile ? "100%" : "auto"};
          }
        `}
      </style>
    </>
  );
};
