import type { TFunction } from "@equiem/localisation-eq1";
import { BookingDiscountStatus } from "../../../generated/gateway-client";

export const transformDiscountStatus = (status: BookingDiscountStatus, t: TFunction) => {
  switch (status) {
    case BookingDiscountStatus.Live:
      return t("bookings.discounts.discountStatus.active");
    case BookingDiscountStatus.Expired:
      return t("bookings.discounts.discountStatus.expired");
    case BookingDiscountStatus.Deleted:
      return t("bookings.discounts.discountStatus.deleted");

    default:
      return t("bookings.discounts.discountStatus.expired");
  }
};
