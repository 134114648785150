import type { ApolloError } from "@apollo/client";
import { dollarToCents, stringNotEmpty, useSiteContext, CurrentProfile } from "@equiem/lib";
import { useErrorTranslation } from "@equiem/localisation-eq1";
import { durationString, useToast } from "@equiem/react-admin-ui";
import type { FormikHelpers } from "formik";
import { useRouter } from "next/router";
import React, { useMemo, useContext } from "react";
import { ResourceCreateAndEditForm } from "./ResourceCreateAndEditForm";
import type { BookableResourceInput } from "../../../../generated/gateway-client";
import { BookableResourcesDocument, useCreateResourceMutation } from "../../../../generated/gateway-client";
import { addOnToInput } from "../fields/addon/addOnToInput";
import { availabilityToInput } from "../fields/availability/availabilityToInput";
import { toInputCancellationRates } from "../../../../lib/cancellationRate";
import { getVideoUrl } from "../../../../lib/getVideoUrl";
import { convertInputMoneyToNumber } from "../../../../lib/convertInputMoneyToNumber";
import { convertInputNumberToNumber } from "../../../../lib/convertNumberStringToNumber";
import { AvailabilityType, type FormValues } from "../../../../lib/formValidation";
import { useAuthorizedDestinationDetails } from "../../hooks/useAuthorizedDestinationDetails";

// eslint-disable-next-line complexity
const toResourceCreateInput = (values: FormValues): BookableResourceInput => {
  const convertedCapacity = values.displayCapacity != null ? Number(values.displayCapacity) : null;
  const displayCapacity = convertedCapacity === 0 ? null : convertedCapacity;

  const startValue = stringNotEmpty(values.start) ? values.start : "";
  const endValue = stringNotEmpty(values.end) ? values.end : "infinity";
  const availabilityDateRange =
    stringNotEmpty(values.start) || stringNotEmpty(values.end) ? `[${startValue}, ${endValue}]` : null;
  const { flexibleAvailability, slotsAvailability } = availabilityToInput(values);

  return {
    status: values.status,

    // Details
    name: values.title,
    typeV2: values.typeV2 ?? "",
    displayCapacity,
    site: values.site,
    buildingUuid: values.building,
    levelUuid: stringNotEmpty(values.level) ? values.level : undefined,
    description: values.description,

    // Photos & Videos
    images: values.images.flatMap((i) => (i.url != null ? [i.url] : [])),
    video: getVideoUrl(values.video),
    videoTitle: values.videoTitle,

    // Rates
    paymentRateHourly: dollarToCents(convertInputMoneyToNumber(values.paymentRateHourly, true)),
    paymentRateHalfDay: dollarToCents(convertInputMoneyToNumber(values.paymentRateHalfDay, true)),
    paymentRateFullDay: dollarToCents(convertInputMoneyToNumber(values.paymentRateFullDay, true)),
    paymentRateHourlyAfterHours: dollarToCents(convertInputMoneyToNumber(values.paymentRateHourlyAfterHours, true)),
    paymentRateHourlyWeekend: dollarToCents(convertInputMoneyToNumber(values.paymentRateHourlyWeekend, true)),
    businessHoursStart: values.businessHoursStart,
    businessHoursEnd: values.businessHoursEnd,
    businessHoursHalfDayDurationMinutes: durationString.toMinutes(values.businessHoursHalfDayDuration),
    paymentMethods: values.paymentMethods,
    taxExempt: values.taxExempt,

    // Configurations
    roomConfigurations: values.roomConfigurations,
    features: values.features,

    // Availability
    availabilityDateRange,
    prepTimeBeforeInMinutes: values.prepTimeBeforeInMinutes,
    prepTimeAfterInMinutes: values.prepTimeAfterInMinutes,
    bookingWindowMinInMinutes: values.bookingWindowMinInMinutes,
    bookingWindowMaxInMinutes: values.bookingWindowMaxInMinutes,
    slotsAvailability,
    flexibleAvailability,

    // Extras
    addOns: addOnToInput(values.addOns),

    // Permissions
    autoApproveBookings: values.autoApproveBookings ?? true,
    allowVisitorInvites: values.allowVisitorInvites ?? false,
    siteAudiences: values.siteAudiences.map((value) => ({
      site: value.site,
      segmentIds: value.segmentIds,
      segmentSummary: value.segmentSummary,
      paymentMethods: value.paymentMethods,
      paymentRateFullDay: dollarToCents(convertInputMoneyToNumber(value.paymentRateFullDay)),
      paymentRateHalfDay: dollarToCents(convertInputMoneyToNumber(value.paymentRateHalfDay)),
      paymentRateHourly: dollarToCents(convertInputMoneyToNumber(value.paymentRateHourly)),
      paymentRateHourlyAfterHours: dollarToCents(convertInputMoneyToNumber(value.paymentRateHourlyAfterHours)),
      paymentRateHourlyWeekend: dollarToCents(convertInputMoneyToNumber(value.paymentRateHourlyWeekend)),
    })),

    // Additional Settings
    ownerCompanyUuid: stringNotEmpty(values.ownerCompanyUuid) ? values.ownerCompanyUuid : null,
    children: values.children,
    externalSyncCalendarUrl: stringNotEmpty(values.externalSyncCalendarUrl) ? values.externalSyncCalendarUrl : null,
    externalSyncEmailAddress: stringNotEmpty(values.externalSyncEmailAddress) ? values.externalSyncEmailAddress : null,

    // Access Control
    barrierControlAccessUuid: stringNotEmpty(values.barrierControlAccessUuid) ? values.barrierControlAccessUuid : null,
    barrierControlVisitorAccessUuid:
      values.allowVisitorInvites === true && stringNotEmpty(values.barrierControlVisitorAccessUuid)
        ? values.barrierControlVisitorAccessUuid
        : null,

    // Terms and Conditions
    termsAndConditions: values.termsAndConditions ?? "",

    // Cancellation Permissions
    userCanEditBookings: values.userCanEditBookings ?? false,
    editBookingNoticePeriodInMinutes:
      values.userCanEditBookings === true ? convertInputNumberToNumber(values.editBookingNoticePeriodInMinutes) : null,
    editBookingTermsAndConditions:
      values.userCanEditBookings === true && stringNotEmpty(values.editBookingTermsAndConditions)
        ? values.editBookingTermsAndConditions
        : null,
    paymentRateCancellation: toInputCancellationRates(values.paymentRateCancellation),
  };
};

export const ResourceCreateForm: React.FC = () => {
  const { tError } = useErrorTranslation();
  const router = useRouter();
  const toast = useToast();
  const { uuid: siteUuid } = useSiteContext();
  const profile = useContext(CurrentProfile);
  const [createResourceMutation] = useCreateResourceMutation();
  const { buildings, loading } = useAuthorizedDestinationDetails(siteUuid);

  const defaultLocation = useMemo(() => {
    if (loading) {
      return {
        building: undefined,
        level: undefined,
      };
    }

    const building = buildings?.[0];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const level = building?.buildingLevels?.[0];

    return {
      building: building?.uuid,
      level: level?.uuid,
    };
  }, [buildings, loading]);

  const initialValues: FormValues = {
    addOns: [],
    autoApproveBookings: true,
    allowVisitorInvites: false,
    availability: [],
    availabilityType: AvailabilityType.slots,
    bookingWindowMinInMinutes: 0,
    bookingWindowMaxInMinutes: 20160, // 14 days (60 * 24 * 14)
    building: defaultLocation.building ?? "",
    businessHoursEnd: "17:00",
    businessHoursHalfDayDuration: "04:00",
    businessHoursStart: "09:00",
    children: [],
    description: "",
    displayCapacity: "",
    editBookingNoticePeriodInMinutes: 0,
    images: [],
    features: [],
    level: defaultLocation.level ?? "",
    parentNames: [],
    paymentRateCancellation: [],
    paymentRateFullDay: 0,
    paymentRateHalfDay: 0,
    paymentRateHourly: 0,
    paymentRateHourlyAfterHours: 0,
    paymentRateHourlyWeekend: 0,
    prepTimeAfterInMinutes: 0,
    prepTimeBeforeInMinutes: 0,
    roomConfigurations: [],
    site: siteUuid,
    siteAudiences: [],
    steps: 0,
    taxExempt: false,
    title: "",
    typeV2: "",
    // This will default to the user's own company on the server, but make sure
    // we show this fact in the setting to regional managers.
    ownerCompanyUuid: profile.profile?.companyV2?.uuid ?? "",
    ownerCompanyName: profile.profile?.companyV2?.name ?? "",
  };

  const submit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setSubmitting(true);

    try {
      await createResourceMutation({
        refetchQueries: [{ query: BookableResourcesDocument, variables: { destinationUuid: siteUuid } }],
        variables: { input: toResourceCreateInput(values) },
      });

      await router.push("/bookings/resources");
    } catch (e: unknown) {
      console.error(e);
      toast.negative(tError(e as ApolloError));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <ResourceCreateAndEditForm
      initialValues={initialValues}
      resourceOwnerCompany={profile.profile?.companyV2}
      submit={submit}
    />
  );
};
