import { stringNotEmpty, validEmail } from "@equiem/lib";
import type { TFunction } from "@equiem/localisation-eq1";
import type { FormikErrors } from "formik";
import { DateTime } from "luxon";
import type { BookableResourceAddOnFragmentFragment } from "../../../generated/gateway-client";
import {
  BookableResourceAddOnType,
  BookableResourcePaymentMethod,
  BookingInvoiceContactTypeInput,
} from "../../../generated/gateway-client";
import { convertInputNumberToNumber } from "../../../lib/convertNumberStringToNumber";
import type { AddOnCheckboxOrRadio, AddOnFreeText, BookingFormValue } from "../models/BookingFormValue";
import type { FormStep } from "../contexts/BookingFormProvider";
import type { Availability } from "./ResourceAvailability";
import { ResourceAvailability } from "./ResourceAvailability";

const validateTitle = (t: TFunction, title?: string | null) => {
  const bookingTitle = title ?? "";

  if (bookingTitle.length > 100) {
    return { title: t("bookings.operations.titleValidation", { value: 100 }) };
  }

  return {};
};

const validateTimeSelection = (
  t: TFunction,
  values: BookingFormValue,
  timezone: string,
  availabilities: Availability[],
) => {
  // Validate date.
  if (!stringNotEmpty(values.date)) {
    return { date: t("bookings.lib.selectDate") };
  }
  const date = DateTime.fromISO(values.date, { zone: timezone });
  if (!date.isValid) {
    return { date: t("bookings.lib.invalidDateFormat") };
  }
  const now = DateTime.local({ zone: timezone });
  if (date.startOf("day") < now.startOf("day")) {
    return { date: t("bookings.lib.dateInThePast") };
  }

  // Validate start and end time.
  if (!stringNotEmpty(values.start)) {
    return { start: t("bookings.lib.selectStartTime") };
  }
  const startCheck = DateTime.fromFormat(values.start, "HH:mm");
  if (!startCheck.isValid) {
    return { start: t("bookings.lib.invalidStartTime") };
  }
  if (!stringNotEmpty(values.end)) {
    return { end: t("bookings.lib.selectEndTime") };
  }
  const endCheck = DateTime.fromFormat(values.end, "HH:mm");
  if (!endCheck.isValid) {
    return { end: t("bookings.lib.invalidEndTime") };
  }
  if (endCheck <= startCheck) {
    return { start: t("bookings.lib.startTimeBeforeEnd") };
  }

  // Validate min and max.
  if (values.hasSuperPower !== true) {
    const start = date.set({ hour: startCheck.hour, minute: startCheck.minute });
    const end = date.set({ hour: endCheck.hour, minute: endCheck.minute });
    const availability = new ResourceAvailability(availabilities).findMatchingAvailability(start, end);
    if (availability?.__typename === "BookableResourceFlexibleAvailability") {
      const durationInMinutes = end.diff(start).as("minutes");
      if (availability.minTimeInMinutes != null && durationInMinutes < availability.minTimeInMinutes) {
        return { start: t("bookings.lib.minimumBookingIs", { time: availability.minTimeInMinutes }) };
      }
      if (availability.maxTimeInMinutes != null && durationInMinutes > availability.maxTimeInMinutes) {
        return { end: t("bookings.lib.maximumBookingIs", { time: availability.maxTimeInMinutes }) };
      }
    }
  }

  return {};
};

const validateAddOns = (
  t: TFunction,
  inputAddOns: BookingFormValue["addOns"] | null,
  resourceAddOns: BookableResourceAddOnFragmentFragment[] | null,
) => {
  if (resourceAddOns == null || resourceAddOns.length === 0) {
    return null;
  }

  const hasMandatory = resourceAddOns.some((addOn) => addOn.mandatory);
  if (inputAddOns == null || inputAddOns.length === 0) {
    return hasMandatory
      ? resourceAddOns.map((addOn) => {
          let result = "";

          if (addOn.mandatory) {
            result = stringNotEmpty(addOn.name) ? addOn.name : t("bookings.lib.thisField");
            result += ` ${t("bookings.lib.isRequired")}`;
          }

          return result;
        })
      : null;
  }

  // eslint-disable-next-line complexity
  return resourceAddOns.map((resourceAddOn, index) => {
    const name = stringNotEmpty(resourceAddOn.name) ? resourceAddOn.name : t("bookings.lib.thisField");

    if (inputAddOns[index] == null) {
      return resourceAddOn.mandatory ? t("bookings.lib.varIsRequired", { name }) : "";
    }

    switch (resourceAddOn.type) {
      case BookableResourceAddOnType.FreeText: {
        const target = inputAddOns[index] as AddOnFreeText;
        if (resourceAddOn.mandatory && !stringNotEmpty(target.value)) {
          return t("bookings.lib.varIsRequired", { name });
        }
        break;
      }

      case BookableResourceAddOnType.SingleChoice:
      case BookableResourceAddOnType.SingleOption: {
        const targetAddon = inputAddOns[index] as AddOnCheckboxOrRadio;
        const uuids = Object.keys(targetAddon);
        const fieldName = resourceAddOn.type === BookableResourceAddOnType.SingleChoice ? name : "This field";

        if (uuids.length === 0) {
          return resourceAddOn.mandatory ? t("bookings.lib.varIsRequired", { name: fieldName }) : "";
        }

        // Should not be the case.
        if (uuids.length > 1) {
          return t("bookings.lib.isSingleOption", { fieldName });
        }

        const selectedOptionUuid = uuids[0];
        const option = (resourceAddOn.options ?? []).find((o) => o.uuid === selectedOptionUuid);
        if (option == null) {
          return t("bookings.lib.noOptionSelected", { fieldName });
        }

        const selectedOption = targetAddon[selectedOptionUuid];
        if (selectedOption == null) {
          return resourceAddOn.mandatory ? t("bookings.lib.varIsRequired", { name: fieldName }) : "";
        }

        if (option.hasQuantity && convertInputNumberToNumber(selectedOption.quantity) == null) {
          return t("bookings.lib.quantityIsRequired", {
            fieldName: stringNotEmpty(option.name) ? option.name : fieldName,
          });
        }
        break;
      }

      case BookableResourceAddOnType.MultiOption: {
        const targetAddOn = inputAddOns[index] as AddOnCheckboxOrRadio;
        const uuids = Object.keys(targetAddOn);
        if (uuids.length === 0) {
          return resourceAddOn.mandatory ? t("bookings.lib.requiredPickOption", { name }) : "";
        }

        const userSelectAnyOptions = uuids.some((uuid) => targetAddOn[uuid] != null);
        if (resourceAddOn.mandatory && !userSelectAnyOptions) {
          return t("bookings.lib.requiredPickOption", { name });
        }

        const options = resourceAddOn.options ?? [];
        // Check all selected uuids.
        const hasUnknownKey = uuids.some((uuid) => options.find((o) => o.uuid === uuid) == null);
        if (hasUnknownKey) {
          return t("bookings.lib.noOptionSelected", { fieldName: name });
        }

        const hasEmptyQuantity = uuids.some((uuid) => {
          // No point checking when its not selected.
          if (targetAddOn[uuid] == null) {
            return false;
          }

          const option = options.find((o) => o.uuid === uuid);
          if (option == null) {
            return false;
          }

          return option.hasQuantity && convertInputNumberToNumber(targetAddOn[uuid]?.quantity) == null;
        });
        if (hasEmptyQuantity) {
          return t("bookings.lib.optionMustHaveQuantity");
        }
        break;
      }

      default:
        break;
    }

    // unfortunately it doesn't accept null for good input.
    return "";
  }, []);
};

const validatePaymentMethod = (t: TFunction, values: BookingFormValue, step: FormStep) => {
  if (step !== "payment") {
    return {};
  }

  if (values.paymentMethod == null && !(values.totalCharges == null || values.totalCharges === 0)) {
    return { paymentMethod: t("bookings.operations.pleaseSelectPaymentMethod") };
  }

  if (values.paymentMethod === BookableResourcePaymentMethod.CreditCard && values.creditcard == null) {
    return { paymentMethod: t("bookings.operations.pleaseProvideCreditCardDetails") };
  }

  if (values.paymentMethod === BookableResourcePaymentMethod.Credits) {
    if (values.creditAccount == null || !stringNotEmpty(values.creditAccount.uuid)) {
      const paymentMethod =
        values.bookingUuid != null
          ? t("bookings.operations.youCanNoLongerUseCredit")
          : t("bookings.operations.pleaseProvideCreditAccountDetails");

      return { paymentMethod };
    }
  }

  return {};
};

const validateContact = (t: TFunction, values: BookingFormValue, step: FormStep) => {
  if (step !== "payment") {
    return {};
  }

  if (values.paymentMethod !== BookableResourcePaymentMethod.Invoice) {
    return {};
  }

  if (values.totalCharges == null || values.totalCharges === 0) {
    return {};
  }

  const contact = values.contact;
  if (contact == null) {
    return { contact: t("bookings.operations.pleaseSelectOneOfContact") };
  }

  if (contact.type === BookingInvoiceContactTypeInput.NewContact) {
    if (!stringNotEmpty(contact.fullName) || !stringNotEmpty(contact.email)) {
      return { contact: t("bookings.operations.pleaseEnterContactFullnameEmail") };
    }
    if (!validEmail(contact.email)) {
      return { contact: t("bookings.operations.pleaseEnterValidEmail") };
    }
  }

  if (contact.type === BookingInvoiceContactTypeInput.BillingCustomer) {
    if (!stringNotEmpty(contact.billingCustomerId)) {
      return { contact: t("bookings.operations.pleaseSelectBillingCustomer") };
    }
  }

  return {};
};

const validateTerms = (t: TFunction, { values, editBookingTermsAndConditions, termsAndConditions, step }: Props) => {
  const termsAccepted = values.termsAccepted === true;

  if (!termsAccepted) {
    const wantedStep =
      // Free booking inital step validation.
      (step === "initial" && values.freeBookingMode) ||
      // Paid booking when in payment page.
      step === "payment";

    // For booking creation.
    if (values.bookingUuid == null && stringNotEmpty(termsAndConditions) && wantedStep) {
      return { termsAccepted: t("common.invalidTerms") };
    }
    // For edit booking.
    if (values.bookingUuid != null && stringNotEmpty(editBookingTermsAndConditions) && wantedStep) {
      return { termsAccepted: t("common.invalidTerms") };
    }
  }

  return {};
};

const validateHost = (t: TFunction, values: BookingFormValue) =>
  values.host == null ? { host: t("bookings.operations.pleaseSelectHost") } : {};

interface Props {
  timezone: string;
  values: BookingFormValue;
  addOns: BookableResourceAddOnFragmentFragment[];
  availabilities?: Availability[];
  editBookingTermsAndConditions?: string | null;
  termsAndConditions?: string | null;
  step: FormStep;
}
export const bookingFormValidation = (props: Props, t: TFunction) => {
  const { timezone, values, step } = props;
  const errors: FormikErrors<BookingFormValue> = {
    ...validateTitle(t, values.title),
    ...validateTimeSelection(t, values, timezone, props.availabilities ?? []),
    ...validateHost(t, values),
    ...validateTerms(t, props),
    ...validatePaymentMethod(t, values, step),
    ...validateContact(t, values, step),
  };

  const addOnErrors = validateAddOns(t, values.addOns, props.addOns);
  const hasError = addOnErrors?.some((addOnError) => stringNotEmpty(addOnError)) ?? false;
  if (addOnErrors != null && hasError) {
    errors.addOns = addOnErrors;
  }

  return errors;
};
