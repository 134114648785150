import { createContext, useContext } from "react";

import type {
  AccessControlSettings,
  DestinationFragmentFragment,
  DestinationTierLevel,
  DestinationType,
  FeatureModuleType,
} from "../generated/gateway-client";
import type { Runtype } from "runtypes";
import { Array as RArray, Boolean, Literal, Null, Number, Record, String, Union, Optional } from "runtypes";
import { stringNotEmpty } from "../stringNotEmpty";

export type SiteContextInput = DestinationFragmentFragment;

interface FeatureModuleFeatureType {
  enabled: boolean;
  url?: string;
  label?: string;
  enableMenu?: boolean;
}

export interface SiteContextType {
  uuid: string;
  name: string;
  address: string;
  email: string | null;
  androidAppId: string | null;
  iosAppId: string | null;
  type: DestinationType;
  tierLevel: DestinationTierLevel | null;
  timezone: string;
  webAppUrl: string;
  equiemOneUrl: string | null;
  accountAppUrl: string;
  domainAliases: string[];
  primaryColour: string;
  primaryColourIsDark: boolean;
  primaryContrastColour: string;
  whiteContrastColour: string;
  headerImage: string | null;
  showLogoOnWhiteBackground: boolean;
  logo: string | null;
  logoSize: string | null;
  gatewayEndpoint: string;
  regionName: string;
  termsAndConditions: string | null;
  privacyPolicy: string | null;
  buildingInfo: {
    title: string;
    showSiteName: boolean;
    heroImage?: string | null;
    categories: Array<{
      uuid: string;
      name: string;
      slug: string;
    }>;
  };
  memberSites: Array<{ uuid: string; name: string }>;
  setMemberSites: (memberSites: Array<{ uuid: string; name: string }>) => void;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  loggedOutHome: {
    heading: string | null;
    subHeading: string | null;
    heroImage: string | null;
  };
  store: {
    enabled: boolean;
    navEnabled: boolean;
    title: string;
  };
  socialLinks: Array<{
    value: string;
    type: "FACEBOOK" | "INSTAGRAM" | "TWITTER" | "LINKEDIN" | "EMAIL" | "PHONE" | "OTHER";
  }>;
  featureModules: {
    bookings: FeatureModuleFeatureType;
    requests: FeatureModuleFeatureType;
    visitors: FeatureModuleFeatureType;
  };
  featureMenus: {
    bookingsEnabled: boolean;
    requestsEnabled: boolean;
    visitorsEnabled: boolean;
  };
  footerLogo?: string | null;
  siteLoaderMode: {
    appLoadedByDefaultUrl: boolean;
    appLoadedByLegacyUrl: boolean;
    hostUrl?: string;
    inSiteMode: boolean;
  };
  accessControl: AccessControlSettings;
  siteSwitchingInProgress: boolean;
  setSiteSwitchingInProgress: (status: boolean) => void;
  locale: string;
  machineName: string;
  favicon?: string | null;
}

export const defaultSite: SiteContextType = {
  uuid: "",
  name: "Site Not Found",
  type: "PORTAL",
  email: null,
  androidAppId: null,
  iosAppId: null,
  address: "",
  tierLevel: null,
  timezone: "Etc/UTC",
  accountAppUrl: "",
  webAppUrl: "",
  equiemOneUrl: null,
  domainAliases: [],
  showLogoOnWhiteBackground: true,
  primaryColour: "#000",
  primaryContrastColour: "#FFF",
  whiteContrastColour: "#000",
  primaryColourIsDark: true,
  headerImage: null,
  siteSwitchingInProgress: false,
  setSiteSwitchingInProgress: () => undefined,
  logo: null,
  logoSize: null,
  termsAndConditions: null,
  privacyPolicy: null,
  gatewayEndpoint: "",
  memberSites: [],
  setMemberSites: () => undefined,
  coordinates: {
    latitude: 0,
    longitude: 0,
  },
  buildingInfo: {
    title: "",
    showSiteName: true,
    categories: [],
  },
  loggedOutHome: {
    heading: null,
    subHeading: null,
    heroImage: null,
  },
  regionName: "",
  store: {
    enabled: false,
    navEnabled: false,
    title: "Store",
  },
  socialLinks: [],
  featureModules: {
    bookings: { enabled: false },
    requests: { enabled: false },
    visitors: { enabled: false },
  },
  featureMenus: {
    bookingsEnabled: false,
    requestsEnabled: false,
    visitorsEnabled: false,
  },
  siteLoaderMode: {
    appLoadedByDefaultUrl: true,
    appLoadedByLegacyUrl: true,
    inSiteMode: false,
  },
  locale: "en",
  machineName: "local.one",
  accessControl: { solution: "VICINITEE" },
};

export const Site = createContext<SiteContextType>(defaultSite);

export function useSiteContext() {
  return useContext(Site);
}

export type TransformedSite = ReturnType<typeof transformSite>;

export const TransformedSite: Runtype<TransformedSite> = Record({
  uuid: String,
  name: String,
  type: Union(
    Literal("AUTH_API"),
    Literal("EQUIEM_ONE"),
    Literal("IRIS"),
    Literal("PORTAL"),
    Literal("UNKNOWN"),
    Literal("VMS"),
  ),
  email: Union(String, Null),
  androidAppId: Union(String, Null),
  iosAppId: Union(String, Null),
  tierLevel: Union(Literal("Essentials"), Literal("Unlimited"), Null),
  address: String,
  timezone: String,
  webAppUrl: String,
  equiemOneUrl: Union(String, Null),
  accountAppUrl: String,
  domainAliases: RArray(String),
  logo: Union(String, Null),
  logoSize: Union(String, Null),
  coordinates: Record({
    latitude: Number,
    longitude: Number,
  }),
  buildingInfo: Record({
    title: String,
    showSiteName: Boolean,
    categories: RArray(
      Record({
        uuid: String,
        name: String,
        slug: String,
      }),
    ),
  }),
  showLogoOnWhiteBackground: Boolean,
  primaryColour: String,
  primaryContrastColour: String,
  whiteContrastColour: String,
  primaryColourIsDark: Boolean,
  headerImage: Union(String, Null),
  regionName: String,
  store: Record({
    enabled: Boolean,
    navEnabled: Boolean,
    title: String,
  }),
  socialLinks: RArray(
    Record({
      value: String,
      type: Union(
        Literal("FACEBOOK"),
        Literal("INSTAGRAM"),
        Literal("TWITTER"),
        Literal("LINKEDIN"),
        Literal("EMAIL"),
        Literal("PHONE"),
        Literal("OTHER"),
      ),
    }),
  ),
  featureModules: Record({
    bookings: Record({ enabled: Boolean }),
    requests: Record({ enabled: Boolean }),
    visitors: Record({ enabled: Boolean }),
  }),
  termsAndConditions: Union(String, Null),
  privacyPolicy: Union(String, Null),
  loggedOutHome: Record({
    heading: Union(String, Null),
    subHeading: Union(String, Null),
    heroImage: Union(String, Null),
  }),
  gatewayEndpoint: String,
  featureMenus: Record({
    bookingsEnabled: Boolean,
    requestsEnabled: Boolean,
    visitorsEnabled: Boolean,
  }),
  locale: String,
  machineName: String,
  footerLogo: Optional(Union(String, Null)),
  favicon: Optional(Union(String, Null)),
  accessControl: Record({ solution: Union(Literal("ACCESS_ONE"), Literal("VICINITEE")) }),
});

// eslint-disable-next-line complexity
export const transformSite = (
  site: SiteContextInput,
): Omit<
  SiteContextType,
  "siteLoaderMode" | "siteSwitchingInProgress" | "setSiteSwitchingInProgress" | "memberSites" | "setMemberSites"
> => {
  const findFeature = (type: FeatureModuleType) => site.featureModules.find((fm) => fm.type === type);

  const getFeature = (type: FeatureModuleType) => {
    const feature = site.featureModules.find((fm) => fm.type === type);

    return {
      enabled: feature != null,
      url: feature?.url ?? undefined,
      label: feature?.label,
      enableMenu: feature?.enableMenu ?? undefined,
    };
  };

  return {
    uuid: site.uuid,
    name: site.name,
    email: site.email ?? null,
    androidAppId: site.androidAppId,
    iosAppId: site.iosAppId,
    type: site.type,
    tierLevel: site.tierLevel ?? null,
    address: site.address,
    timezone: site.timezone,
    webAppUrl: site.infrastructure?.primaryDomain.hostname ?? "",
    equiemOneUrl: site.infrastructure?.equiemOneDomain?.hostname ?? null,
    accountAppUrl: site.accountAppUrl ?? "",
    machineName: site.machineName,
    domainAliases: [
      site.infrastructure?.primaryDomain.hostname ?? "",
      ...(site.infrastructure?.webDomains.map(({ domain }) => domain.hostname) ?? []),
      site.infrastructure?.referenceDomain.hostname ?? "",
    ],
    logo: site.settings.branding.web.siteLogoImage ?? null,
    logoSize: site.settings.branding.web.siteLogoImageSize ?? null,
    coordinates: {
      latitude: site.latitude,
      longitude: site.longitude,
    },
    showLogoOnWhiteBackground: site.settings.branding.web.showLogoOnWhiteBackground,
    primaryColour: site.settings.branding.primaryColour,
    primaryColourIsDark: site.settings.branding.primaryColourIsDark,
    primaryContrastColour: site.settings.branding.primaryContrastColour,
    whiteContrastColour: site.settings.branding.primaryWhiteContrastColour,
    headerImage: site.settings.branding.web.homePageHeaderImage ?? null,
    regionName: site.regionName,
    termsAndConditions: site.termsAndConditions ?? null,
    privacyPolicy: site.privacyPolicy ?? null,
    buildingInfo: {
      title: stringNotEmpty(site.settings.buildingInfo.title) ? site.settings.buildingInfo.title : "My Building",
      categories: site.settings.buildingInfo.categories,
      heroImage: site.settings.branding.web.buildingPageHeaderImage,
      showSiteName: site.settings.branding.web.textOverBuildingPageImage,
    },
    loggedOutHome: {
      heading: site.settings.branding.web.welcomePageHeading ?? null,
      subHeading: site.settings.branding.web.welcomePageSubHeading ?? null,
      heroImage: site.settings.branding.web.welcomePageImage ?? null,
    },
    store: {
      enabled: site.settings.store.enabled,
      navEnabled: site.settings.store.enabled && site.settings.store.webEnabled,
      title: site.settings.store.title ?? "Store",
    },
    socialLinks: site.socialLinks.filter((x) => stringNotEmpty(x.value)),
    // All portal type destinations have a gateway.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gatewayEndpoint: site.gatewayEndpoint!,
    footerLogo: site.settings.branding.web.footerLogoImage,
    featureModules: {
      bookings: getFeature("BOOKINGS"),
      requests: getFeature("REQUESTS"),
      visitors: getFeature("VISITORS"),
    },
    featureMenus: {
      bookingsEnabled: findFeature("BOOKINGS")?.enableMenu ?? false,
      requestsEnabled: findFeature("REQUESTS")?.enableMenu ?? false,
      visitorsEnabled: findFeature("VISITORS")?.enableMenu ?? false,
    },
    accessControl: site.settings.accessControl ?? { solution: "VICINITEE" },
    locale: site.locale,
    favicon: site.settings.branding.web.browserIconImage,
  };
};
