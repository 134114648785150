import React, { useCallback, useMemo, useState } from "react";
import { useSiteContext } from "@equiem/lib";
import { Button, EmptyState, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

import type { BookableResourcesListQuery } from "../../generated/gateway-client";
import { BookableResourceStatus, useBookableResourcesListQuery } from "../../generated/gateway-client";
import { matchesSearchText } from "../../lib/matchesSearch";
import { resourceTypeToString } from "../../lib/resourceTypeToString";
import { BookingsTab } from "../../components/BookingsTab";
import { ResourceCard, ResourceCardLoading } from "./components/ResourceCard";
import { resourceStatusText } from "./components/ResourceStatus";
import { useRouter } from "next/router";
import { useTranslation } from "@equiem/localisation-eq1";
import { defaultTabFilter, ResourcesListStatusTabs } from "./ResourcesListStatusTabs";

type Resource = BookableResourcesListQuery["bookableResources"][number];

export const ResourcesList = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { uuid: destinationUuid, timezone } = useSiteContext();
  const { breakpoints, spacers } = useTheme(true);
  const [searchText, setSearchText] = useState("");
  const [tabFilter, setTabFilter] = useState(defaultTabFilter);

  const { data, loading, error, refetch } = useBookableResourcesListQuery({
    fetchPolicy: "cache-and-network",
    variables: { destinationUuid },
  });

  const totalCount = data?.bookableResources.length;
  const publishedCount = data?.bookableResources.filter((r) => r.status === BookableResourceStatus.Published).length;
  const draftCount = data?.bookableResources.filter((r) => r.status === BookableResourceStatus.Draft).length;

  const getSearchTokens = useCallback(
    (r: Resource) => [
      r.name,
      resourceStatusText({
        status: r.status,
        deletedAfter: r.deletedAfter,
        timezone: r.building?.timezone ?? timezone,
        language: i18n.language,
        t,
      }),
      resourceTypeToString(r.typeInfo.name, t),
      r.displayCapacity != null ? t("bookings.resources.countPersonCapacity", { count: r.displayCapacity }) : "",
    ],
    [timezone, i18n.language, t],
  );

  const bookableResources = useMemo(
    () =>
      (data?.bookableResources ?? []).filter(
        (resource) =>
          (tabFilter == null || resource.status === tabFilter) &&
          matchesSearchText(searchText, getSearchTokens(resource)),
      ),
    [data, searchText, tabFilter, getSearchTokens],
  );

  const handleTabFilterChange = (newTabFilter: BookableResourceStatus | null) => {
    setSearchText("");
    setTabFilter(newTabFilter);
  };

  return (
    <>
      <BookingsTab
        title={
          <ResourcesListStatusTabs
            setTabFilter={handleTabFilterChange}
            countLoading={loading}
            totalCount={totalCount}
            publishedCount={publishedCount}
            draftCount={draftCount}
          />
        }
        search={{ searchText, setSearchText }}
        button={
          <Button
            className="main-button"
            variant="primary"
            size="md"
            onClick={() => {
              router.push("/bookings/resources/new-resource").catch(console.error);
            }}
          >
            <RiAddLine size={16} />
            {t("bookings.resources.newResource")}
          </Button>
        }
      >
        {loading || error != null || bookableResources.length > 0 ? (
          <div className="listing">
            {error != null && <div>{error.message}</div>}
            {bookableResources.map((resource) => (
              <ResourceCard key={resource.uuid} {...resource} triggerRefresh={refetch} />
            ))}
            {loading && Array.from({ length: 6 }).map((_, index) => <ResourceCardLoading key={index} />)}
          </div>
        ) : (
          <div className="empty-listing">
            <EmptyState />
          </div>
        )}
      </BookingsTab>
      <style jsx>{`
        .listing {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
          justify-items: center;
          grid-gap: ${spacers.s7} ${spacers.s5};
          padding: ${spacers.s4} ${spacers.s7} ${spacers.s7};
        }
        .empty-listing {
          padding: ${spacers.s7};
        }
        .main-button {
          text-transform: uppercase;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .listing {
            grid-template-columns: 1fr;
          }
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .listing {
            padding: 16px;
          }
        }
      `}</style>
    </>
  );
};
