export interface DurationDate {
  label: string;
  value: string;
}

export interface FormVisitor {
  firstName: string;
  lastName: string;
  companyName?: string;
  email?: string;
  uuid?: string;
}

export interface FormHost {
  firstName: string;
  lastName: string;
  company: {
    uuid: string;
    name: string;
  };
  email: string;
}

export interface AppointmentHost {
  email?: string;
  firstName: string;
  lastName: string;
  company?: {
    uuid: string;
    name: string;
  };
  userUuid?: string;
  avatar?: string;
}

interface AdditionalDate {
  date: string;
  startTime: number;
  duration: string;
}

export interface AppointmentRecurringSettings {
  recurringType: AppointmentRecurringType;
  repeatEvery?: number;
  startDate: number;
  endDate: number;
  repeatOn?: number[];
  repeatTimes?: number;
  repeatUntil?: number;
  sameWeekDayEachMonth?: boolean;
  lastWeekDayEachMonth?: boolean;
  recurringDates: number[];
}

export enum AppointmentRecurringType {
  Daily,
  Weekly,
  Monthly,
}

export enum AppointmentWeekday {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export interface FormValues {
  isWalkIn: boolean;
  title: string;
  description: string;
  date: string;
  startTime: number;
  duration: string;
  holdInLobby: boolean;
  buildingReceptionNotes: string;
  companyReceptionNotes: string;
  visitorNotes: string;
  visitors: FormVisitor[];
  organizer: AppointmentHost;
  host: AppointmentHost;
  notifyHost: boolean;
  notifyOrganizer: boolean;
  additionalDates: AdditionalDate[];
  location?: string;
  recurringSettings?: AppointmentRecurringSettings;
}
