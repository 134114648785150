import React, { createContext, useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { type BookingChargesQuery } from "../../../generated/gateway-client";
import { useBookingCharges } from "../hooks/useBookResource";
import type { BookingFormValue } from "../models/BookingFormValue";
import { BookingModalInfo } from "./BookingModalInfoProvider";
import { stringIsEmpty } from "@equiem/lib";

interface BookingChargesContext {
  charges?: BookingChargesQuery["bookingCharges"];
  loading: boolean;
}
export const BookingChargesContext = createContext<BookingChargesContext>({
  charges: undefined,
  loading: false,
});

interface P {
  children?: React.ReactNode;
}
export const BookingChargesProvider: React.FC<P> = ({ children }) => {
  const fm = useFormikContext<BookingFormValue>();
  const { resource, timezone } = useContext(BookingModalInfo);

  const { data, loading } = useBookingCharges({
    resource,
    timezone,
    values: fm.values,
    skip: stringIsEmpty(fm.values.date) || stringIsEmpty(fm.values.start) || stringIsEmpty(fm.values.end),
  });

  useEffect(() => {
    void fm.setFieldValue("totalCharges", data?.bookingCharges.total ?? undefined);
    // We don't want [fm] to be inside deps to avoid unecessary update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.bookingCharges.total]);

  return (
    <BookingChargesContext.Provider value={{ charges: data?.bookingCharges, loading }}>
      {children}
    </BookingChargesContext.Provider>
  );
};
