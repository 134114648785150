import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { AppointmentsMenuContext, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, useIsMobileWidth, useTheme, useToast } from "@equiem/react-admin-ui";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useDeleteVisitorAppointmentMutation } from "../../../generated/visitors-client";

export const AppointmentDeleteWidget: React.FC<{ showRecurringOptions: boolean }> = ({
  showRecurringOptions: isRecurring,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const modal = useContext(ModalContext);
  const toast = useToast();
  const showError = useShowError();
  const [deleteVisitorAppointment] = useDeleteVisitorAppointmentMutation();
  const { clearState } = useContext(AppointmentsMenuContext);
  const isMobile = useIsMobileWidth();
  const { colors } = useTheme();
  const [deleteFollowing, setDeleteFollowing] = useState<boolean>(false);
  useEffect(() => {
    if (modal.activeModal === "DeleteAppointment" && modal.id != null) {
      setShowModal(true);
    }
  }, [modal.activeModal, modal.id, setShowModal]);

  const handleDelete = async () => {
    if (modal.id != null) {
      try {
        const result = await deleteVisitorAppointment({
          variables: {
            uuid: modal.id,
            deleteFollowingRecurringAppointments: isRecurring && deleteFollowing ? true : undefined,
          },
        });

        if (result.data?.deleteVisitorAppointment.success === true && result.errors == null) {
          toast.negative(t("visitors.appointments.appointmentDeleted"));
          void router.push("/visitor-management");
          clearState();
        } else {
          throw new Error("Failed to delete appointment.");
        }
      } catch (e: unknown) {
        showError(e);
      }
    }

    setShowModal(false);
    modal.close();
  };

  const handleCancel = () => {
    setShowModal(false);
    modal.close();
  };

  return (
    <>
      <Modal.Dialog
        data-escape-focus-trap
        title={isRecurring ? "" : t("visitors.appointments.appointmentDelete")}
        show={showModal}
        hideOnEsc
        supportsMobile
        className="appointment-delete-widget"
        mobileView
        hideOnClick={false}
        focusTrapOptions={{ initialFocus: false }}
        size="sm"
        centered
      >
        <Modal.Header
          closeButton={false}
          intro={
            isRecurring ? t("visitors.appointmentForm.appointmentRecurringDeleteRecurringAppointmentHeader") : null
          }
          noBorder
        >
          {isRecurring ? null : <h3 className="title m-0">{t("visitors.appointments.appointmentDeleteConfirm")}</h3>}
        </Modal.Header>

        {isMobile && <hr className="mt-6 mb-4 m-0" />}
        {isRecurring ? (
          <Modal.Body>
            <div className="label-container pt-4">
              <div className="d-flex flex-column">
                <label className="pb-2 pt-2">
                  <input
                    name="monthlyLastOccurance"
                    className="radio-input"
                    checked={!deleteFollowing}
                    onChange={(_) => setDeleteFollowing(false)}
                    type="radio"
                  />
                  <span className="custom-select-text mr-6">
                    {t("visitors.appointmentForm.appointmentRecurringDeleteThisOne")}
                  </span>
                </label>
                <label className="pb-2 pt-2">
                  <input
                    name="monthlyLastOccurance"
                    className="radio-input"
                    checked={deleteFollowing}
                    onChange={(_) => setDeleteFollowing(true)}
                    type="radio"
                  />
                  <span className="custom-select-text mr-6">
                    {t("visitors.appointmentForm.appointmentRecurringDeleteThisAndFollowing")}
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="ghost" size={isMobile ? "lg" : "md"} onClick={handleCancel}>
            {t("visitors.appointmentForm.cancelNo")}
          </Button>
          <Button
            variant="danger"
            size={isMobile ? "lg" : "md"}
            disabled={isProcessingDelete}
            className="delete-button"
            onClick={() => {
              if (!isProcessingDelete) {
                setIsProcessingDelete(true);
                void handleDelete();
              }
            }}
          >
            {t("visitors.appointmentForm.cancelYes")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>
        {`
          hr {
            border: 0;
            border-top: 1px solid ${colors.border};
          }

          :global(.appointment-delete-widget .footer) {
            display: flex;
            gap: 8px;
            padding: ${isMobile ? "1rem !important" : "0"};
          }
          :global(.appointment-delete-widget .footer button) {
            width: ${isMobile ? "100%" : "auto"};
          }
          .title {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
          }
          :global(.delete-button) {
            margin-right: ${isMobile ? "0" : "0.5rem"};
          }
        `}
      </style>
    </>
  );
};
