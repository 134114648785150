import React, { useContext, useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { AppointmentsMenuContext, SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Button, ButtonLink, Dropdown, Tabs, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseLine,
  RiDeleteBin6Line,
  RiEdit2Line,
  RiMoreFill,
} from "@equiem/react-admin-ui/icons";

import { Modal } from "../contexts/ModalContext";
import { withContexts } from "../contexts/withContexts";
import { EditAppointmentContext } from "../pages/appointment/context/EditAppointmentContext";
import { useIsExpiredAppointmentByDateAndTime } from "../pages/appointment/hooks/useIsExpiredAppointmentByDateAndTime";
import { CancelFormWidget } from "../pages/appointment/widgets/CancelFormWidget";
import { ReceptionsListWidget } from "../pages/reception/widgets/ReceptionsListWidget";

export const EditAppointmentsMenuBase: React.FC = () => {
  const router = useRouter();
  const modal = useContext(Modal);
  const isMobile = useIsMobileWidth();
  const { colors } = useTheme(true);
  const { t } = useTranslation();
  const {
    cachedAppointmentForm,
    isEndUserHasEditableAccess,
    isEditAppointment,
    setIsPreview,
    hasEditableAccess,
    selectedAppointmentUuid,
    isNewAppointment,
    isPreview,
    clearState,
    navigatePreviousPage,
  } = useContext(AppointmentsMenuContext);
  const { onDeleteAppointment } = useContext(EditAppointmentContext);
  const isAppointmentExpired = useIsExpiredAppointmentByDateAndTime(
    cachedAppointmentForm?.startTime,
    cachedAppointmentForm?.date,
  );
  const isEligibleCreateNewAppointment = isEndUserHasEditableAccess || hasEditableAccess;
  const tabs = useMemo<TabItem[]>(() => {
    const _tabs = [
      {
        title: t("visitors.appointments.appointmentsTab"),
        key: "/visitor-management",
      },
    ];

    if (isNewAppointment) {
      const closeableTab: TabItem = {
        title: t("visitors.appointments.newAppointment"),
        key: "/visitor-management/new-appointment",
        onClose: () => modal.open("CancelForm"),
      };

      return [..._tabs, closeableTab];
    }

    if (isEditAppointment) {
      const closeableTab: TabItem = {
        title: isPreview
          ? (cachedAppointmentForm?.title as string)
          : t("visitors.appointments.editAppointmentWithName", { name: cachedAppointmentForm?.title ?? "" }),
        key: `/visitor-management/edit-appointment/${selectedAppointmentUuid}`,
        onClose: () => {
          if (!isPreview) {
            modal.open("CancelForm");
          } else {
            navigatePreviousPage();
            clearState();
          }
        },
      };

      return [..._tabs, closeableTab];
    }

    return _tabs;
  }, [isEditAppointment, isPreview, cachedAppointmentForm, isNewAppointment, clearState, navigatePreviousPage, t]);

  const handleTabSelect = (key: string) => {
    void router.replace({
      pathname: key,
      query: router.query,
    });
  };

  const title = useMemo(() => {
    if (isMobile && isEditAppointment) {
      return isPreview
        ? t("visitors.appointmentForm.appointmentDetails")
        : t("visitors.appointments.editAppointmentWithName", { name: cachedAppointmentForm?.title ?? "" });
    }

    if (isMobile && isNewAppointment) {
      return t("visitors.appointments.newAppointment");
    }

    return t("visitors.appointments.visitorAppointments");
  }, [t, isMobile, isEditAppointment, isNewAppointment, cachedAppointmentForm?.title, isPreview]);

  const isEditAllowed = hasEditableAccess || isEndUserHasEditableAccess;
  const isControlsVisible = isMobile && isEditAppointment && !isAppointmentExpired && isEditAllowed;

  return (
    <div style={{ background: colors.white }} className="menu">
      <SubTopMenu className="title-container mb-0">
        <div className="d-flex align-items-center w-100 " style={{ gap: "8px", overflow: "hidden" }}>
          {isMobile && isPreview && (
            <Button
              variant="ghost"
              round
              onClick={() => {
                void router.push("/visitor-management");
              }}
            >
              <RiArrowLeftLine size={24} />
            </Button>
          )}
          {isMobile && !isPreview && (
            <Button
              variant="ghost"
              round
              onClick={() => {
                modal.open("CancelForm");
              }}
            >
              <RiCloseLine size={24} />
            </Button>
          )}
          <h1 className="title" style={{ fontWeight: "700" }}>
            {title}
          </h1>
        </div>
        {isControlsVisible && (
          <div className="actions d-flex">
            {isPreview && (
              <Button variant="secondary" onClick={() => setIsPreview(!isPreview)} round size="md">
                <RiEdit2Line size={16} />
              </Button>
            )}
            <Dropdown.Container
              sameWidth={false}
              placement="bottom-end"
              trigger={
                <Button variant="outline" round size="md">
                  <RiMoreFill size={16} />
                </Button>
              }
            >
              <Button
                variant="ghost"
                size="sm"
                disabled={isAppointmentExpired}
                onClick={() => onDeleteAppointment(selectedAppointmentUuid as string)}
              >
                <RiDeleteBin6Line size={16} color={colors.danger} />
                <span className="cancel-text">{t("common.cancel")}</span>
              </Button>
            </Dropdown.Container>
          </div>
        )}
      </SubTopMenu>
      {!isMobile && (
        <SubTopMenu className="menu-container py-0" sticky>
          <div className=" d-flex align-items-center">
            <div className="d-flex menu">
              <Tabs
                items={tabs}
                selected={router.asPath}
                tabLinks
                onSelect={handleTabSelect}
                actionComponent={
                  <>
                    {isEligibleCreateNewAppointment && (
                      <Link href="/visitor-management/new-appointment" passHref legacyBehavior>
                        <ButtonLink
                          size="md"
                          variant="secondary"
                          className="create-button"
                          disabled={isNewAppointment || isEditAppointment}
                        >
                          <RiAddLine size={16} />
                          {t("visitors.appointments.newAppointment")}
                        </ButtonLink>
                      </Link>
                    )}
                  </>
                }
              />
            </div>
          </div>
        </SubTopMenu>
      )}
      <CancelFormWidget />
      <ReceptionsListWidget />
      <style jsx>
        {`
          .title {
            font-weight: 700;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: horizontal;
            max-width: 90%;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          :global(.menu-container) {
            height: 48px;
            min-height: unset !important;
          }
          .menu {
            position: sticky;
            top: 0;
            z-index: 2;
          }
          .cancel-text {
            text-transform: capitalize;
          }
          .actions {
            gap: 10px;
          }
        `}
      </style>
    </div>
  );
};

export const EditAppointmentsMenu = withContexts(EditAppointmentsMenuBase);
