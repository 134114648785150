import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { useIsMobileWidth } from "@equiem/react-admin-ui";

export const AppointmentFormTitle = ({
  isEditView,
  title,
  type,
  isReceptionView,
}: {
  type: "create" | "update";
  isEditView: boolean;
  title: string;
  isReceptionView: boolean;
}) => {
  const isMobile = useIsMobileWidth();
  const { t } = useTranslation();
  const isTitleVisible = !isReceptionView && isMobile && !isEditView;

  return (
    <>
      {isReceptionView && (
        <h1 style={{ fontWeight: "700" }} className="mb-4">
          {type === "create" ? t("visitors.appointments.newAppointment") : t("visitors.appointments.editAppointment")}
        </h1>
      )}

      {isTitleVisible && (
        <h1 style={{ fontWeight: "700" }} className="mb-2">
          {title}
        </h1>
      )}

      {!isMobile && !isReceptionView && type === "update" && !isEditView && (
        <h1 style={{ fontWeight: "700" }}>{title}</h1>
      )}
    </>
  );
};
