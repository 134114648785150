import { useContext, useMemo } from "react";
import type { ApolloError } from "@apollo/client";

import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";

import {
  useAddBuildingLevelMutation,
  useAddSpaceMutation,
  useCreateOrUpdateBuildingMutation,
  useDeleteBuildingLevelMutation,
  useDeleteBuildingMutation,
  useDeleteSpaceMutation,
  useDestinationBuildingsQuery,
  useDuplicateBuildingMutation,
  useDuplicateLevelMutation,
  useDuplicateSpaceMutation,
  useUpdateBuildingLevelMutation,
  useUpdateBuildingOrderMutation,
  useUpdateLevelOrderMutation,
  useUpdateSpaceMutation,
  useUpdateSpaceOrderMutation,
} from "../../../generated/settings-client";
import { BuildingDataContext } from "../contexts/BuildingDataContext";
import type { BuildingFormValues } from "../types";

export function useBuildingData() {
  const NEW_ID = "new";
  const { t } = useTranslation();
  const site = useSiteContext();
  const toast = useToast();
  const { selectedBuildingUuid, setSelectedBuildingUuid, selectedLevelUuid, setSelectedLevelUuid } =
    useContext(BuildingDataContext);

  const { data: destinationData, loading } = useDestinationBuildingsQuery({
    variables: {
      uuid: site.uuid,
    },
  });

  const [addSpaceMutation, { loading: createLoading }] = useAddSpaceMutation();
  const [updateSpaceMutation, { loading: updateSpaceLoading }] = useUpdateSpaceMutation();
  const [deleteSpaceMutation] = useDeleteSpaceMutation();
  const [createOrUpdateBuildingMutation, { loading: updateBuildingLoading }] = useCreateOrUpdateBuildingMutation();
  const [deleteBuildingMutation] = useDeleteBuildingMutation();
  const [updateLevelMutation, { loading: updateLevelLoading }] = useUpdateBuildingLevelMutation();
  const [updateLevelOrderMutation, { loading: updateLevelOrderLoading }] = useUpdateLevelOrderMutation();
  const [updateSpaceOrderMutation, { loading: updateSpaceOrderLoading }] = useUpdateSpaceOrderMutation();
  const [updateBuildingOrderMutation, { loading: updateBuildingOrderLoading }] = useUpdateBuildingOrderMutation();
  const [duplicateSpaceMutation, { loading: duplicateSpaceLoading }] = useDuplicateSpaceMutation();
  const [duplicateLevelMutation, { loading: duplicateLevelLoading }] = useDuplicateLevelMutation();
  const [duplicateBuildingMutation, { loading: duplicateBuildingLoading }] = useDuplicateBuildingMutation();
  const [deleteBuildingLevelMutation, { loading: deleteBuildingLevelLoading }] = useDeleteBuildingLevelMutation();
  const [addBuildingLevelMutation, { loading: addBuildingLevelLoading }] = useAddBuildingLevelMutation();

  const selectedBuilding = useMemo(() => {
    return destinationData?.destination.buildings?.find((building) => building.uuid === selectedBuildingUuid);
  }, [destinationData, selectedBuildingUuid]);

  const selectedLevel = useMemo(() => {
    return selectedBuilding?.buildingLevels.find((level) => level.uuid === selectedLevelUuid);
  }, [selectedBuilding, selectedLevelUuid]);

  const spaces = useMemo(() => {
    return selectedLevelUuid != null
      ? selectedLevel?.spaces.map((space) => ({
          id: space.uuid,
          title: space.name,
        })) ?? []
      : undefined;
  }, [selectedLevel, selectedLevelUuid]);

  const levels = useMemo(() => {
    return selectedBuildingUuid != null
      ? selectedBuilding?.buildingLevels.map((level) => ({
          id: level.uuid,
          title: level.name,
          count: level.spaces.length,
        })) ?? []
      : undefined;
  }, [selectedBuilding, selectedBuildingUuid]);

  const buildings = useMemo(() => {
    return destinationData?.destination.buildings?.map((building) => ({
      id: building.uuid,
      title: building.name,
    }));
  }, [destinationData]);

  const updateLevel = async (id: string, title: string) => {
    if (id === NEW_ID) {
      if (levels != null && selectedBuilding != null) {
        if (
          levels
            .filter((c) => c.id !== id)
            .map((c) => c.title.toLowerCase().replace(/\s/g, ""))
            .includes(title.toLowerCase().replace(/\s/g, ""))
        ) {
          toast.negative(t("settings.build.levelExists"));
          return;
        }

        await addBuildingLevelMutation({
          variables: {
            input: {
              name: title,
              buildingUuid: selectedBuilding.uuid,
            },
          },
          refetchQueries: ["DestinationBuildings"],
        });
      }
    } else {
      try {
        await updateLevelMutation({
          variables: {
            input: {
              uuid: id,
              name: title,
            },
          },
          refetchQueries: ["DestinationBuildings"],
        });
      } catch (e: unknown) {
        const error = e as ApolloError;
        toast.negative(error.message);
      }
    }
  };

  const deleteLevel = async (id: string) => {
    try {
      const levelName = selectedBuilding?.buildingLevels.find((level) => level.uuid === id)?.name ?? "";

      await deleteBuildingLevelMutation({
        variables: {
          uuid: id,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      toast.positive(t("settings.build.deleteSuccess", { name: levelName }));
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const updateSpace = async (id: string, title: string) => {
    if (selectedLevelUuid != null) {
      try {
        if (id === NEW_ID) {
          await addSpaceMutation({
            variables: {
              input: {
                name: title,
                buildingLevelUuid: selectedLevelUuid,
              },
            },
            refetchQueries: ["DestinationBuildings"],
          });
        } else {
          await updateSpaceMutation({
            variables: {
              input: {
                uuid: id,
                name: title,
              },
            },
            refetchQueries: ["DestinationBuildings"],
          });
        }
      } catch (e: unknown) {
        const error = e as ApolloError;
        toast.negative(error.message);
      }
    }
  };

  const deleteSpace = async (uuid: string) => {
    if (selectedLevelUuid != null) {
      const spaceName = selectedLevel?.spaces.find((space) => space.uuid === uuid)?.name ?? "";

      const result = await deleteSpaceMutation({
        variables: {
          uuid,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      if (result.data?.deleteSpace === false) {
        toast.negative(t("settings.build.failedToDeleteSpace"));
        return;
      }

      toast.positive(t("settings.build.deleteSuccess", { name: spaceName }));
    }
  };

  const createOrUpdateBuilding = async (input: BuildingFormValues) => {
    const result = await createOrUpdateBuildingMutation({
      variables: {
        input: {
          uuid: input.uuid,
          name: input.name,
          area: input.area ?? 0,
          units: input.units ?? "",
          occupants: input.occupants ?? 0,
          destinationUuid: site.uuid,
          buildingAddress: input.addressStatus === "enabled" ? input.address : undefined,
          levels:
            input.uuid != null
              ? destinationData?.destination.buildings
                  ?.find((building) => building.uuid === input.uuid)
                  ?.buildingLevels.map((level) => level.name) ?? []
              : [],
        },
      },
      refetchQueries: ["DestinationBuildings"],
    });

    if (result.data?.createOrUpdateBuilding.__typename === "BuildingSyncSuccess") {
      setSelectedBuildingUuid(result.data.createOrUpdateBuilding.building.uuid);
    }

    return result;
  };

  const deleteBuilding = async (uuid: string) => {
    const buildingName = destinationData?.destination.buildings?.find((building) => building.uuid === uuid)?.name;

    const result = await deleteBuildingMutation({
      variables: {
        uuid,
      },
      refetchQueries: ["DestinationBuildings"],
    });

    if (result.data?.deleteBuilding === false) {
      toast.negative(t("settings.build.failedToDeleteBuilding"));
      return;
    }

    toast.positive(t("settings.build.deleteSuccess", { name: buildingName ?? "" }));
  };

  const updateBuildingOrder = async (uuids: string[]) => {
    try {
      const result = await updateBuildingOrderMutation({
        variables: {
          uuids,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      if (result.data?.updateBuildingOrder === false) {
        toast.negative(t("settings.build.failedToUpdateBuildingOrder"));
      }
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const updateLevelOrder = async (uuids: string[]) => {
    try {
      const result = await updateLevelOrderMutation({
        variables: {
          uuids,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      if (result.data?.updateBuildingLevelOrder === false) {
        toast.negative(t("settings.build.failedToUpdateLevelOrder"));
      }
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const updateSpaceOrder = async (uuids: string[]) => {
    try {
      const result = await updateSpaceOrderMutation({
        variables: {
          uuids,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      if (result.data?.updateSpaceOrder === false) {
        toast.negative(t("settings.build.failedToUpdateSpaceOrder"));
      }
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const duplicateSpace = async (uuid: string) => {
    try {
      const result = await duplicateSpaceMutation({
        variables: {
          uuid,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      return result.data?.duplicateSpace;
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
      return null;
    }
  };

  const duplicateLevel = async (uuid: string) => {
    try {
      const result = await duplicateLevelMutation({
        variables: {
          uuid,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      return result.data?.duplicateBuildingLevel;
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
      return null;
    }
  };

  const duplicateBuilding = async (uuid: string) => {
    try {
      const result = await duplicateBuildingMutation({
        variables: {
          uuid,
        },
        refetchQueries: ["DestinationBuildings"],
      });

      return result.data?.duplicateBuilding;
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
      return null;
    }
  };

  return {
    NEW_ID,
    spaces,
    levels,
    buildings,
    destinationBuildings: destinationData?.destination.buildings,
    loading,
    selectedBuildingUuid,
    selectedLevelUuid,
    selectedBuilding,
    createLoading,
    updateLoading:
      updateBuildingLoading ||
      updateLevelLoading ||
      updateSpaceLoading ||
      updateLevelOrderLoading ||
      updateSpaceOrderLoading ||
      updateBuildingOrderLoading ||
      duplicateSpaceLoading ||
      duplicateLevelLoading ||
      duplicateBuildingLoading ||
      deleteBuildingLevelLoading ||
      addBuildingLevelLoading,
    orderLoading: updateLevelOrderLoading || updateSpaceOrderLoading || updateBuildingOrderLoading,
    duplicateLoading: duplicateSpaceLoading || duplicateLevelLoading || duplicateBuildingLoading,
    setSelectedLevelUuid,
    setSelectedBuildingUuid,
    updateSpace,
    deleteSpace,
    updateLevel,
    deleteLevel,
    createOrUpdateBuilding,
    deleteBuilding,
    updateLevelOrder,
    updateSpaceOrder,
    updateBuildingOrder,
    duplicateSpace,
    duplicateLevel,
    duplicateBuilding,
  };
}
