import React, { useContext, useEffect } from "react";

import { AppointmentsMenuContext, stringNotEmpty, useBcUuidContext, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Dropdown, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBin6Line, RiEdit2Line, RiMoreFill } from "@equiem/react-admin-ui/icons";

import type { VisitorReception, VisitorRole } from "../../../generated/visitors-client";
import { EditAppointmentContext } from "../context/EditAppointmentContext";
import { useAppointmentFormMetadata } from "../hooks/useAppointmentFormMetadata";
import { useIsExpiredAppointmentByDateAndTime } from "../hooks/useIsExpiredAppointmentByDateAndTime";
import type { FormValues } from "../types";

import { AppointmentFormEditable } from "./AppointmentFormEditable";
import { AppointmentFormPreview } from "./AppointmentFormPreview";
import { AppointmentFormTitle } from "./AppointmentTitle";

type Props = {
  type: "create" | "update";
  showAdditionalDates?: boolean;
  as?: VisitorRole;
  receptionUuid?: string;
  uuid?: string;
  isReceptionView?: boolean;
  isAllBuildingsReceptionsView?: boolean;
};

export const AppointmentForm: React.FC<Props> = ({
  isReceptionView = false,
  isAllBuildingsReceptionsView = false,
  type,
  as: role,
  receptionUuid,
  uuid,
}) => {
  const isMobile = useIsMobileWidth();
  const { t } = useTranslation();
  const { isPreview, setIsPreview, hasEditableAccess, isEndUserHasEditableAccess } =
    useContext(AppointmentsMenuContext);
  const { isEditView, onDeleteAppointment } = useContext(EditAppointmentContext);
  const { values } = useSaferFormikContext<FormValues>();
  const { colors, breakpoints } = useTheme();
  const { receptions, reception, receptionsLoading } = useAppointmentFormMetadata({
    companyUuid: stringNotEmpty(values.host.company?.uuid) ? values.host.company?.uuid : undefined,
    as: role,
    receptionUuid,
    isAllBuildingsReceptionsView,
  });
  const { updateBcUuid } = useBcUuidContext();
  const isAppointmentExpired = useIsExpiredAppointmentByDateAndTime(values.startTime, values.date);

  const isEditAllowed = hasEditableAccess || isEndUserHasEditableAccess;
  const isControlsVisible = !isMobile && !isEditView && !isAppointmentExpired && isEditAllowed && !isReceptionView;

  useEffect(() => {
    if (type === "update" && !isPreview) {
      updateBcUuid(uuid as string, t("visitors.appointmentForm.editBreadcrumb", { title: values.title }));
    }
  }, [isPreview, type, t, updateBcUuid, uuid, values.title]);

  return (
    <div className="main-form-side">
      <div className="d-flex justify-content-between">
        <AppointmentFormTitle
          type={type}
          isEditView={isEditView}
          isReceptionView={isReceptionView}
          title={values.title}
        />
        {isControlsVisible && (
          <div className="actions">
            <Button
              variant="outline"
              onClick={() => {
                setIsPreview(!isPreview);
              }}
            >
              <RiEdit2Line size={16} />
              {t("common.edit")}
            </Button>
            <Dropdown.Container
              sameWidth={false}
              placement="bottom-end"
              trigger={
                <Button variant="ghost" round size="md">
                  <RiMoreFill size={16} />
                </Button>
              }
            >
              <Button
                disabled={uuid == null || isAppointmentExpired}
                variant="ghost"
                size="sm"
                onClick={() => onDeleteAppointment(uuid as string)}
              >
                <RiDeleteBin6Line size={16} color={colors.danger} />
                <span className="cancel-text">{t("common.cancel")}</span>
              </Button>
            </Dropdown.Container>
          </div>
        )}
      </div>
      {isReceptionView || isEditView ? (
        <AppointmentFormEditable
          type={type}
          receptions={receptions as Array<VisitorReception & { name: string }>}
          reception={reception}
          receptionsLoading={receptionsLoading}
        />
      ) : (
        <AppointmentFormPreview receptions={receptions as Array<VisitorReception & { name: string }>} />
      )}

      <style jsx>
        {`
          .actions {
            display: flex;
            gap: 8px;
            height: 100%;
          }
          .cancel-text {
            text-transform: capitalize;
          }
          .main-form-side {
            padding: 32px 32px 0;
          }
          @media (max-width: ${breakpoints.sm}px) {
            .main-form-side {
              padding: 16px 16px 0;
            }
          }
        `}
      </style>
    </div>
  );
};
