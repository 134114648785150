import type { WeekdayNumbers } from "luxon";
import { DateTime } from "luxon";

import { Weekday } from "../generated/gateway-client";

const weekdayMap: Record<Weekday, WeekdayNumbers> = {
  [Weekday.Mon]: 1,
  [Weekday.Tue]: 2,
  [Weekday.Wed]: 3,
  [Weekday.Thu]: 4,
  [Weekday.Fri]: 5,
  [Weekday.Sat]: 6,
  [Weekday.Sun]: 7,
};

export const formatWeekday = (weekday: Weekday, language: string): string => {
  // As a general rule, rolling your own custom localised formatters like this
  // is a bad idea and should be avoided.
  // However, in this case, this format is only relevant here and and we
  // explicitly * don't* want it reused elsewhere.
  const dayOfWeekFormatter = new Intl.DateTimeFormat(language, { weekday: "short" });

  const weekdayNum = weekdayMap[weekday];

  const d = DateTime.local().set({ weekday: weekdayNum }).toJSDate();

  return dayOfWeekFormatter.format(d);
};
