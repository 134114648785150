import React, { useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { ReceiverOption } from "./CreateActivity";
import { CreateActivity } from "./CreateActivity";

interface Props {
  scrollToBottom?: boolean;
  messageOptions?: {
    receivers: ReceiverOption[];
    onSubmit: (message: string, receiverId?: string) => void;
  };
  dataLength: number;
  hasMore: boolean;
  next: () => void | Promise<void>;
}

export const ActivityFeed: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  messageOptions,
  scrollToBottom,
  next,
  dataLength,
  hasMore,
}) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToBottom === true) {
      contentContainerRef.current?.scrollTo({
        top: contentContainerRef.current.scrollHeight,
      });
    }
  }, [scrollToBottom]);

  return (
    <>
      <div className="container">
        <div className="py-6 pl-8 pr-6 content" id="activity-feed" ref={contentContainerRef}>
          <InfiniteScroll
            next={next}
            dataLength={dataLength}
            hasMore={hasMore}
            loader={null}
            inverse={true}
            style={{ display: "flex", flexDirection: "column-reverse", overflow: "visible" }}
            scrollableTarget="activity-feed"
            scrollThreshold="550px"
          >
            {children}
          </InfiniteScroll>
        </div>
        {messageOptions != null && (
          <div className="footer">
            <CreateActivity {...messageOptions} />
          </div>
        )}
      </div>
      <style jsx>{`
        .container {
          overflow: hidden;
          height: 100%;
        }
        .content {
          overflow-y: auto;
          height: calc(100% - ${messageOptions != null ? 120 : 0}px);
        }
        .footer {
          position: sticky;
          bottom: 0;
          background: #fff;
          z-index: 1;
        }
      `}</style>
    </>
  );
};
