import { MainContainer, Site, SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Tabs, useTheme } from "@equiem/react-admin-ui";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { BookingsAuthContext } from "../../../contexts/BookingsAuthContext";

interface P {
  children?: React.ReactNode;
  actionComponent?: React.ReactNode;
  title?: string;
  prefixTopMenu?: React.ReactNode;
}
export const SettingsTab: React.FC<P> = ({ actionComponent, children, title, prefixTopMenu }) => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const router = useRouter();
  const site = useContext(Site);
  const { canManageSite, canManageBuildingCompany, canManageSiteCompany, canManageCompanyBookingSettings } =
    useContext(BookingsAuthContext);

  const canManageDiscounts = canManageSiteCompany || canManageSite || canManageBuildingCompany;
  const dynamicPricingEnabled = process.env.dynamicPricingEnabled === "true";

  const tabs: TabItem[] = [
    ...(canManageSite
      ? [{ title: t("bookings.settings.resourceConfiguration"), key: "/bookings/settings/resource-configuration" }]
      : []),
    ...(canManageCompanyBookingSettings
      ? [{ title: t("bookings.settings.invoiceDetails"), key: "/bookings/settings/invoice-details" }]
      : []),
    ...(site.tierLevel !== "Essentials" && canManageSite
      ? [{ title: t("bookings.settings.paymentDetails"), key: "/bookings/settings/payment-details" }]
      : []),
    ...(canManageBuildingCompany
      ? [{ title: t("bookings.settings.blockOutDates"), key: "/bookings/settings/blockout-dates" }]
      : []),
    ...(canManageDiscounts && dynamicPricingEnabled
      ? [{ title: t("breadcrumbs.discounts"), key: "/bookings/settings/discounts" }]
      : []),
  ];

  const handleTabSelect = (key: string) => {
    void router.push(key);
  };

  return (
    <>
      <Head>
        <title>{t("bookings.settings.bookingSetting")}</title>
      </Head>
      <SubTopMenu btmPadding={false}>
        <h1 className="mb-3 font-weight-bold">{title ?? t("bookings.settings.bookingSetting")}</h1>
      </SubTopMenu>
      <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
        <Tabs
          items={tabs}
          selected={router.asPath}
          tabLinks
          onSelect={handleTabSelect}
          actionComponent={actionComponent}
        />
      </SubTopMenu>
      {prefixTopMenu != null && <SubTopMenu border={false}>{prefixTopMenu}</SubTopMenu>}
      <div className={prefixTopMenu != null ? "" : "pt-4"}>
        <MainContainer>{children}</MainContainer>
      </div>
      <style jsx>{`
        div {
          background: ${theme.colors.white};
          min-height: 100%;
        }
      `}</style>
    </>
  );
};
