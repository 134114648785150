import React, { createContext, useState } from "react";

export const SideMenuContext = createContext({
  setSiteSwitcherEnabled: (_enabled: boolean): void => undefined,
  siteSwitcherEnabled: true,
});

export const SideMenuProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [siteSwitcherEnabled, setSiteSwitcherEnabled] = useState(true);

  return (
    <SideMenuContext.Provider
      value={{
        siteSwitcherEnabled,
        setSiteSwitcherEnabled,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  );
};
