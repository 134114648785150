import React, { useContext, useEffect } from "react";
import { BookingModal } from "../../operations/contexts/BookingModalContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingDiscountAddEditContext } from "./BookingDiscountAddEditProvider";
import { Modal, ActivityFeed, Skeleton, EmptyState, Alert } from "@equiem/react-admin-ui";
import { ActivitySortField, useActivitiesQuery } from "../../../generated/gateway-client";
import { BookingDiscountActivityDisplay } from "./BookingDiscountActivityDisplay";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";

const LoadingState = () => (
  <div>
    <div className="mb-2">
      <Skeleton.Line width="100px" height="15px" />
    </div>
    <Skeleton.Line width="250px" height="20px" />
  </div>
);

export const BookingDiscountActivityHistory: React.FC = () => {
  const { selectedDiscount: discount } = useContext(BookingDiscountAddEditContext);
  const modal = useContext(BookingModal);
  const { t } = useTranslation();

  const { data, loading, fetchMore, called } = useActivitiesQuery({
    variables: {
      entityUuid: discount!.uuid,
      first: 100,
      sort: {
        asc: true,
        field: ActivitySortField.Timestamp,
      },
    },
    skip: discount == null,
  });

  const fetchMoreAsync = async () => {
    await fetchMore({
      variables: {
        after: data?.activities.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          activities: {
            ...fetchMoreResult.activities,
            edges: [...prev.activities.edges, ...fetchMoreResult.activities.edges],
          },
        };
      },
    });
  };

  useEffect(() => {
    modal.setTitle(t("common.activity"));
  }, [modal, t]);

  if (discount == null) {
    return <></>;
  }

  return (
    <>
      <Modal.Body>
        {called && !loading && data?.activities.edges.length === 0 && (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <EmptyState message={t("bookings.discounts.activity.noActivity")} />
          </div>
        )}
        <ActivityFeed
          next={fetchMoreAsync}
          dataLength={data?.activities.totalCount ?? 0}
          hasMore={data?.activities.pageInfo.hasNextPage ?? false}
        >
          {!loading ? (
            data?.activities.edges.map(({ node }) =>
              node != null ? <BookingDiscountActivityDisplay key={node.uuid} activity={node} /> : null,
            )
          ) : (
            <LoadingState />
          )}
        </ActivityFeed>
      </Modal.Body>
      <Modal.Footer>
        <Alert
          className="w-100"
          icon={<RiInformationLine size={20} />}
          variant="gray"
          message={t("bookings.discounts.activityHistoryLimitWarning")}
        />
      </Modal.Footer>
    </>
  );
};
