import type { RefObject } from "react";
import React, { useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";

import { useSaferFormikContext } from "@equiem/lib";
import type { AppointmentWeekday } from "@equiem/lib/context/types";
import { AppointmentRecurringType } from "@equiem/lib/context/types";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { ButtonLink, Text, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiBuilding4Line,
  RiCalendarLine,
  RiRepeat2Line,
  RiTimeLine,
} from "@equiem/react-admin-ui/icons";

import type { VisitorReception } from "../../../generated/visitors-client";
import { useWeekdayMapper } from "../hooks/useWeekdayMapper";
import type { FormValues } from "../types";
import { getAppointmentIntervals } from "../utils/appointmentIntervals";

import { getWeekdayOccuranceOfWeekdayInMonth } from "./recurring-settings/RecurringSettingsAuxiliaryFunctions";
import { Notes } from "./Notes";

export const AppointmentFormPreview = ({ receptions }: { receptions: Array<VisitorReception & { name: string }> }) => {
  const isMobile = useIsMobileWidth();
  const { t, i18n } = useTranslation();
  const { values } = useSaferFormikContext<FormValues>();
  const { colors } = useTheme();
  const reception = receptions.find((item) => item.uuid === values.location);
  const descriptionRefContainer = useRef() as RefObject<HTMLParagraphElement>;
  const [isDescriptionHide, setIsDescriptionHide] = useState(true);
  const { weekDayMap, weekDayMapNumber } = useWeekdayMapper();

  const durationPreview = useMemo(() => {
    const startTime = formatters.timeshort(DateTime.fromSeconds(Number(values.startTime)), i18n.language);
    const endTime = formatters.timeshort(
      DateTime.fromFormat(`${values.date} ${values.duration}`, "yyyy-LL-dd h:mma"),
      i18n.language,
    );
    const date = DateTime.fromFormat(values.date, "yyyy-MM-dd");
    const { year, month, day } = date.toObject();
    const { minute, second, hour } = DateTime.fromSeconds(Number(values.startTime)).toObject();
    const {
      minute: nextMinute,
      second: nextSecond,
      hour: nextHour,
    } = DateTime.fromFormat(`${values.date} ${values.duration}`, "yyyy-LL-dd h:mma").toObject();

    const minDate = DateTime.fromObject({
      hour,
      minute,
      second,
      year,
      month,
      day,
    });

    const nextDate = DateTime.fromObject({
      hour: nextHour,
      minute: nextMinute,
      second: nextSecond,
      month,
      year,
      day,
    });

    const duration = nextDate.diff(minDate).as("minutes");

    return `${startTime} - ${endTime} (${formatters.durationshort(duration, i18n.language)})`;
  }, [values.startTime, values.date, values.duration, getAppointmentIntervals, i18n.language]);

  const ellipsedText = useMemo(() => {
    const RESERVED_SPACE_LETTER_WIDTH = 5.5;
    const TEXT_LETTER_WIDTH = 6;
    const ROWS = 3;

    const reservedSpace = t("visitors.common.showMore").length * RESERVED_SPACE_LETTER_WIDTH;

    const wordsLength = Math.round(((descriptionRefContainer.current?.clientWidth ?? 0) / TEXT_LETTER_WIDTH) * ROWS);
    const allowedLength = wordsLength - Math.round(reservedSpace);
    const isAllowed = isMobile && values.description.length > allowedLength;

    if (isAllowed && isDescriptionHide) {
      return { text: `${values.description.slice(0, wordsLength - Math.round(reservedSpace))}...`, isAllowed };
    }

    return { text: values.description, isAllowed: !isDescriptionHide && isAllowed };
  }, [t, descriptionRefContainer.current?.clientWidth, isMobile, isDescriptionHide, values.description]);

  const getRecurringSummaryText = (): string => {
    if (
      values.recurringInfo?.recurringType == null ||
      values.recurringInfo.repeatEvery == null ||
      values.recurringInfo.appointments.length === 0
    ) {
      return "";
    }

    const isSingular = values.recurringSettings?.repeatEvery === 1;

    let p1: string =
      values.recurringInfo.recurringType === AppointmentRecurringType.Daily
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryDay").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryDays").toLocaleLowerCase()
        : values.recurringInfo.recurringType === AppointmentRecurringType.Weekly
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryWeek").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryWeeks").toLocaleLowerCase()
        : values.recurringInfo.recurringType === AppointmentRecurringType.Monthly
        ? isSingular
          ? t("visitors.appointmentForm.appointmentRecurringOccursEveryMonth").toLocaleLowerCase()
          : t("visitors.appointmentForm.appointmentRecurringOccursEveryMonths").toLocaleLowerCase()
        : "placeholder";

    let repeatEveryStr = isSingular ? "" : values.recurringSettings?.repeatEvery;

    if (values.recurringSettings?.sameWeekDayEachMonth === true) {
      p1 = weekDayMapNumber[DateTime.fromMillis(values.recurringSettings.startDate).weekday].toLocaleLowerCase();
      repeatEveryStr = getWeekdayOccuranceOfWeekdayInMonth(DateTime.fromMillis(values.recurringSettings.startDate));
    }

    if (values.recurringSettings?.lastWeekDayEachMonth === true) {
      p1 = weekDayMapNumber[DateTime.fromMillis(values.recurringSettings.startDate).weekday];
    }

    const repeatWeekdays =
      values.recurringInfo.repeatOn != null ? (values.recurringInfo.repeatOn as AppointmentWeekday[]) : [];
    const p2 = repeatWeekdays
      .sort((a, b) => a - b)
      .map((x, i, arr) =>
        i === arr.length - 1 && arr.length !== 1 ? `${t("common.and")} ${weekDayMap[x]}` : `${weekDayMap[x]} `,
      )
      .join("");

    const repeatType: string =
      values.recurringInfo.recurringType !== AppointmentRecurringType.Weekly ||
      values.recurringInfo.repeatOn?.length === 7
        ? `${
            values.recurringSettings?.lastWeekDayEachMonth === true
              ? t("visitors.appointmentForm.appointmentRecurringLast")
              : repeatEveryStr
          } ${p1}`
        : `${repeatEveryStr} ${p2}`;

    return t("visitors.appointmentForm.appointmentPreviewRecurringText", {
      repeatType: repeatType,
      recurringEndDate: formatters.datelong(DateTime.fromMillis(values.recurringInfo.endDate), i18n.language),
      times: values.recurringInfo.appointments.filter((x) => x.uuid != null).length,
    });
  };

  return (
    <>
      {values.description != null && values.description.length > 0 && (
        <div className="d-flex flex-column">
          <Text
            variant="text"
            color={colors.grayscale[60]}
            ref={descriptionRefContainer}
            className="p-0 mb-0 mt-3 appointment-description"
          >
            <p className="p-0 m-0 appointment-description-text">
              {ellipsedText.text}
              {isMobile && ellipsedText.isAllowed && (
                <ButtonLink
                  className="py-0 ml-3 px-2 show-more"
                  variant="ghost"
                  onClick={() => setIsDescriptionHide(!isDescriptionHide)}
                >
                  {isDescriptionHide ? (
                    <>
                      {t("visitors.common.showMore")} <RiArrowDownSLine size={16} />
                    </>
                  ) : (
                    <>
                      {t("common.hide")} <RiArrowUpSLine size={16} />
                    </>
                  )}
                </ButtonLink>
              )}
            </p>
          </Text>
        </div>
      )}

      <div className={`info-data ${isMobile ? "mt-4" : "mt-5"}`}>
        <div className="info-data-icons">
          <RiCalendarLine size={16} color={colors.grayscale[60]} />
          <Text variant="text" size="small" className="p-0 m-0">
            {formatters.datelong(DateTime.fromFormat(values.date, "yyyy-LL-dd").toMillis(), i18n.language)}
          </Text>
        </div>
        <div className="info-data-icons">
          <RiTimeLine size={16} color={colors.grayscale[60]} />
          <Text variant="text" size="small" className="p-0 m-0">
            {durationPreview}
          </Text>
        </div>
        <div className="info-data-icons">
          <RiBuilding4Line size={16} color={colors.grayscale[60]} />
          <Text variant="text" size="small" className="p-0 m-0 info-data-icons__text">
            {reception?.name}
          </Text>
        </div>
        {values.recurringSettings != null ? (
          <div className="info-data-icons">
            <RiRepeat2Line size={16} color={colors.grayscale[60]} />
            <Text variant="text" size="small" className="p-0 m-0 info-data-icons__text">
              {getRecurringSummaryText()}
            </Text>
          </div>
        ) : null}
      </div>
      <hr className="mx-1 my-6" />

      {reception != null && <Notes values={values} reception={reception} />}
      <style jsx>
        {`
          :global(.appointment-description .show-more) {
            align-self: flex-end;
            position: relative;
            display: inline;
            line-height: 24px;
          }

          :global(.appointment-description),
          .appointment-description-text {
            display: inline;
            font-size: 14px;
          }

          hr {
            border: 0;
            border-top: 1px solid ${colors.border};
          }

          .info-data {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }

          .info-data-icons {
            display: flex;
            gap: 12px;
          }
        `}
      </style>
    </>
  );
};
