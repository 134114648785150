import { EquiemOne, useTheme } from "@equiem/react-admin-ui";
import Link from "next/link";
import type { FC } from "react";
import { useContext } from "react";
import { Role, SideMenuContext, CurrentRole } from "@equiem/lib";
import { PropertyManagerSideMenu } from "./layouts/PropertyManagerSideMenu";
import { WorkplaceManagerSideMenu } from "./layouts/WorkplaceManagerSideMenu";
import { MenuSwitcher } from "./MenuSwitcher";
import { UnknownSideMenu } from "./layouts/UnknownSideMenu";
import { HelpSupportMenu } from "./HelpSupportMenu";

interface Props {
  width: number;
  toggleMenu: () => void;
}

export const SideMenu: FC<Props> = ({ width }) => {
  const { currentRole } = useContext(CurrentRole);
  const { siteSwitcherEnabled } = useContext(SideMenuContext);
  const { colors, spacers } = useTheme();

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-inner">
          <div className="section border logo-section px-5">
            <Link passHref href="/" legacyBehavior>
              <a className="w-100">
                <EquiemOne />
              </a>
            </Link>
          </div>
          <div className="section menu-switcher">
            <MenuSwitcher enabled={siteSwitcherEnabled} />
          </div>
          <div className="section shortcuts">
            {currentRole === Role.PropertyManager ? (
              <PropertyManagerSideMenu />
            ) : currentRole === Role.WorkplaceManager ? (
              <WorkplaceManagerSideMenu />
            ) : (
              <UnknownSideMenu />
            )}
          </div>
          <div className="spacer" />
          <div className="section help">
            <HelpSupportMenu />
          </div>
        </div>
      </div>
      <style jsx>{`
        .sidebar {
          background: ${colors.white};
          width: ${width}px;
          height: 100dvh;
          border-right: 1px solid ${colors.border};
          overflow-y: auto;
        }
        .sidebar-inner {
          display: flex;
          flex-direction: column;
          min-height: 100dvh;
        }
        .section {
          padding: ${spacers.s3} ${spacers.s3};
          min-height: 64px;
        }
        .border {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .logo-section {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .menu-switcher {
          padding: 0;
        }
        .logo-section a {
          text-decoration: none;
        }
        .cookies {
          height: 50px;
          min-height: 50px;
          margin-top: auto;
        }
        .help {
          height: 48px;
          padding-top: 0;
          padding-bottom: ${spacers.s5};
          position: sticky;
          bottom: 0;
        }
        .spacer {
          min-height: 0;
          flex: 1;
        }
      `}</style>
    </>
  );
};
