import React, { type ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { CurrentProfile, CurrentRole, Role, stringIsEmpty, stringNotEmpty, useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Dropdown,
  Form as EqForm,
  MemberCard,
  Text,
  Tooltip,
  useIsMobileWidth,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import {
  RiEditLine,
  RiFileAddLine,
  RiMoreFill,
  RiNotificationLine,
  RiNotificationOffLine,
  RiSearchLine,
  RiUserAddLine,
  RiUserSettingsLine,
  RiUserUnfollowLine,
} from "@equiem/react-admin-ui/icons";

import { Modal } from "../../../contexts/ModalContext";
import { EditAppointmentContext } from "../context/EditAppointmentContext";
import type { FormValues, FormVisitor } from "../types";

import { AddVisitorInformationModal } from "./AppointmentVisitorInformationModal";
import { VisitorInformationDialog } from "./VisitorInformationDialog";

const SHOWN_VISITORS_AMOUNT = 5;

type NotifyVariables = "notifyHost" | "notifyOrganizer";

export const HostAndVisitors = ({ isReceptionView = false }: { isReceptionView?: boolean }) => {
  const { t } = useTranslation();
  const { currentRole } = useContext(CurrentRole);
  const profile = useContext(CurrentProfile);
  const { colors } = useTheme();
  const modal = useContext(Modal);
  const { setFieldValue, values, touched, errors } = useSaferFormikContext<FormValues>();
  const toast = useToast();
  const isMobile = useIsMobileWidth();
  const { isEditView } = useContext(EditAppointmentContext);
  const [isShowAll, setIsShowAll] = useState(false);
  const [search, setSearch] = useState("");
  const visitorsData = useMemo(() => {
    const visitors = values.visitors.filter((item) =>
      `${item.firstName} ${item.lastName} ${item.email}`.toLowerCase().includes(search.toLowerCase()),
    );

    const croppedVisitors = [...visitors];
    croppedVisitors.length = SHOWN_VISITORS_AMOUNT;

    return {
      totalCount: visitors.length,
      visitors: isShowAll || !isMobile ? visitors : croppedVisitors,
    };
  }, [isShowAll, values.visitors, isMobile, search]);

  const isEditPageView = isEditView || isReceptionView;
  const isChangeHostVisible = useMemo(() => {
    return isEditPageView && ((currentRole === Role.Unknown && profile.isReceptionist) || currentRole !== Role.Unknown);
  }, [currentRole, profile.isReceptionist, isEditPageView]);

  const isDeleteHost = values.host.userUuid !== values.organizer.userUuid ? true : false;

  const handleAddVisitor = () => {
    modal.open("AddVisitor");
  };

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
  };

  const handleBulkUploadVisitors = () => {
    modal.open("BulkUploadVisitors");
  };

  const handleDeleteVisitor = (visitor: FormVisitor) => () => {
    void setFieldValue(
      "visitors",
      values.visitors.filter((v) => v !== visitor),
    ).catch(console.error);

    toast.neutral(t("visitors.appointmentForm.visitorRemoved", { name: `${visitor.firstName} ${visitor.lastName}` }));
  };

  const handleChangeHost = () => {
    if (isDeleteHost) {
      toast.neutral(
        t("visitors.appointmentForm.hostRemoved", { name: `${values.host.firstName} ${values.host.lastName}` }),
      );
      void setFieldValue("host", {
        firstName: values.organizer.firstName ?? "",
        lastName: values.organizer.lastName ?? "",
        company: {
          uuid: values.organizer.company?.uuid ?? "",
          name: values.organizer.company?.name ?? "",
        },
        email: values.organizer.email,
        avatar: values.organizer.avatar ?? "",
        userUuid: values.organizer.userUuid,
      }).catch(console.error);

      if (stringIsEmpty(values.organizer.email)) {
        void setFieldValue("notifyHost", false);
      }
    } else {
      modal.open("ChangeHost");
    }
  };

  const toggleCheckInNotification = (keyName: NotifyVariables) => {
    void setFieldValue(keyName, !values[keyName]);
  };

  const getFieldError = (field: keyof typeof values) =>
    touched[field] === true || Array.isArray(touched[field]) ? errors[field]?.toString() : undefined;

  const renderNotifyDropdownItem = (keyName: NotifyVariables): React.JSX.Element => (
    <Dropdown.Item
      noMargin={false}
      onClick={() => toggleCheckInNotification(keyName)}
      className="mb-0"
      icon={values[keyName] ? RiNotificationOffLine : RiNotificationLine}
    >
      {t(values[keyName] ? "visitors.appointmentForm.dontNotifyHost" : "visitors.appointmentForm.notifyHost")}
    </Dropdown.Item>
  );

  const renderEditNotificationsButton = (children: ReactNode | ReactNode[]): React.JSX.Element | null => {
    if (!isEditPageView) {
      return null;
    }
    return (
      <Dropdown.Icon className="additional-actions" icon={RiMoreFill} placement="bottom-end">
        {children}
      </Dropdown.Icon>
    );
  };

  useEffect(() => {
    const { firstName, lastName, email } = values.organizer;
    if (isEditPageView && stringNotEmpty(firstName) && stringNotEmpty(lastName) && stringIsEmpty(email)) {
      void setFieldValue("notifyOrganizer", false);
    }
  }, [values.organizer, values.organizer.email, values.organizer.firstName, values.organizer.lastName, isEditPageView]);

  return (
    <div className="add-visitors-side px-6 pt-7">
      <EqForm.Group
        label={t("common.host")}
        required
        className="host-block"
        showTooltip={currentRole === Role.WorkplaceManager}
        tooltipText={t("visitors.appointmentForm.hostTooltip")}
      >
        <MemberCard.Card
          firstName={values.host.firstName}
          lastName={values.host.lastName}
          email={values.host.email}
          companyName={values.host.company?.name ?? ""}
          profileIconUrl={values.host.avatar}
          showStar={true}
          onButtonClick={handleChangeHost}
        >
          {values.notifyHost && (
            <Tooltip
              showArrow={true}
              title={t("visitors.appointmentForm.notifyHostTooltip", {
                userType: t("common.host"),
              })}
              placement="top"
            >
              <div className="d-flex align-items-center px-3">
                <RiNotificationLine size={16} />
              </div>
            </Tooltip>
          )}
          {renderEditNotificationsButton([
            isChangeHostVisible && !isDeleteHost && (
              <Dropdown.Item noMargin={false} onClick={handleChangeHost} className="mb-0" icon={RiEditLine}>
                {t("common.change")}
              </Dropdown.Item>
            ),
            stringNotEmpty(values.host.email) && renderNotifyDropdownItem("notifyHost"),
            isChangeHostVisible && isDeleteHost && (
              <Dropdown.Item
                noMargin={false}
                onClick={handleChangeHost}
                className="mb-0"
                variant="danger"
                icon={RiUserUnfollowLine}
              >
                {t("common.remove")}
              </Dropdown.Item>
            ),
          ])}
        </MemberCard.Card>
        {values.host.userUuid !== values.organizer.userUuid && (
          <MemberCard.Card
            firstName={values.organizer.firstName}
            lastName={`${values.organizer.lastName} (${t("visitors.common.organizer")})`}
            email={values.organizer.email}
            companyName={values.organizer.company?.name ?? ""}
            profileIconUrl={values.organizer.avatar ?? undefined}
            className="mt-4"
          >
            {values.notifyOrganizer && (
              <Tooltip
                showArrow={true}
                title={t("visitors.appointmentForm.notifyHostTooltip", {
                  userType: t("visitors.common.organizer"),
                })}
                placement="top"
              >
                <div className="d-flex align-items-center px-3">
                  <RiNotificationLine size={16} />
                </div>
              </Tooltip>
            )}
            {stringNotEmpty(values.organizer.email) &&
              renderEditNotificationsButton(renderNotifyDropdownItem("notifyOrganizer"))}
          </MemberCard.Card>
        )}
      </EqForm.Group>
      <EqForm.Group
        label={`${t("visitors.common.visitors")} (${values.visitors.length})`}
        error={getFieldError("visitors")}
        required
        className="visitors-block mb-0"
      >
        {isEditPageView && (
          <div className="add-visitors-block">
            <div className="add-visitors-btn">
              <Button size="md" type="button" onClick={handleAddVisitor}>
                <span className="add-visitors-text">+ {t("visitors.appointmentForm.addVisitors")}</span>
              </Button>
            </div>
            {!isMobile && (
              <div className="bulk-upload-visitors-btn">
                <Button variant="secondary" size="md" type="button" onClick={handleBulkUploadVisitors}>
                  <RiFileAddLine />
                  <span className="add-visitors-text">{t("common.bulkUpload")}</span>
                </Button>
              </div>
            )}
          </div>
        )}
      </EqForm.Group>
      <EqForm.InputGroup.Group className="mb-0">
        <EqForm.InputGroup.Prefix>
          <RiSearchLine size={16} color={colors.grayscale[40]} />
        </EqForm.InputGroup.Prefix>
        <EqForm.Input
          className="p-0 search-guest"
          placeholder={t("visitors.appointments.findGuest")}
          autoComplete="off"
          onChange={(e) => handleSearch(e.target.value)}
          type="text"
        />
      </EqForm.InputGroup.Group>
      {visitorsData.visitors.length > 0 && (
        <div className="pb-4 visitors-list">
          {visitorsData.visitors.map((visitor, index) => {
            return (
              <MemberCard.Card
                firstName={visitor.firstName}
                lastName={visitor.lastName}
                email={visitor.email ?? ""}
                companyName={visitor.companyName ?? ""}
                key={`${visitor.firstName}_${visitor.lastName}_${index}`}
                className="mt-2"
                showButton={isEditPageView}
                buttonInverted
                buttonVariant="ghost"
                additionalButtons={
                  (visitor.receptionNoteMessage != null || (visitor.visitorInfo?.length ?? 0) > 0) && (
                    <VisitorInformationDialog visitor={visitor} />
                  )
                }
                buttonElement={
                  <>
                    <Dropdown.Container
                      placement="bottom-end"
                      mobileView="minimal"
                      trigger={
                        <Button variant="ghost" round>
                          <RiMoreFill size={16} />
                        </Button>
                      }
                    >
                      {!values.isWalkIn && (
                        <Dropdown.Item
                          icon={
                            visitor.receptionNoteMessage != null || (visitor.visitorInfo?.length ?? 0) > 0
                              ? RiUserSettingsLine
                              : RiUserAddLine
                          }
                          onClick={() => {
                            modal.open("AddVisitorInformation", undefined, { visitor });
                          }}
                        >
                          <Text variant="label" color={colors.grayscale[100]} style={{ textTransform: "none" }}>
                            {visitor.receptionNoteMessage != null || (visitor.visitorInfo?.length ?? 0) > 0
                              ? t("visitors.appointmentForm.editVisitorInformation")
                              : t("visitors.appointmentForm.addVisitorInformation")}
                          </Text>
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        icon={RiUserUnfollowLine}
                        variant={"danger"}
                        onClick={handleDeleteVisitor(visitor)}
                      >
                        <Text variant="label" color={colors.grayscale[100]} style={{ textTransform: "none" }}>
                          {t("common.remove")}
                        </Text>
                      </Dropdown.Item>
                    </Dropdown.Container>
                  </>
                }
              />
            );
          })}

          {isMobile && visitorsData.totalCount > SHOWN_VISITORS_AMOUNT && (
            <Button
              className="w-100 mt-2"
              variant="outline"
              size="lg"
              onClick={() => {
                setIsShowAll(!isShowAll);
              }}
            >
              {isShowAll ? t("common.hide") : t("visitors.common.viewAll")}
            </Button>
          )}
          <AddVisitorInformationModal />
        </div>
      )}
      <style jsx>
        {`
          .add-visitors-block {
            display: flex;
            margin: auto;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
            margin-bottom: 24px;
          }

          .add-visitors-block > div {
            flex: 1;
          }

          .add-visitors-side {
            background: #f7f7f7;
            height: 100%;
          }
          .add-visitors-btn {
            display: flex;
            flex-direction: column;
          }
          .bulk-upload-visitors-btn {
            display: flex;
            flex-direction: column;
          }
          .add-visitors-text {
            font-size: 14px;
          }
        `}
      </style>
    </div>
  );
};
