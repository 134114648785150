import { useContext, useMemo } from "react";
import type { DestinationDetailsQuery } from "../../../generated/gateway-client";
import { useDestinationDetailsQuery, useViewerCompanyDestinationDetailsQuery } from "../../../generated/gateway-client";
import { BookingsAuthContext } from "../../../contexts/BookingsAuthContext";
import type { QueryHookOptions } from "@apollo/client";

export const useAuthorizedDestinationDetails = (
  siteUuid: string,
  options?: Pick<QueryHookOptions<unknown>, "fetchPolicy"> & {
    includeBuildingLevels?: string[];
  },
) => {
  const { canManageSite } = useContext(BookingsAuthContext);

  const viewerDestDetails = useViewerCompanyDestinationDetailsQuery({
    variables: { uuid: siteUuid },
    skip: canManageSite,
    fetchPolicy: options?.fetchPolicy,
  });
  const destDetails = useDestinationDetailsQuery({
    variables: { uuid: siteUuid },
    skip: !canManageSite,
    fetchPolicy: options?.fetchPolicy,
  });

  return useMemo(() => {
    if (canManageSite) {
      return {
        buildings: destDetails.data?.destination.buildings,
        loading: destDetails.loading,
        error: destDetails.error,
      };
    } else {
      const buildingLevels =
        viewerDestDetails.data?.profile?.companyV2?.companyDestination?.buildingLevels.filter(
          (bl) => bl.building.viewerRelations.canManageCompanyBookings,
        ) ?? [];
      const buildingMap = buildingLevels.reduce<
        Record<string, NonNullable<DestinationDetailsQuery["destination"]["buildings"]>[number]>
      >(
        (blgs, l) => ({
          ...blgs,
          [l.building.uuid]: {
            ...l.building,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            buildingLevels: [...(blgs[l.building.uuid]?.buildingLevels ?? []), l],
          },
        }),
        {},
      );
      return {
        buildings: Object.keys(buildingMap).map((uuid) => ({
          ...buildingMap[uuid],
        })),
        loading: viewerDestDetails.loading,
        error: viewerDestDetails.error,
      };
    }
  }, [canManageSite, destDetails, viewerDestDetails]);
};
