import React from "react";
import { DateTime } from "luxon";

import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown } from "@equiem/react-admin-ui";
import type { IconType } from "@equiem/react-admin-ui/icons";
import {
  RiArrowGoBackFill,
  RiArrowGoForwardFill,
  RiEdit2Line,
  RiEyeLine,
  RiMailLine,
  RiMoreFill,
  RiPrinterLine,
  RiStickyNoteLine,
} from "@equiem/react-admin-ui/icons";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../../generated/visitors-client";
import { VisitorStatus } from "../../../../generated/visitors-client";
import { useIsAppointmentExpiredByDate } from "../../../appointment/hooks/useIsAppointmentExpiredByDate";
import { useIsExpiredAppointmentByDateAndTime } from "../../../appointment/hooks/useIsExpiredAppointmentByDateAndTime";
import { ReceptionNotesStep, ReceptionNotesWidget } from "../../widgets/ReceptionNotesWidget";
import { VisitorActivityWidget } from "../../widgets/VisitorActivityWidget";
import { VisitorResendCodeWidget } from "../../widgets/VisitorResendCodeWidget";

type Visitor = VisitorForBuildingReceptionFragment | VisitorForReceptionFragment;

export const ActionsMenu = ({
  visitor,
  handleNotes,
  handleActivity,
  handleResendCode,
  handleEdit,
  handlePrintPass,
  enablePassPrinting,
  handleSetCardStatusReturned,
  receptionType,
  notes,
}: {
  handlePrintPass: (visitor: Visitor) => void;
  enablePassPrinting: boolean;
  handleActivity: (uuid: string) => () => void;
  handleEdit: (uuid: string) => () => void;
  handleResendCode: (uuid: string) => () => void;
  handleSetCardStatusReturned: (visitorUuid: string, returned?: boolean) => Promise<void>;
  visitor: Visitor;
  handleNotes: (visitor: Visitor) => () => void;
  receptionType: "tenant" | "building";
  notes: string;
}) => {
  const { t } = useTranslation();
  const renderDropdownItemConditionally = ({
    condition = true,
    onClick = () => undefined,
    icon = RiArrowGoBackFill,
    tKey = "visitors.reception.markCardAsReturned",
  }: {
    condition?: boolean;
    onClick?: () => void;
    icon?: IconType;
    tKey: string;
  }) =>
    condition && (
      <Dropdown.Item onClick={onClick} icon={icon}>
        {/* @ts-expect-error tKey must be a translation key */}
        {t(tKey)}
      </Dropdown.Item>
    );
  const isWalkin = visitor.appointment.isWalkIn === true;
  const isAppointmentExpired = useIsExpiredAppointmentByDateAndTime(
    visitor.appointment.startTime,
    DateTime.fromMillis(visitor.appointment.startTime).toISODate(),
  );
  const isAppointmentExpiredByDate = useIsAppointmentExpiredByDate(
    DateTime.fromMillis(visitor.appointment.startTime).toISODate(),
  );

  const isWalkinEditable = isWalkin && !isAppointmentExpiredByDate;
  const isPreBookedEditable = visitor.status === "PRE_BOOKED" && !isAppointmentExpiredByDate;
  const iAppointmentEditable = !isWalkin && !isAppointmentExpired;
  const isEditVisible = isWalkinEditable || iAppointmentEditable || isPreBookedEditable;

  return (
    <>
      <Dropdown.Icon icon={RiMoreFill} size="md" placement="bottom-end">
        {renderDropdownItemConditionally({
          onClick: handleNotes(visitor),
          tKey: "visitors.reception.receptionNotes",
          icon: RiStickyNoteLine,
        })}
        {renderDropdownItemConditionally({
          onClick: handleActivity(visitor.uuid),
          tKey: "visitors.reception.activityHistory",
          icon: RiEyeLine,
        })}
        {renderDropdownItemConditionally({
          condition: visitor.code != null && !isWalkin,
          onClick: handleResendCode(visitor.uuid),
          tKey: "visitors.reception.resendAppointmentCodeEmail",
          icon: RiMailLine,
        })}
        {renderDropdownItemConditionally({
          condition: isEditVisible,
          onClick: handleEdit(visitor.appointment.uuid),
          tKey: "visitors.reception.editAppointment",
          icon: RiEdit2Line,
        })}
        {renderDropdownItemConditionally({
          condition: visitor.status === VisitorStatus.CheckedOut && visitor.cardId != null && !visitor.cardReturned,
          onClick: () => {
            void handleSetCardStatusReturned(visitor.uuid);
          },
          tKey: "visitors.reception.markCardAsReturned",
          icon: RiArrowGoBackFill,
        })}
        {renderDropdownItemConditionally({
          condition: visitor.status === VisitorStatus.CheckedOut && visitor.cardId != null && visitor.cardReturned,
          onClick: () => {
            void handleSetCardStatusReturned(visitor.uuid, false);
          },
          tKey: "visitors.reception.markCardAsNotReturned",
          icon: RiArrowGoForwardFill,
        })}
        {renderDropdownItemConditionally({
          condition: enablePassPrinting,
          onClick: () => handlePrintPass(visitor),
          tKey: "visitors.reception.printPass",
          icon: RiPrinterLine,
        })}
      </Dropdown.Icon>

      <ReceptionNotesWidget
        organizerName={`${visitor.appointment.organizer?.firstName} ${visitor.appointment.organizer?.lastName}`}
        visitor={visitor}
        notes={notes}
        initialStep={
          visitor.receptionistNotes.length > 0 || stringNotEmpty(notes)
            ? ReceptionNotesStep.View
            : ReceptionNotesStep.AddNew
        }
        id={`${visitor.appointment.uuid}_${visitor.uuid}`}
      />

      <VisitorActivityWidget
        id={visitor.uuid}
        receptionType={receptionType}
        isWalkIn={visitor.appointment.isWalkIn === true}
      />

      <VisitorResendCodeWidget
        id={visitor.uuid}
        email={visitor.email ?? undefined}
        fullName={`${visitor.firstName} ${visitor.lastName}`}
      />
    </>
  );
};
