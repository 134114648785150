import { useContext, useMemo } from "react";
import { Site, notNullOrUndefined } from "@equiem/lib";
import { useFormikContext } from "formik";
import uniqBy from "lodash/uniqBy";

import {
  DestinationTierLevel,
  useDestinationClientQuery,
  useSearchDestinationsByClientQuery,
  useGetCompanyDestinationsQuery,
} from "../../../generated/gateway-client";
import type { FormValues } from "../../../lib/formValidation";
import { useCompanyIsFlexOperator } from "./useCompanyIsFlexOperator";

export interface AudienceDestination {
  uuid: string;
  name: string;
}

export function useAudienceDestinations(): AudienceDestination[] {
  const fm = useFormikContext<FormValues>();

  const site = useContext(Site);
  const isEssentials = site.tierLevel === DestinationTierLevel.Essentials;
  const currentDestination = useMemo(
    () => ({ uuid: site.uuid, name: site.name, tierLevel: site.tierLevel }),
    [site.uuid, site.name, site.tierLevel],
  );

  const client = useDestinationClientQuery({ variables: { uuid: site.uuid } });
  const clientUuid = client.data?.destination.client?.uuid;
  const clientDestinationsQuery = useSearchDestinationsByClientQuery({
    variables: { clientUuid: clientUuid ?? "" },
    skip: clientUuid == null,
  });
  const clientDestinations = clientDestinationsQuery.data?.searchDestinationsByClient;

  const isFlexOperator = useCompanyIsFlexOperator();
  const companyDestinationsQuery = useGetCompanyDestinationsQuery({
    variables: { uuid: fm.values.ownerCompanyUuid },
    skip: !isFlexOperator,
  });
  const companyDestinations = companyDestinationsQuery.data?.companyV2?.companyDestinations;

  const output = useMemo(() => {
    const list = [
      currentDestination,
      ...(clientDestinations ?? []),
      ...(companyDestinations ?? []).map((cd) => cd.destination),
    ]
      .filter(notNullOrUndefined)
      .filter((d) => isEssentials || d.tierLevel !== DestinationTierLevel.Essentials)
      .sort((a, b) => a.name.localeCompare(b.name));

    return uniqBy(list, (d) => d.uuid);
  }, [currentDestination, isEssentials, clientDestinations, companyDestinations]);

  return output;
}
