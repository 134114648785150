import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, Table, remainingCharacters, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine, RiCloseLine } from "@equiem/react-admin-ui/icons";
import { Form as FormikForm, Formik, Field } from "formik";
import React from "react";
import type {
  BookableResourceAddOnFragmentFragment as BookableResourceExtras,
  BookableResourceAddOnOption as BookableResourceExtrasOption,
} from "../../../../../generated/gateway-client";
import {
  BookableResourceAddOnType as BookableResourceExtrasType,
  DestinationTierLevel,
} from "../../../../../generated/gateway-client";
import { convertInputNumberToNumber } from "../../../../../lib/convertNumberStringToNumber";
import { extrasModalValidationSchema } from "../../../../../lib/validationSchema";

type ExtrasModalFormValues = {
  extras: BookableResourceExtras;
};

const TITLE_MAX_LENGTH = 100;
const TOOLTIP_MAX_LENGTH = 500;
const NAME_MAX_LENGTH = 100;

const MAX_OPTIONS_NUMBER = 10;

const defaultOption: BookableResourceExtrasOption = { name: "", hasQuantity: false, uuid: "", unitPrice: 0 };

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  modalIndex: number | null;
  setModalIndex: (_index: number | null) => void;
  extras?: BookableResourceExtras;
  currency?: string;
  onAdd: (_extras: BookableResourceExtras) => void;
  onEdit: (_extras: BookableResourceExtras, _index: number) => void;
}

export const ResourceCreateAndEditFormExtrasModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  modalIndex,
  setModalIndex,
  extras,
  currency,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);
  const { tierLevel } = useSiteContext();

  return (
    <Modal.Dialog
      title={modalIndex != null ? t("bookings.resources.editExtras") : t("bookings.resources.addExtras")}
      show={showModal}
      size="xl"
      onHide={() => {
        setShowModal(false);
        setModalIndex(null);
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="extras-modal">
        <Modal.Header supportsMobile={true} closeButton={true} noBorder={false} />
        <Formik<ExtrasModalFormValues>
          initialValues={{
            extras: extras ?? {
              uuid: "",
              name: "",
              tooltipTxt: "",
              mandatory: false,
              type: BookableResourceExtrasType.SingleOption,
              options: [defaultOption],
            },
          }}
          validationSchema={extrasModalValidationSchema(t)}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            modalIndex != null ? onEdit(values.extras, modalIndex) : onAdd(values.extras);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid, submitForm, setFieldValue, touched, values, errors }) => (
            <FormikForm>
              <Modal.Body>
                <div className="form-group-container">
                  <Form.Group
                    className="extras-title"
                    label={t("bookings.resources.extrasTitle")}
                    required
                    error={touched.extras?.name === true ? errors.extras?.name : undefined}
                    hint={t("common.remainingCharacters", {
                      count: remainingCharacters(TITLE_MAX_LENGTH, values.extras.name?.length ?? 0),
                    })}
                  >
                    <Field
                      id="extras.name"
                      name="extras.name"
                      placeholder={t("bookings.resources.extrasTitleHint")}
                      maxLength={TITLE_MAX_LENGTH}
                      as={Form.Input}
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                  <Form.Group
                    className="extras-tooltip"
                    label={t("bookings.resources.tooltipText")}
                    hint={t("common.remainingCharacters", {
                      count: remainingCharacters(TOOLTIP_MAX_LENGTH, values.extras.tooltipTxt?.length ?? 0),
                    })}
                  >
                    <Field
                      id="extras.tooltipTxt"
                      name="extras.tooltipTxt"
                      placeholder={t("bookings.resources.tooltipTextHint")}
                      maxLength={TOOLTIP_MAX_LENGTH}
                      as={Form.Input}
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                </div>

                <Form.Group>
                  <div className="extras-type-container">
                    <Field
                      as={Form.RadioButton}
                      id="single-option"
                      name="extras.type"
                      disabled={isSubmitting}
                      label={t("bookings.resources.optionToSelect")}
                      checked={values.extras.type !== BookableResourceExtrasType.FreeText}
                      onChange={() => {
                        setFieldValue("extras.type", BookableResourceExtrasType.SingleOption)
                          .then(() => {
                            setFieldValue("extras.options", [defaultOption]).catch(console.error);
                            setFieldValue("extras.mandatory", false).catch(console.error);
                          })
                          .catch(console.error);
                      }}
                    />
                    <Field
                      as={Form.RadioButton}
                      id="free-text"
                      name="extras.type"
                      disabled={isSubmitting}
                      label={t("bookings.resources.freeTextToProvide")}
                      checked={values.extras.type === BookableResourceExtrasType.FreeText}
                      onChange={() => {
                        setFieldValue("extras.type", BookableResourceExtrasType.FreeText)
                          .then(async () => setFieldValue("extras.options", []))
                          .catch(console.error);
                      }}
                    />
                  </div>
                </Form.Group>

                {values.extras.type !== BookableResourceExtrasType.FreeText && (
                  <div className="extras-table-container">
                    <Table.Table className="extras-table">
                      <thead>
                        <tr>
                          <Table.Header
                            style={{ width: "70%" }}
                            label={<Form.Label required>{t("bookings.resources.extrasName")}</Form.Label>}
                          />
                          <Table.Header
                            style={{ width: "12.5%" }}
                            label={<Form.Label>{t("bookings.resources.maxQuantity")}</Form.Label>}
                          />
                          {tierLevel !== DestinationTierLevel.Essentials && (
                            <Table.Header
                              style={{ width: "12.5%" }}
                              label={<Form.Label>{t("bookings.resources.priceEach")}</Form.Label>}
                            />
                          )}
                          {values.extras.options != null && values.extras.options.length > 1 && (
                            <Table.Header style={{ width: "5%" }} label="" />
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {values.extras.options?.map((_option, i) => (
                          <tr key={i}>
                            <td>
                              <Field
                                as={Form.Input}
                                id={`option_name_${i}`}
                                name={`extras.options[${i}].name`}
                                placeholder={t("bookings.resources.extrasNameHint")}
                                maxLength={NAME_MAX_LENGTH}
                                disabled={isSubmitting}
                              />
                            </td>
                            <td>
                              <Field
                                as={Form.Input}
                                type="number"
                                min={1}
                                max={999}
                                id={`option_maxQuantity_${i}`}
                                name={`extras.options[${i}].maxQuantity`}
                                placeholder="--"
                                disabled={isSubmitting}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const maxQuantity = convertInputNumberToNumber(e.target.value);
                                  setFieldValue(`extras.options[${i}].hasQuantity`, (maxQuantity ?? 0) > 0).catch(
                                    console.error,
                                  );
                                  setFieldValue(`extras.options[${i}].maxQuantity`, maxQuantity ?? "").catch(
                                    console.error,
                                  );
                                }}
                              />
                            </td>
                            {tierLevel !== DestinationTierLevel.Essentials && (
                              <td>
                                <Field
                                  as={Form.Money}
                                  id={`option_unitPrice_${i}`}
                                  name={`extras.options[${i}].unitPrice`}
                                  currency={currency ?? ""}
                                  disabled={isSubmitting}
                                />
                              </td>
                            )}
                            {values.extras.options != null && values.extras.options.length > 1 && (
                              <td>
                                <div className="px-3" style={{ textAlign: "center" }}>
                                  <RiCloseLine
                                    size="16"
                                    color={colors.danger}
                                    cursor="pointer"
                                    onClick={() => {
                                      if (values.extras.options?.length === 2) {
                                        setFieldValue("extras.type", BookableResourceExtrasType.SingleOption)
                                          .then(async () => setFieldValue("extras.mandatory", false))
                                          .catch(console.error);
                                      }

                                      const updatedOptions = [
                                        ...(values.extras.options as BookableResourceExtrasOption[]),
                                      ];
                                      setFieldValue("extras.options", [])
                                        .then(async () => {
                                          updatedOptions.splice(i, 1);
                                          return setFieldValue("extras.options", updatedOptions);
                                        })
                                        .catch(console.error);
                                    }}
                                  />
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table.Table>

                    {values.extras.options != null && values.extras.options.length < MAX_OPTIONS_NUMBER && (
                      <div className="add-option-button-container">
                        <Button
                          variant="secondary"
                          size="lg"
                          shape="round"
                          disabled={isSubmitting}
                          onClick={() => {
                            if (values.extras.options?.length === 1) {
                              setFieldValue("extras.type", BookableResourceExtrasType.SingleChoice).catch(
                                console.error,
                              );
                            }

                            const updatedOptions = [
                              ...(values.extras.options as BookableResourceExtrasOption[]),
                              defaultOption,
                            ];
                            setFieldValue("extras.options", updatedOptions).catch(console.error);
                          }}
                        >
                          <RiAddLine size={20} />
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                {values.extras.options != null && values.extras.options.length > 1 && (
                  <Form.Group label={t("bookings.resources.extrasType")}>
                    <div className="extras-type-container">
                      <Field
                        as={Form.ToggleButton}
                        type="button"
                        id="single-choice"
                        name="extras.type"
                        label={t("bookings.resources.singleChoice")}
                        disabled={isSubmitting}
                        value={values.extras.type === BookableResourceExtrasType.SingleChoice}
                        onChange={(state: boolean) => {
                          if (state) {
                            setFieldValue("extras.type", BookableResourceExtrasType.SingleChoice).catch(console.error);
                          }
                        }}
                      />
                      <Field
                        as={Form.ToggleButton}
                        type="button"
                        id="multi-choice"
                        name="extras.type"
                        label={t("bookings.resources.multiChoice")}
                        disabled={isSubmitting}
                        value={values.extras.type === BookableResourceExtrasType.MultiOption}
                        onChange={(state: boolean) => {
                          if (state) {
                            setFieldValue("extras.type", BookableResourceExtrasType.MultiOption).catch(console.error);
                          }
                        }}
                      />
                    </div>
                  </Form.Group>
                )}

                {values.extras.type !== BookableResourceExtrasType.SingleOption && (
                  <div className="extras-type-container">
                    <Field
                      as={Form.Checkbox}
                      name="extras.mandatory"
                      label={t("bookings.resources.makeThisExtraRequired")}
                      disabled={isSubmitting}
                    />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setModalIndex(null);
                  }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  className="add-button"
                  type="submit"
                  variant="primary"
                  onSubmit={() => {
                    void submitForm();
                  }}
                  disabled={isSubmitting || !isValid}
                >
                  {modalIndex != null ? t("common.save") : t("common.add")}
                </Button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </div>
      <style jsx>
        {`
          .extras-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .extras-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .extras-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .extras-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .extras-modal :global(.modal-body p),
          .extras-modal :global(.modal-body .form-group) {
            margin: ${spacers.s0};
          }
          .extras-modal :global(.modal-body .form-group-container) {
            display: flex;
            gap: ${spacers.s7};
          }
          .extras-modal :global(.modal-body .form-group .form-header label) {
            line-height: 14px;
          }
          .extras-modal :global(.modal-body .extras-title p),
          .extras-modal :global(.modal-body .extras-tooltip p) {
            margin: ${spacers.s3} ${spacers.s0};
          }
          .extras-modal :global(.modal-body .extras-type-container) {
            display: flex;
            gap: ${spacers.s5};
          }
          .extras-modal :global(.modal-body .extras-table-container) {
            display: flex;
            flex-direction: column;
            gap: ${spacers.s3};
          }
          .extras-modal :global(.modal-body .extras-table) {
            width: 100%;
            border: 0;
          }
          .extras-modal :global(.modal-body .extras-table label) {
            margin: ${spacers.s0};
          }
          .extras-modal :global(.modal-body .extras-table th),
          .extras-modal :global(.modal-body .extras-table td) {
            padding: ${spacers.s2};
            box-shadow: none;
            border: 0;
          }
          .extras-modal :global(.modal-body .add-option-button-container) {
            align-self: center;
          }
          .extras-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .extras-modal :global(.footer .add-button) {
            width: 200px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .extras-modal :global(.header .title) {
              font-size: 20px;
            }
            .extras-modal :global(.header button) {
              z-index: 2;
            }
            .extras-modal :global(.modal-body) {
              padding: ${spacers.s5};
              gap: ${spacers.s5};
            }
            .extras-modal :global(.modal-body .form-group-container) {
              flex-direction: column;
              gap: ${spacers.s5};
            }
            .extras-modal :global(.modal-body .extras-table th) {
              padding: ${spacers.s3};
            }
            .extras-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
