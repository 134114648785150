import type { PropsWithChildren } from "react";
import React, { useContext, useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import {
  CurrentProfile,
  CurrentRole,
  getReceptionName,
  Role,
  stringIsEmpty,
  SubTopMenu,
  useSaferFormikContext,
  useSiteContext,
} from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Button, ButtonLink, Tabs } from "@equiem/react-admin-ui";
import { RiArrowUpDownLine, RiSettings3Line } from "@equiem/react-admin-ui/icons";

import { Modal } from "../../../contexts/ModalContext";
import { useVisitorReceptionQuery } from "../../../generated/visitors-client";
import type { FormValues } from "../../appointment/types";
import { useReceptionDate } from "../hooks/useReceptionDate";
import { CloseWalkinConfirmationWidget } from "../widgets/CloseWalkinConfirmationWidget";
import { ReceptionsListWidget } from "../widgets/ReceptionsListWidget";

import { NewDropdownButton } from "./NewDropdownButton";

interface Props {
  uuid?: string;
  search: string;
  isAllBuildingsReceptions?: boolean;
}

export const DeskMenu: React.FC<PropsWithChildren<Props>> = ({ isAllBuildingsReceptions, uuid, search, children }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const modal = useContext(Modal);
  const { currentRole } = useContext(CurrentRole);
  const { isReceptionist } = useContext(CurrentProfile);
  const { name } = useSiteContext();
  const { values, dirty, resetForm } = useSaferFormikContext<FormValues>();
  const { pushUrl, replaceUrl } = useReceptionDate();

  const { data: receptionData } = useVisitorReceptionQuery({
    variables: { uuid: uuid as string },
    skip: stringIsEmpty(uuid),
  });

  const isWalkInPage = useMemo(() => {
    return router.query.page === "walk_in";
  }, [router.query]);

  const isAppointmentPage = useMemo(() => {
    return router.query.page === "appointment";
  }, [router.query]);

  const visitorTabLabel = useMemo(() => {
    if (values.visitors.length > 0) {
      return `${values.visitors[0].firstName} ${values.visitors[0].lastName}${
        values.visitors.length > 1 ? ` +${values.visitors.length - 1}` : ""
      }`;
    }

    return undefined;
  }, [values, t]);

  const handleClearWalkIn = () => {
    const url =
      isAllBuildingsReceptions === true
        ? "/visitor-management/building-receptions/pre_booked"
        : `/visitor-management/receptions/${uuid}/pre_booked`;

    void pushUrl(url).then(() => {
      resetForm();
    });
  };

  const isManager = currentRole === Role.PropertyManager || currentRole === Role.WorkplaceManager;
  const isPropertyManager = currentRole === Role.PropertyManager;

  const handleSelectReception = () => {
    modal.open("ReceptionList");
  };

  const isDirtyWalkIn = dirty && values.isWalkIn;

  const tabShown = isWalkInPage || isDirtyWalkIn || isAppointmentPage;

  const tabs = useMemo<TabItem[]>(() => {
    const _tabs = [
      {
        title: t("visitors.reception.statusPrebooked"),
        key:
          isAllBuildingsReceptions === true
            ? "/visitor-management/building-receptions/pre_booked"
            : `/visitor-management/receptions/${uuid}/pre_booked`,
        disabled: search.length > 0,
      },
      {
        title: t("visitors.reception.statusCheckedIn"),
        key:
          isAllBuildingsReceptions === true
            ? "/visitor-management/building-receptions/checked_in"
            : `/visitor-management/receptions/${uuid}/checked_in`,
        disabled: search.length > 0,
      },
      {
        title: t("visitors.reception.statusCheckedOut"),
        key:
          isAllBuildingsReceptions === true
            ? "/visitor-management/building-receptions/checked_out"
            : `/visitor-management/receptions/${uuid}/checked_out`,
        disabled: search.length > 0,
      },
    ];

    if (isWalkInPage || isDirtyWalkIn) {
      const closeableTab: TabItem = {
        title: visitorTabLabel ?? t("visitors.walkIn.newWalkIn"),
        key:
          isAllBuildingsReceptions === true
            ? "/visitor-management/building-receptions/walk_in"
            : `/visitor-management/receptions/${uuid}/walk_in`,
        onClose: () => modal.open("CloseWalkin"),
      };

      return [..._tabs, closeableTab];
    }

    return _tabs;
  }, [t, uuid, isAllBuildingsReceptions, search.length, isWalkInPage, isDirtyWalkIn, visitorTabLabel, modal]);

  const handleTabSelect = (key: string) => {
    void replaceUrl(key);
  };

  const pageTitle = useMemo(() => {
    return receptionData != null && isAllBuildingsReceptions !== true
      ? getReceptionName(receptionData.visitorReception, t)
      : t("visitors.receptions.allVisitorsForSiteName", { siteName: name });
  }, [t, receptionData?.visitorReception, isAllBuildingsReceptions, name]);

  return (
    <>
      <SubTopMenu className="title-container mb-0">
        <div className="d-flex">
          <h1 style={{ fontWeight: "700" }}>{pageTitle}</h1>
          {isReceptionist && (
            <Button variant="ghost" size="sm" className="reception-button ml-2" onClick={handleSelectReception} round>
              <RiArrowUpDownLine size={16} />
            </Button>
          )}
        </div>
        {isManager && (
          <div className="actions d-flex">
            <Link href="/visitor-management" passHref legacyBehavior>
              <ButtonLink variant="outline" className="mr-3" size="md">
                {t("visitors.reception.appointmentsView")}
              </ButtonLink>
            </Link>
            <Link
              href={
                isPropertyManager ? "/visitor-management/settings/general" : "/visitor-management/settings/receptions"
              }
              passHref
              legacyBehavior
            >
              <ButtonLink variant="ghost" size="md">
                <RiSettings3Line size={16} />
                {t("common.settings")}
              </ButtonLink>
            </Link>
          </div>
        )}
      </SubTopMenu>
      <SubTopMenu className="menu-container py-0" sticky>
        <div className=" d-flex align-items-center">
          <div className="d-flex menu">
            <Tabs
              items={tabs}
              selected={router.asPath.split("?")[0]}
              tabLinks
              onSelect={handleTabSelect}
              actionComponent={<NewDropdownButton appointmentDisabled={tabShown} walkinDisabled={tabShown} />}
            />
          </div>
        </div>
        {children}
      </SubTopMenu>
      <ReceptionsListWidget />
      <CloseWalkinConfirmationWidget handleClearWalkIn={handleClearWalkIn} />
      <style jsx>
        {`
          :global(.menu-container) {
            height: 48px;
            min-height: unset !important;
          }
        `}
      </style>
    </>
  );
};
