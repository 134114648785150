/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

import { notNullOrUndefined, stringNotEmpty, useBcUuidContext, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useDebounced, useTheme, useToast } from "@equiem/react-admin-ui";

import { PrintedPass } from "../../../components/PrintedPass";
import { withContexts } from "../../../contexts/withContexts";
import type { Visitor, VisitorsByAllBuildingReceptionsQuery, VisitorStatus } from "../../../generated/visitors-client";
import {
  useBarrierControlConfigCodeTypeLazyQuery,
  useBarrierControlConfigPrefixLazyQuery,
  useCurrentDestinationBuildingsQuery,
  useVisitorAppointmentByAllBuildingsExportCsvMutation,
  useVisitorReceptionLazyQuery,
  useVisitorsByAllBuildingReceptionsQuery,
  useVisitorsUpdatedByAllBuildingReceptionsQuery,
} from "../../../generated/visitors-client";
import { DeskMenu } from "../components/DeskMenu";
import { DeskMenuBar } from "../components/DeskMenuBar";
import { InfiniteScrollAppointmentTable } from "../components/InfinitScrollAppointmentTable";
import { useReceptionDate } from "../hooks/useReceptionDate";
import { ReceptionContext } from "../ReceptionContext";

import { AppointmentsTable } from "./components/AppointmentsTable";

interface Props {
  visitorStatus: "PRE_BOOKED" | "CHECKED_IN" | "CHECKED_OUT";
}

export const ReceptionBuildingDeskBase: React.FC<Props> = ({ visitorStatus }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { colors } = useTheme(true);
  const [search, setSearch] = useState("");
  const { date, setDate } = useReceptionDate();
  const {
    printingAppointment,
    reception,
    printingVisitors,
    setPrintingAppointment,
    setPrintingVisitors,
    setReception,
  } = useContext(ReceptionContext);
  const { updateBcUuid } = useBcUuidContext();
  const { uuid, name } = useSiteContext();
  const debounceTimeout = 500;
  const debouncedSearch = useDebounced(search, debounceTimeout);
  const printedPassRef = useRef(null);
  const toaster = useToast();

  const {
    data: visitorsData,
    refetch: refetchAppointments,
    stopPolling,
    fetchMore,
    loading,
    updateQuery,
  } = useVisitorsByAllBuildingReceptionsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      siteUuid: uuid,
      beginDate: date.startOf("day").toMillis(),
      endDate: date.endOf("day").toMillis(),
      search: debouncedSearch.length > 0 ? debouncedSearch : undefined,
      visitorStatus: debouncedSearch.length > 0 ? undefined : (visitorStatus as VisitorStatus),
      first: 25,
    },
  });
  const { data: buildingsData } = useCurrentDestinationBuildingsQuery();

  const [fetchVisitorReception, { data: receptionData }] = useVisitorReceptionLazyQuery();
  const [fetchCodeType, { data: codeType }] = useBarrierControlConfigCodeTypeLazyQuery();
  const [fetchPrefix, { data: prefix }] = useBarrierControlConfigPrefixLazyQuery();

  const handleRefetchAppointments: typeof refetchAppointments = async (...args) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return refetchAppointments(...args);
  };
  const [exportAppointmentsCsv, { loading: reportLoading }] = useVisitorAppointmentByAllBuildingsExportCsvMutation({
    variables: {
      input: {
        siteUuid: uuid,
        beginDate: date.startOf("day").toMillis(),
        endDate: date.endOf("day").toMillis(),
        receptionUuid: reception?.uuid,
      },
    },
  });

  const visitors =
    visitorsData?.visitorsByAllBuildingReceptions.edges.map((e) => e.node).filter(notNullOrUndefined) ?? [];
  const maxUpdated =
    visitorsData?.visitorsByAllBuildingReceptions.maxUpdated == null && visitors.length === 0
      ? undefined
      : Math.max(visitorsData?.visitorsByAllBuildingReceptions.maxUpdated ?? 0, ...visitors.map((v) => v.updated));

  const { data: updatedVisitorsData } = useVisitorsUpdatedByAllBuildingReceptionsQuery({
    pollInterval: 30_000,
    fetchPolicy: "network-only",
    variables: {
      siteUuid: uuid,
      beginDate: date.startOf("day").toMillis(),
      endDate: date.endOf("day").toMillis(),
      visitorStatus: visitorStatus as VisitorStatus,
      updatedSince: maxUpdated,
    },
    skip: stringNotEmpty(debouncedSearch),
  });

  const newCount = updatedVisitorsData?.visitorsByAllBuildingReceptions.totalCount ?? 0;

  const building = useMemo(() => {
    const buildings = buildingsData?.viewer.currentDestination?.destination.buildings ?? [];
    const currentBuilding = buildings.find((b) => b.uuid === receptionData?.visitorReception.building?.uuid);

    return currentBuilding;
  }, [buildingsData, receptionData]);

  /* istanbul ignore next */
  const handlePassPrint = useReactToPrint({
    content: () => printedPassRef.current,
    onAfterPrint: () => {
      setPrintingAppointment?.(null);
      setPrintingVisitors?.([]);
      setReception?.(undefined);
    },
  });

  useEffect(() => {
    updateBcUuid("building-receptions", t("visitors.receptions.allVisitorsForSiteName", { siteName: name }));
  }, [t, updateBcUuid, name]);

  useEffect(() => {
    if (
      (printingAppointment != null || (printingVisitors?.length ?? 0) > 0) &&
      building != null &&
      Boolean(receptionData?.visitorReception.enablePassPrinting)
    ) {
      setTimeout(() => {
        handlePassPrint();
      }, 100);
    }
  }, [printingAppointment, printingVisitors, building, handlePassPrint, receptionData]);

  useEffect(() => {
    if (reception?.uuid != null) {
      void fetchVisitorReception({
        variables: { uuid: reception.uuid as string },
      });
    }
  }, [reception]);

  useEffect(() => {
    if (receptionData?.visitorReception.building?.uuid != null) {
      void fetchCodeType({
        variables: {
          buildingUuid: receptionData.visitorReception.building.uuid as string,
        },
      });
      void fetchPrefix({
        variables: {
          buildingUuid: receptionData.visitorReception.building.uuid as string,
        },
      });
    }
  }, [receptionData?.visitorReception.building?.uuid, fetchCodeType, fetchPrefix]);

  useEffect(() => {
    setSearch("");
  }, [router.query]);

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  const exportCsv = () => {
    exportAppointmentsCsv()
      .then(() => {
        toaster.positive(t("visitors.common.csvExportSuccess"), { autoDismiss: true });
      })
      .catch(console.error);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    void setDate(DateTime.fromISO(e.target.value));
  };

  const hasMoreAppointments = visitorsData?.visitorsByAllBuildingReceptions.pageInfo.hasNextPage ?? false;

  const handleNextPage = async () =>
    fetchMore({
      variables: {
        after: visitorsData?.visitorsByAllBuildingReceptions.pageInfo.endCursor,
      },
    });

  const updateEdges = useCallback(
    (
      cb: (
        edges: VisitorsByAllBuildingReceptionsQuery["visitorsByAllBuildingReceptions"]["edges"],
      ) => VisitorsByAllBuildingReceptionsQuery["visitorsByAllBuildingReceptions"]["edges"],
    ) => {
      updateQuery((existing) => ({
        ...existing,
        visitorsByAllBuildingReceptions: {
          ...existing.visitorsByAllBuildingReceptions,
          edges: cb(existing.visitorsByAllBuildingReceptions.edges),
        },
      }));
      void refetchAppointments();
    },
    [updateQuery],
  );

  return (
    <div className="reception d-flex flex-column">
      <DeskMenu isAllBuildingsReceptions={true} search={search}>
        <DeskMenuBar
          handleDateChange={handleDateChange}
          onClearSearch={() => setSearch("")}
          handleRefetchAppointments={async () => handleRefetchAppointments()}
          reportLoading={reportLoading}
          exportCsv={exportCsv}
          handleSearch={handleSearch}
          search={search}
          date={date}
          loading={loading}
          newCount={newCount}
        />
      </DeskMenu>
      <InfiniteScrollAppointmentTable
        handleNextPage={handleNextPage}
        date={date}
        loading={loading}
        visitors={visitors as Visitor[]}
        hasMoreAppointments={hasMoreAppointments}
      >
        <AppointmentsTable
          visitors={visitors}
          receptionType="building"
          updateEdges={updateEdges}
          visitorsLoading={loading}
        />
      </InfiniteScrollAppointmentTable>

      {printingAppointment != null && building != null && receptionData != null && (
        <div className="pass-printing">
          <PrintedPass
            ref={printedPassRef}
            passes={printingAppointment.visitors.map((visitor) => ({
              visitor: {
                uuid: visitor.uuid,
                codeValue: (prefix?.barrierControlConfigPrefix ?? "") + visitor.code,
                fullName: `${visitor.firstName} ${visitor.lastName}`,
                companyName: visitor.companyName,
              },
              host: {
                fullName: `${printingAppointment.host.firstName} ${printingAppointment.host.lastName}`,
                companyName: printingAppointment.host.company?.name,
              },
              startDate: printingAppointment.startTime,
              endDate: printingAppointment.endTime,
              codeType: codeType?.barrierControlConfigCodeType.type,
            }))}
            marginTop={building.visitorManagement?.passPrintingConfiguration?.marginTop}
          />
        </div>
      )}
      {printingVisitors != null && building != null && receptionData != null && printingVisitors.length > 0 && (
        <div className="pass-printing">
          <PrintedPass
            ref={printedPassRef}
            passes={printingVisitors.map((printingVisitor) => ({
              startDate: printingVisitor.appointment.startTime,
              endDate: printingVisitor.appointment.endTime,
              visitor: {
                uuid: printingVisitor.uuid,
                fullName: `${printingVisitor.firstName} ${printingVisitor.lastName}`,
                companyName: printingVisitor.companyName,
                codeValue: (prefix?.barrierControlConfigPrefix ?? "") + printingVisitor.code,
              },
              host: {
                fullName: `${printingVisitor.appointment.host.firstName} ${printingVisitor.appointment.host.lastName}`,
                companyName: printingVisitor.appointment.host.company?.name,
              },
              codeType: codeType?.barrierControlConfigCodeType.type,
            }))}
            marginTop={building.visitorManagement?.passPrintingConfiguration?.marginTop}
          />
        </div>
      )}

      <style jsx>
        {`
          .pass-printing {
            display: none;
          }
          .reception {
            flex-grow: 1;
            background: ${colors.white};
            min-height: 100%;
          }
          :global(.search-container) {
            width: 320px !important;
          }
          :global(.date) {
            width: 140px !important;
          }
          .filters {
            gap: 8px;
            position: relative;
            align-items: center;
          }
          .spin {
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export const ReceptionBuildingDesk = withContexts(ReceptionBuildingDeskBase);
