import { useTranslation } from "@equiem/localisation-eq1";
import { AccordionList, AccordionListItem } from "@equiem/react-admin-ui";
import React, { useContext, useMemo } from "react";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";

export const BookingDiscountsDisplay: React.FC = () => {
  const { t } = useTranslation();
  const { resource } = useContext(BookingModalInfo);

  const hasDiscounts = resource.discounts.length > 0;
  const hasMultipleDiscounts = resource.discounts.length > 1;

  const discountList = resource.discounts.map(({ percentage, availability }) => {
    if (availability.__typename === "BookingDiscountPeriodicAvailability") {
      return <></>;
    }

    return (
      <>
        <strong>{t("bookings.discounts.saveDiscount", { percentage })}</strong> -{" "}
        {t("bookings.discounts.bookDaysInAdvance", {
          days:
            availability.__typename === "BookingDiscountEarlyBookingAvailability"
              ? availability.daysBeforeBookingToApply
              : 0,
        })}
      </>
    );
  });

  const headerText = useMemo(() => {
    const totalDiscountPercentage = resource.discounts.reduce((sum, { percentage }) => sum + percentage, 0);
    const percentage = totalDiscountPercentage > 100 ? 100 : totalDiscountPercentage;

    return hasMultipleDiscounts ? (
      <strong>{t("bookings.discounts.saveUptoDiscount", { percentage })}</strong>
    ) : (
      discountList[0]
    );
  }, [resource, hasMultipleDiscounts, t, discountList]);

  return (
    <>
      {hasDiscounts && (
        <AccordionList headerText={headerText} showCollapseButton={hasMultipleDiscounts} showCollapsed={false}>
          {discountList.map((discountDisplay, i) => (
            <AccordionListItem key={i}>{discountDisplay}</AccordionListItem>
          ))}
        </AccordionList>
      )}
    </>
  );
};
