import React, { useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useRouter } from "next/router";

import { useTranslation } from "@equiem/localisation-eq1";
import { ConfirmerProvider } from "@equiem/react-admin-ui";

import { PrintedPass } from "../../components/PrintedPass";
import type { VisitorAppointmentFragment, VisitorForReceptionFragment } from "../../generated/visitors-client";
import {
  useBarrierControlConfigCodeTypeLazyQuery,
  useBarrierControlConfigPrefixLazyQuery,
  useCurrentDestinationBuildingsQuery,
  useVisitorReceptionQuery,
} from "../../generated/visitors-client";
import { initialValues } from "../appointment/initialValues";
import { getValidationSchema } from "../appointment/utils/validationSchema";

import { useAppointmentSubmitCallback } from "./hooks/useAppointmentSubmitCallback";
import { AppointmentPage } from "./Appointment";
import { ReceptionDesk } from "./Desk";
import { ReceptionProvider } from "./ReceptionContext";
import { WalkIn } from "./WalkIn";

interface Props {
  uuid: string;
}

export const Reception: React.FC<Props> = ({ uuid }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { page, appointmentUuid } = router.query;
  const { data: buildingsData } = useCurrentDestinationBuildingsQuery();
  const [fetchCodeType, { data: codeType }] = useBarrierControlConfigCodeTypeLazyQuery();
  const [fetchPrefix, { data: prefix }] = useBarrierControlConfigPrefixLazyQuery();
  const { data: receptionData } = useVisitorReceptionQuery({
    variables: { uuid },
  });
  const [isWalkIn, setIsWalkIn] = useState(false);

  const [printingAppointment, setPrintingAppointment] = useState<VisitorAppointmentFragment | null>(null);
  const [printingVisitors, setPrintingVisitors] = useState<VisitorForReceptionFragment[]>([]);
  const printedPassRef = useRef(null);

  /* istanbul ignore next */
  const handlePassPrint = useReactToPrint({
    content: () => printedPassRef.current,
    onAfterPrint: () => {
      setPrintingAppointment(null);
      setPrintingVisitors([]);
    },
  });

  const building = useMemo(() => {
    const buildings = buildingsData?.viewer.currentDestination?.destination.buildings ?? [];
    return buildings.find((b) => b.uuid === receptionData?.visitorReception.building?.uuid);
  }, [buildingsData, receptionData]);

  const settings = useMemo(
    () => ({
      enableHoldInLobby: receptionData?.visitorReception.enableHoldInLobby === true,
      enablePassPrinting: receptionData?.visitorReception.enablePassPrinting === true,
      enableAutomaticPassPrinting: receptionData?.visitorReception.enableAutomaticPassPrinting === true,
      setPrintingAppointment,
      setPrintingVisitors,
    }),
    [receptionData, setPrintingAppointment, setPrintingVisitors],
  );

  useEffect(() => {
    if (building != null) {
      void fetchCodeType({
        variables: {
          buildingUuid: building.uuid,
        },
      });
      void fetchPrefix({
        variables: {
          buildingUuid: building.uuid,
        },
      });
    }
  }, [building, fetchCodeType, fetchPrefix]);

  useEffect(() => {
    if ((printingAppointment != null || printingVisitors.length > 0) && settings.enablePassPrinting) {
      // timeout to render pass properly
      /* istanbul ignore next */
      setTimeout(() => {
        handlePassPrint();
      }, 100);
    }
  }, [printingAppointment, printingVisitors, handlePassPrint, settings]);

  const handleSubmit = useAppointmentSubmitCallback({
    appointmentUuid: appointmentUuid as string,
    setPrintingAppointment,
    receptonUuid: uuid,
    isAllBuildingReceptions: false,
    settings,
  });

  const renderActivePage = () => {
    switch (page) {
      case "walk_in":
        return <WalkIn uuid={uuid} />;
      case "appointment":
        return (
          <AppointmentPage
            isReceptionView
            receptionUuid={uuid}
            appointmentUuid={typeof appointmentUuid === "string" ? appointmentUuid : undefined}
          />
        );
      case "checked_in":
        return <ReceptionDesk uuid={uuid} visitorStatus="CHECKED_IN" />;
      case "checked_out":
        return <ReceptionDesk uuid={uuid} visitorStatus="CHECKED_OUT" />;
      default:
        return <ReceptionDesk uuid={uuid} visitorStatus="PRE_BOOKED" />;
    }
  };

  return (
    <ReceptionProvider {...settings} isWalkIn setIsWalkIn={setIsWalkIn}>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(DateTime.local().zoneName, t, isWalkIn)}
        onSubmit={handleSubmit}
      >
        <ConfirmerProvider>{renderActivePage()}</ConfirmerProvider>
      </Formik>
      {printingAppointment != null && (
        <div className="pass-printing">
          <PrintedPass
            ref={printedPassRef}
            passes={printingAppointment.visitors.map((visitor) => ({
              visitor: {
                uuid: visitor.uuid,
                codeValue: (prefix?.barrierControlConfigPrefix ?? "") + visitor.code,
                fullName: `${visitor.firstName} ${visitor.lastName}`,
                companyName: visitor.companyName,
              },
              host: {
                fullName: `${printingAppointment.host.firstName} ${printingAppointment.host.lastName}`,
                companyName: printingAppointment.host.company?.name,
              },
              startDate: printingAppointment.startTime,
              endDate: printingAppointment.endTime,
              codeType: codeType?.barrierControlConfigCodeType.type,
            }))}
            marginTop={building?.visitorManagement?.passPrintingConfiguration?.marginTop}
          />
        </div>
      )}
      {printingVisitors.length > 0 && (
        <div className="pass-printing">
          <PrintedPass
            ref={printedPassRef}
            passes={printingVisitors.map((printingVisitor) => ({
              startDate: printingVisitor.appointment.startTime,
              endDate: printingVisitor.appointment.endTime,
              visitor: {
                uuid: printingVisitor.uuid,
                fullName: `${printingVisitor.firstName} ${printingVisitor.lastName}`,
                companyName: printingVisitor.companyName,
                codeValue: (prefix?.barrierControlConfigPrefix ?? "") + printingVisitor.code,
              },
              host: {
                fullName: `${printingVisitor.appointment.host.firstName} ${printingVisitor.appointment.host.lastName}`,
                companyName: printingVisitor.appointment.host.company?.name,
              },
              codeType: codeType?.barrierControlConfigCodeType.type,
            }))}
            marginTop={building?.visitorManagement?.passPrintingConfiguration?.marginTop}
          />
        </div>
      )}
      <style jsx>
        {`
          .pass-printing {
            display: none;
          }
        `}
      </style>
    </ReceptionProvider>
  );
};
