import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal, useTheme, Form as EqForm, Button, Alert, remainingCharacters } from "@equiem/react-admin-ui";
import { BookingModal } from "../../operations/contexts/BookingModalContext";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FormikErrors } from "formik";
import { Field, Formik, Form as FormikForm } from "formik";
import { BookingDiscountType } from "../../../generated/gateway-client";
import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import { getEndTime, getStartTime } from "../../settings/components/blockout/helpers/validateAddForm";
import { ResourceSelector } from "../../../components/ResourceSelector";
import type { FormValues } from "../util/BookingDiscountFormValues";
import { BookingDiscountAddEditContext, ViewMode } from "./BookingDiscountAddEditProvider";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

const maxTitleLength = 50;

export const validateAddForm =
  (t: TFunction, timezone: string) =>
  (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};
    if (!stringNotEmpty(values.title)) {
      errors.title = t("bookings.settings.pleaseFillTheTitle");
    }
    if (!stringNotEmpty(values.startDate)) {
      errors.startDate = t("bookings.settings.pleaseSelectADate");
    }
    if (!stringNotEmpty(values.endDate)) {
      errors.endDate = t("bookings.settings.pleaseSelectADate");
    }
    if (stringNotEmpty(values.startDate) && stringNotEmpty(values.endDate)) {
      const start = getStartTime(timezone, values.startDate, "");
      const end = getEndTime(timezone, values.endDate, "");

      if (!start.isValid) {
        errors.startDate = t("bookings.settings.pleaseEnterAValidDate");
      }
      if (!end.isValid) {
        errors.endDate = t("bookings.settings.pleaseEnterAValidDate");
      }
      if (start.isValid && end.isValid && start >= end) {
        errors.startDate = t("bookings.settings.startShouldBeBeforeEndDateAndTime");
      }
    }
    if (values.availability.daysBeforeBookingToApply == null) {
      errors.availability = {
        daysBeforeBookingToApply: t("bookings.discounts.numberOfDaysFieldError"),
      };
    }
    if (values.percentage == null || !stringNotEmpty(values.percentage.toString())) {
      errors.percentage = t("bookings.discounts.percentageFieldError");
    }
    if (values.resources.length === 0) {
      errors.resources = t("bookings.settings.pleaseSelectAResource");
    }

    return errors;
  };

export const CreateUpdateBookingDiscountForm: React.FC = () => {
  const modal = useContext(BookingModal);
  const { t } = useTranslation();
  const { spacers, colors } = useTheme(true);
  const { timezone } = useSiteContext();
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
  const { createOrUpdate, value, viewMode } = useContext(BookingDiscountAddEditContext);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    modal.setTitle(
      viewMode === ViewMode.New ? t("bookings.discounts.addNewDiscount") : t("bookings.discounts.editDiscount"),
    );
    modal.setShowConfirmationBeforeClose(dirty);
    modal.setConfirmationTextBeforeClose(t("bookings.discounts.formCloseWarning"));
  }, [modal, t, viewMode, dirty]);

  const isErrorDisplayed = useCallback(
    (errorCheck: string | undefined) => (hasClickedSubmit ? errorCheck : undefined),
    [hasClickedSubmit],
  );

  const closeModal = useCallback(() => {
    modal.close();
  }, [modal]);

  return (
    <Formik
      initialValues={value}
      validate={validateAddForm(t, timezone)}
      onSubmit={createOrUpdate}
      innerRef={(formikActions) => setDirty(formikActions?.dirty ?? false)}
    >
      {({ isSubmitting, values, submitForm, setFieldValue, errors }) => (
        <>
          <Modal.Body>
            <FormikForm>
              <div>
                {viewMode === ViewMode.Edit && (
                  <Alert
                    size="large"
                    variant="gray"
                    icon={<RiErrorWarningLine size={18} color={colors.grayscale[60]} />}
                    message={
                      <span className="edit-discount-helptext">{t("bookings.discounts.editDiscountHelpText")}</span>
                    }
                    className="mb-5"
                  />
                )}
                <EqForm.Group>
                  <div className="availability-type-container">
                    {/* @TODO: Enable this code block when Periodic bookings are worked on */}
                    {/* <Field
                      label={t("bookings.discounts.periodic")}
                      className="availability-type-radio w-50"
                      component={EqForm.EnclosedRadioButton}
                      id={BookingDiscountType.Periodic}
                      name="availabilityType"
                      value={values.type === BookingDiscountType.Periodic}
                      description={t("bookings.discounts.periodicBookingHelpText")}
                      onClick={async () => {
                        await setFieldValue("availabilityType", BookingDiscountType.Periodic);
                      }}
                    ></Field> */}
                    <Field
                      label={t("bookings.discounts.earlyBooking")}
                      className="availability-type-radio w-100"
                      component={EqForm.EnclosedRadioButton}
                      id={BookingDiscountType.EarlyBooking}
                      value={values.type === BookingDiscountType.EarlyBooking}
                      name="availabilityType"
                      description={t("bookings.discounts.earlyBookingHelpText")}
                      onClick={async () => {
                        await setFieldValue("availabilityType", BookingDiscountType.EarlyBooking);
                      }}
                    ></Field>
                  </div>
                </EqForm.Group>
                <EqForm.Group
                  label={t("bookings.discounts.titleField")}
                  required
                  error={isErrorDisplayed(errors.title)}
                  hint={t("common.remainingCharacters", {
                    count: remainingCharacters(maxTitleLength, values.title.length),
                  })}
                  tooltipText={t("bookings.discounts.titleFieldHelpText")}
                  showTooltip={true}
                >
                  <Field
                    id="title"
                    name="title"
                    placeholder={t("bookings.discounts.titleFieldPlaceholder")}
                    as={EqForm.Input}
                    maxLength={maxTitleLength}
                  />
                </EqForm.Group>
                <div className="form-group-container">
                  <EqForm.Group
                    required
                    error={isErrorDisplayed(errors.startDate)}
                    label={t("bookings.resources.startDate")}
                  >
                    <Field id="startDate" name="startDate" as={EqForm.Input} type="date" max="9999-12-31" />
                  </EqForm.Group>
                  <EqForm.Group
                    required
                    error={isErrorDisplayed(errors.endDate)}
                    label={t("bookings.resources.endDate")}
                  >
                    <Field id="endDate" name="endDate" as={EqForm.Input} type="date" max="9999-12-31" />
                  </EqForm.Group>
                </div>
                <EqForm.Group
                  required
                  error={isErrorDisplayed(errors.availability?.daysBeforeBookingToApply)}
                  label={t("bookings.discounts.numberOfDaysField")}
                >
                  <Field
                    id="availability.daysBeforeBookingToApply"
                    name="availability.daysBeforeBookingToApply"
                    placeholder={t("bookings.discounts.numberOfDaysFieldPlaceholder")}
                    as={EqForm.Input}
                    min={1}
                    max={365}
                    type="number"
                  />
                </EqForm.Group>
                <EqForm.Group
                  error={isErrorDisplayed(errors.percentage)}
                  required
                  label={t("bookings.discounts.percentageField")}
                >
                  <Field
                    id="percentage"
                    name="percentage"
                    placeholder={t("bookings.discounts.percentageFieldPlaceholder")}
                    as={EqForm.Input}
                    min={1}
                    max={100}
                    type="number"
                  />
                </EqForm.Group>
                <div className="resource-picker">
                  <ResourceSelector
                    fieldName="resources"
                    showErrorCheck={isErrorDisplayed(errors.resources?.[0]) != null}
                  />
                </div>
              </div>
            </FormikForm>
          </Modal.Body>
          <Modal.Footer>
            <div className="discount-buttons w-100">
              <div className="buttons-group">
                <Button className="w-100" variant="outline" onClick={closeModal}>
                  {t("common.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-100"
                  onClick={() => {
                    setHasClickedSubmit(true);
                    submitForm().catch((e) => {
                      console.error(e);
                    });
                  }}
                >
                  {t("common.save")}
                </Button>
              </div>
            </div>
          </Modal.Footer>
          <style jsx>{`
            .availability-type-container {
              display: flex;
              gap: ${spacers.s3};
            }
            .availability-type-radio {
              flex: 1;
            }
            .availability-type-radio :global(label) {
              width: 100%;
              height: 100%;
            }
            .availability-type-container :global(h1) {
              text-overflow: ellipsis;
              text-wrap: wrap;
            }
            .availability-type-container :global(p) {
              text-wrap: wrap;
            }
            .discount-buttons :global(button) {
              width: 40%;
            }
            .discount-buttons .buttons-group {
              display: flex;
              gap: 8px;
            }
            .form-group-container {
              display: flex;
              gap: ${spacers.s5};
            }
            .resource-picker :global(.tags-container span) {
              font-size: 12px;
            }
            .resource-picker :global(.tags-container .placeholder-text) {
              font-size: 14px;
            }
            .edit-discount-helptext {
              color: ${colors.grayscale[60]};
            }
          `}</style>
        </>
      )}
    </Formik>
  );
};
