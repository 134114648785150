import { useCallback } from "react";
import { useRouter } from "next/router";
import { useToast } from "@equiem/react-admin-ui";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";

import {
  type BookingFragmentFragment,
  BookingStatus,
  useCreateVisitorAppointmentForBookingMutation,
} from "../../../generated/gateway-client";
import { formatBookingStatusLocalised } from "../../reports/utils/formatBookingStatusLocalised";

export const editAppointmentRoute = (uuid: string) => `/visitor-management/edit-appointment/${uuid}`;

export const useLazyBookingAppointment = () => {
  const router = useRouter();
  const toast = useToast();
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const [createAppointmentForBooking] = useCreateVisitorAppointmentForBookingMutation();

  const goToAppointment = useCallback(
    async (booking: BookingFragmentFragment) => {
      try {
        if (booking.status !== BookingStatus.Approved) {
          toast.neutral(
            t("bookings.visitors.appointmentCreateInvalidBookingStatus", {
              status: formatBookingStatusLocalised(booking.status, t),
            }),
          );
        }

        if (booking.appointment != null) {
          await router.push(editAppointmentRoute(booking.appointment.uuid));
          return;
        }

        const { data, errors } = await createAppointmentForBooking({ variables: { bookingUuid: booking.uuid } });
        if (data?.createVisitorAppointmentForBooking == null || errors != null) {
          toast.negative(t("bookings.visitors.appointmentCreateError"));
          return;
        }

        await router.push(editAppointmentRoute(data.createVisitorAppointmentForBooking.uuid));
      } catch (e: unknown) {
        console.error(e);
        toast.negative(tError(e));
      }
    },
    [createAppointmentForBooking, router, toast, t, tError],
  );

  return { goToAppointment };
};
