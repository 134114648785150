import React, { createContext } from "react";
import type { FC, PropsWithChildren } from "react";
import type { PageProps } from "../PageProps";

export const PageContext = createContext<PageProps>({
  isInMobileLayout: false,
  isInWebNgLayout: false,
  appLoadedByDefaultUrl: false,
  appLoadedByLegacyUrl: false,
  hasAuthCookie: false,
  usingPostMessageAuth: false,
});

export const PageContextProvider: FC<PropsWithChildren<PageProps>> = ({ children, ...ctx }) => (
  <PageContext.Provider value={{ ...ctx }}>{children}</PageContext.Provider>
);
