import React, { useCallback, useContext } from "react";
import { Dropdown, useTheme } from "@equiem/react-admin-ui";
import { RiMoreLine, RiEyeLine, RiEditLine, RiDeleteBinLine, RiHistoryLine } from "@equiem/react-admin-ui/icons";
import type { BookingDiscount } from "../util/BookingDiscount";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingDiscountAddEditContext } from "./BookingDiscountAddEditProvider";

export interface Props {
  discount: BookingDiscount;
}
export const BookingDiscountActions: React.FC<Props> = ({ discount }) => {
  const theme = useTheme(true);
  const { t } = useTranslation();
  const { edit, viewDiscount, deleteDiscount, viewHistory, canUpdateOrDeleteDiscount } =
    useContext(BookingDiscountAddEditContext);

  const onEdit = useCallback(() => {
    if (discount.availability.__typename !== "BookingDiscountEarlyBookingAvailability") {
      return;
    }

    edit({
      availability: {
        daysBeforeBookingToApply: discount.availability.daysBeforeBookingToApply,
      },
      endDate: discount.availability.endDate === "infinity" ? "" : discount.availability.endDate,
      percentage: discount.percentage,
      resources: discount.resources.map((resource) => resource.uuid),
      startDate: discount.availability.startDate,
      title: discount.title,
      type: discount.type,
      uuid: discount.uuid,
    });
  }, [edit, discount]);

  return (
    <div className="d-flex justify-content-end align-items-center action-menu-cont">
      <Dropdown.Icon placement="left-start" size="sm" icon={RiMoreLine} className="action-menu">
        <Dropdown.Item onClick={() => viewDiscount(discount)}>
          <RiEyeLine size="16" color={theme.colors.primary} className="mr-3" />
          {t("bookings.discounts.details")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => viewHistory(discount)}>
          <RiHistoryLine size="16" color={theme.colors.primary} className="mr-3" />
          {t("bookings.discounts.history")}
        </Dropdown.Item>
        {canUpdateOrDeleteDiscount(discount) && (
          <>
            <Dropdown.Item onClick={onEdit}>
              <RiEditLine size="16" color={theme.colors.primary} className="mr-3" />
              {t("common.edit")}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => deleteDiscount(discount)}>
              <RiDeleteBinLine size="16" color={theme.colors.danger} className="mr-3" />
              {t("common.delete")}
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Icon>
    </div>
  );
};
