import "../styles/styles.scss";

import React, { useEffect, useState, useCallback, useMemo } from "react";
import type { AppContext, AppProps } from "next/app";
import Head from "next/head";
import type { PageProps, AuthenticationContext, TransformedSite } from "@equiem/lib";
import {
  getHostInfo,
  SessionProvider,
  ProfileProvider,
  GradientProvider,
  BreadcrumbsUuidProvider,
  SideMenuProvider,
  AppointmentsMenuProvider,
  IframelyProvider,
  stringNotEmpty,
  Site,
  useCachedLocalePreference,
  UrlParamsProvider,
} from "@equiem/lib";
import { setAuthSetter } from "@equiem/lib/clients/gatewayUtils";
import { getGlobalGateway, getGlobalUnauthenticatedGateway } from "@equiem/lib/clients/globalGateway";
import { AdminTheme, ToastProvider, ConfirmerProvider, ProgressCircle } from "@equiem/react-admin-ui";
import { TranslationProvider } from "@equiem/localisation";
import isEqual from "lodash.isequal";

import { BreadcrumbsProvider } from "../contexts/BreadcrumbsProvider";
import { SiteProvider } from "../contexts/SiteProvider";
import { UsetifulProvider } from "../contexts/UsetifulProvider";
import { YupLocalisation } from "../contexts/YupLocalisation";
import { RoleProvider } from "../contexts/RoleProvider";
import { CookieProvider } from "../contexts/CookieProvider";
import { MetricsProvider } from "../contexts/MetricsProvider";
import { TermsProvider } from "../contexts/TermsProvider";

import { UtilityWidgets } from "../components/UtilityWidgets";
import App from "next/app";
import { fetchSiteByHost } from "../lib/fetchSite";
import { useSiteFromClientSideUuid } from "../hooks/useSiteFromClientSideUuid";
import { unauthenticatedPaths } from "../config/unauthenticatedPaths";
import { LayoutProvider } from "../components/layout/LayoutProvider";
import { PageContextProvider } from "@equiem/lib/context/PageContext";
import { getModeProps } from "../lib/getModeProps";
import { SiteLoading } from "../components/layout/SiteLoading";
import { makeModeCookie } from "../lib/auth/modeCookie";

const useSite = ({
  site: siteInput,
  appLoadedByLegacyUrl,
  appLoadedByDefaultUrl,
  authState,
  authenticate,
}: PageProps & { authState: AuthenticationContext; authenticate: () => Promise<unknown> }) => {
  const [site, setSite] = useState<TransformedSite | null>(siteInput ?? null);
  const skip = !appLoadedByLegacyUrl && (site != null || siteInput != null || authState.accessToken == null);

  const { loading, site: clientSideSite } = useSiteFromClientSideUuid({ skip, authState, authenticate });

  const resolvedSite = siteInput ?? clientSideSite ?? site;

  if (resolvedSite != null && resolvedSite !== site) {
    setSite(resolvedSite);
  }

  useEffect(() => {
    if (resolvedSite == null && skip) {
      authenticate().catch((e: unknown) => {
        console.error(e);
      });
    }
  }, [authenticate, resolvedSite, skip]);

  const redirectUrl =
    typeof window !== "undefined" && appLoadedByDefaultUrl && clientSideSite?.equiemOneUrl != null
      ? `https://${clientSideSite.equiemOneUrl}`
      : undefined;

  return { loading, site: resolvedSite, redirectUrl };
};

function MyApp({ Component, pageProps, router }: AppProps<PageProps>) {
  const [authState, setAuthState] = useState<AuthenticationContext>({
    loaded: false,
    authenticated: pageProps.hasAuthCookie,
  });

  const { client, authenticate } = getGlobalGateway(pageProps.usingPostMessageAuth);
  const { client: unauthenticatedClient } = getGlobalUnauthenticatedGateway();

  const { loading: siteLoading, site: siteState, redirectUrl } = useSite({ ...pageProps, authState, authenticate });

  setAuthSetter((newAuthState: AuthenticationContext) => {
    /**
     * Only update the `authState` variable if its properties have different values to those
     * in `newAuthState`. Necessary because `newAuthState` is a new object every time.
     */
    if (isEqual(newAuthState, authState)) {
      return;
    }

    setAuthState(newAuthState);
  });

  // delay initial rendering of the main app until the user's preferred language is loaded
  const [translationsReady, setTranslationsReady] = useState(false);
  const onTranslationsReadyStateChanged = useCallback((isReady: boolean) => {
    if (isReady) {
      setTranslationsReady(true);
    }
  }, []);

  const cachedLocalePreference = useCachedLocalePreference(pageProps.preferredLanguage);
  const unauthenticated = useMemo(
    () => authState.loaded && !authState.authenticated && !unauthenticatedPaths.includes(router.pathname),
    [authState.authenticated, authState.loaded, router.pathname],
  );

  useEffect(() => {
    if (unauthenticated) {
      window.location.reload();
    }
  }, [unauthenticated]);

  useEffect(() => {
    if (redirectUrl != null) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const redirecting = unauthenticated || redirectUrl != null;
  const favicon = (pageProps.isInWebNgLayout ? siteState?.favicon : null) ?? "/favicon.ico";

  return (
    <PageContextProvider {...pageProps} site={siteState ?? undefined}>
      <Head>
        <title>Equiem One</title>
        <link rel="icon" href={favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, interactive-widget=resizes-content"
        />
      </Head>
      <AdminTheme useUtilityClasses={true}>
        {redirecting ? (
          <div
            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "90vh" }}
          >
            <ProgressCircle size="lg" mode="indeterminate" style={{ opacity: 0.2 }} />
          </div>
        ) : (
          <ToastProvider>
            <ConfirmerProvider>
              <IframelyProvider>
                <SiteProvider
                  isAuthenticated={authState.authenticated}
                  client={authState.authenticated ? client : unauthenticatedClient}
                  loading={siteLoading}
                  site={siteState ?? undefined}
                >
                  <Site.Consumer>
                    {(site) => (
                      <TranslationProvider
                        localePreferenceScope={
                          authState.loaded && authState.authenticated
                            ? { useViewer: true, preferredLanguage: pageProps.preferredLanguage }
                            : {
                                siteUuid: stringNotEmpty(site.uuid) ? site.uuid : undefined,
                                preferredLanguage: cachedLocalePreference ?? site.locale,
                              }
                        }
                        client={authState.loaded && authState.authenticated ? client : unauthenticatedClient}
                        onReadyStateChanged={onTranslationsReadyStateChanged}
                      >
                        <SessionProvider
                          authState={authState}
                          endpoint={site.gatewayEndpoint}
                          globalClient={client}
                          globalAuthenticate={authenticate}
                        >
                          <ProfileProvider>
                            <RoleProvider>
                              <CookieProvider>
                                <TermsProvider>
                                  <UsetifulProvider>
                                    <MetricsProvider>
                                      <UrlParamsProvider>
                                        <SideMenuProvider>
                                          <BreadcrumbsUuidProvider>
                                            <BreadcrumbsProvider>
                                              <AppointmentsMenuProvider>
                                                <GradientProvider>
                                                  <YupLocalisation>
                                                    {translationsReady ? (
                                                      <LayoutProvider
                                                        authenticated={authState.authenticated}
                                                        {...pageProps}
                                                      >
                                                        <Component {...pageProps} />
                                                        <UtilityWidgets />
                                                      </LayoutProvider>
                                                    ) : (
                                                      <SiteLoading />
                                                    )}
                                                  </YupLocalisation>
                                                </GradientProvider>
                                              </AppointmentsMenuProvider>
                                            </BreadcrumbsProvider>
                                          </BreadcrumbsUuidProvider>
                                        </SideMenuProvider>
                                      </UrlParamsProvider>
                                    </MetricsProvider>
                                  </UsetifulProvider>
                                </TermsProvider>
                              </CookieProvider>
                            </RoleProvider>
                          </ProfileProvider>
                        </SessionProvider>
                      </TranslationProvider>
                    )}
                  </Site.Consumer>
                </SiteProvider>
              </IframelyProvider>
            </ConfirmerProvider>
          </ToastProvider>
        )}
      </AdminTheme>
    </PageContextProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const ctx = appContext.ctx;
  const { source: modeSource, mode, hasAuthCookie, preferredLanguage } = getModeProps(appContext);
  const isInMobileMode = mode === "mobile";
  const isInWebNgMode = mode === "webng";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const pageProps: PageProps = {
    ...appProps.pageProps,
    isInMobileLayout: isInMobileMode,
    isInWebNgLayout: isInWebNgMode,
    hasAuthCookie,
    appLoadedByDefaultUrl: false,
    appLoadedByLegacyUrl: false,
    usingPostMessageAuth: process.env.usePostMessageAuth !== "false" && isInMobileMode,
    preferredLanguage,
  };

  if (ctx.req?.headers.host != null) {
    const host = ctx.req.headers.host;
    const hostname = host.split(":")[0];
    const { appLoadedByDefaultUrl, appLoadedByLegacyUrl } = getHostInfo(hostname);
    const isElbHealthCheck = ctx.req.headers["user-agent"] === "ELB-HealthChecker/2.0";
    pageProps.appLoadedByDefaultUrl = appLoadedByDefaultUrl;
    pageProps.appLoadedByLegacyUrl = appLoadedByLegacyUrl;

    if (!appLoadedByLegacyUrl && !appLoadedByDefaultUrl && !isElbHealthCheck) {
      const site = await fetchSiteByHost(host);
      pageProps.site = site;
    }

    if (modeSource === "query") {
      ctx.res?.appendHeader("set-cookie", makeModeCookie(mode, hostname));
    }
  } else {
    const hostname = window.location.hostname;
    const { appLoadedByDefaultUrl, appLoadedByLegacyUrl } = getHostInfo(hostname);
    pageProps.appLoadedByDefaultUrl = appLoadedByDefaultUrl;
    pageProps.appLoadedByLegacyUrl = appLoadedByLegacyUrl;
    if (modeSource === "query") {
      document.cookie = makeModeCookie(mode, hostname);
    }
  }

  return { ...appProps, pageProps };
};

export default MyApp;
