import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";

import type { CompanyFormValues } from "@equiem/lib";
import { companyValidationSchema, CurrentRole, Role, Site, useCompanyFormSubmit, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Alert,
  Button,
  Modal,
  Skeleton,
  Tabs,
  useConfirmer,
  useIsMobileWidth,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { Modal as ModalContext } from "../../../contexts/ModalContext";
import { useWidgetContext } from "../../../contexts/WidgetContext";
import type { CompanyListQueryVariables } from "../../../generated/settings-client";
import { useAccessGroupsQuery, useSiteDetailsQuery } from "../../../generated/settings-client";
import { AddCompanyForm } from "../components/AddCompanyForm";
import { CompanyAccessGroups } from "../components/CompanyAccessGroups";
import { CompanyBilling } from "../components/CompanyBilling";
import { CompanyIntegrations } from "../components/CompanyIntegrations";
import { CompanyPermissions } from "../components/CompanyPermissions";
import { useCompanyDetails } from "../hooks/useCompanyDetails";
import { editCompanyTabs } from "../utils/editCompanyTabs";

interface Props {
  companyListRefetchData?: {
    refetch: (_args?: Partial<CompanyListQueryVariables>) => Promise<unknown>;
    pageSize: number;
    currentPage: number;
  };
}

export const CompanyDetailsWidget: React.FC<Props> = ({ companyListRefetchData }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { currentRole } = useContext(CurrentRole);
  const modal = useContext(ModalContext);
  const isPropertyManager = currentRole === Role.PropertyManager;
  const site = useContext(Site);
  const { company: companyData, loading: companyLoading } = useCompanyDetails({
    companyUuid: modal.id,
    siteUuid: site.uuid,
  });
  const { data: groups } = useAccessGroupsQuery({ variables: { siteUuid: site.uuid } });
  const tabs = editCompanyTabs(t, currentRole, groups?.accessGroups.length, companyData?.accessGroups.length);
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const showError = useShowError();
  const isMobile = useIsMobileWidth();
  const { spacers, colors } = useTheme();
  const { onSubmit } = useCompanyFormSubmit(modal.id, site.uuid);
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const formInnerRef = useRef<FormikProps<CompanyFormValues>>(null);
  const { handleSave, actionsState, setActionState } = useWidgetContext();
  const { data: siteDetails } = useSiteDetailsQuery({
    variables: {
      uuid: site.uuid,
    },
  });
  const isEdit = modal.id != null;
  useEffect(() => {
    if (["general", "permissions", "billing", "groups"].includes(selectedTab)) {
      setActionState("onSave", "normal");
      return;
    }

    setActionState("onSave", "hidden");
  }, [selectedTab, setActionState]);

  useEffect(() => {
    setSelectedTab(tabs[0].key);
  }, [showModal]);

  useEffect(() => {
    if (modal.activeModal === "CompanyDetails") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modal.activeModal]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [setShowModal, modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.createCompany.cancellingCreation"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose, formInnerRef]);

  const companyDestination = companyData?.companyDestination;
  const isSiteIntegrationsEnabled = siteDetails?.destination.integrations?.evolution.enabled ?? false;
  const isIntegrationsEnabled = isSiteIntegrationsEnabled && isPropertyManager;

  const initialValues: CompanyFormValues = useMemo(
    () => ({
      name: companyData?.name ?? "",
      industry: companyData?.industry?.uuid ?? "",
      attributes: companyDestination?.attributes.map((a) => a.uuid) ?? [],
      validationValues: companyData?.validation?.values.map((v) => v.value),
      levels: companyDestination?.buildingLevels.map((l) => l.uuid),
      autoApproveRegistrations: companyLoading ? undefined : companyDestination?.autoApproveRegistrations ?? false,
      defaultAccessGroupUuid: companyData?.defaultAccessGroup?.uuid,
      accessGroupsUuids:
        companyData?.accessGroups.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.uuid]: true,
          };
        }, {}) ?? [],
      addsCardsAutomatically: companyData?.addsCardsAutomatically,
    }),
    [companyLoading, companyData, companyDestination],
  );

  const enabledTabs = isIntegrationsEnabled ? tabs : tabs.filter((tab) => tab.key !== "integrations");

  const handleSubmit = async (values: CompanyFormValues, { setSubmitting }: FormikHelpers<CompanyFormValues>) => {
    try {
      setSubmitting(true);
      setActionState("onSave", "loading");
      await onSubmit(values);
      if (companyListRefetchData != null) {
        const { refetch, currentPage, pageSize } = companyListRefetchData;
        await refetch({
          first: currentPage * pageSize,
        });
      }
      setShowModal(false);
      modal.close();
      toast.positive(
        isEdit
          ? t("settings.createCompany.companySuccessfullyUpdated", { companyName: values.name })
          : t("settings.createCompany.companySuccessfullyCreated", { companyName: values.name }),
      );
    } catch (e: unknown) {
      showError(e);
    }
    setSubmitting(false);
    setActionState("onSave", "normal");
  };

  return (
    <>
      <Modal.Dialog
        title={isEdit ? t("settings.editCompany.editCompany") : t("settings.createCompany.createNew")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header
          intro={!isMobile ? (isEdit ? companyData?.name.trim() ?? "" : t("settings.createCompany.newCompany")) : null}
          closeButton
          noBorder={!isMobile}
          onClose={onCloseModal}
          supportsMobile
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          innerRef={formInnerRef}
          validationSchema={companyValidationSchema(t)}
          onSubmit={handleSubmit}
        >
          <>
            <Modal.Body noPadding>
              {companyLoading ? (
                <div className="px-6 mt-5">
                  <Skeleton.Line width="80px" height="1.5rem" className="mr-4" />
                  <Skeleton.Line width="80px" height="1.5rem" className="mr-4" />
                  <Skeleton.Line width="80px" height="1.5rem" className="mb-0" />
                  <Skeleton.Line width="100%" height="1px" />
                  <Skeleton.Line width="90px" height="1.5rem" className="mt-6 mb-3" />
                  <Skeleton.Line width="100%" height="2rem" />
                  <Skeleton.Line width="120px" height="1.5rem" className="mt-6 mb-3" />
                  <Skeleton.Line width="100%" height="2rem" />
                  <Skeleton.Line width="105px" height="1.5rem" className="mt-6 mb-3" />
                  <Skeleton.Line width="100%" height="2rem" />
                </div>
              ) : (
                <>
                  {isEdit && (
                    <>
                      <div className="px-6">
                        <Tabs items={enabledTabs} selected={selectedTab} onSelect={setSelectedTab} />
                      </div>
                      <hr className="mt-0 pb-4" />
                    </>
                  )}
                  <div className="content">
                    {isEdit && selectedTab !== "integrations" && (
                      <Alert
                        size="large"
                        variant="gray"
                        icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                        message={t("settings.editCompany.editCompanyInfo")}
                        className="mb-6"
                      />
                    )}
                    {!isEdit && (
                      <Alert
                        size="large"
                        variant="gray"
                        icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                        message={t("settings.editCompany.addCompanyInfo")}
                        className="mb-6"
                      />
                    )}
                    {companyData != null && (
                      <>
                        {selectedTab === "integrations" && <CompanyIntegrations company={companyData} />}
                        {selectedTab === "permissions" && (
                          <CompanyPermissions modalClose={onClose} company={companyData} />
                        )}
                        {selectedTab === "billing" && <CompanyBilling modalClose={onClose} company={companyData} />}
                        {selectedTab === "groups" && groups?.accessGroups != null && (
                          <CompanyAccessGroups company={companyData} accessGroups={groups.accessGroups} />
                        )}
                      </>
                    )}
                    {selectedTab === "general" && <AddCompanyForm isEdit={isEdit} open={showModal} />}
                  </div>
                </>
              )}
            </Modal.Body>
            {actionsState.onSave !== "hidden" && (
              <Modal.Footer>
                <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                  {t("common.cancel")}
                </Button>
                <Button
                  disabled={["loading", "disabled"].includes(actionsState.onSave)}
                  type="submit"
                  variant="primary"
                  onClick={handleSave}
                >
                  {isEdit ? t("settings.createCompany.saveChanges") : t("settings.createCompany.createCompany")}
                </Button>
              </Modal.Footer>
            )}
          </>
        </Formik>
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        .content {
          padding: 0 ${spacers.s6} 0;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};
