import React, { useContext, useMemo } from "react";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";

import { CurrentProfile, CurrentRole, Role, SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { ButtonLink, Tabs, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiArrowLeftLine, RiSettings3Line } from "@equiem/react-admin-ui/icons";

import { RequestsMenuContext } from "../contexts/RequestsMenuContext";
import { RequestsAccessContext } from "../pages/requests/contexts/RequestsAccessContext";
import { isRequestPageScope, RequestsScopeContext } from "../pages/requests/contexts/RequestsScopeContext";

interface TabProps {
  children: React.ReactNode;
  title?: string;
  heading?: string;
}

export const RequestTabs: React.FC<TabProps> = ({ children, title, heading }) => {
  const { colors, breakpoints } = useTheme(true);
  const { t } = useTranslation();
  const router = useRouter();
  const { currentRole } = useContext(CurrentRole);
  const { currentScope, setScope, setLastVisitedScope, getLastVisitedScope } = useContext(RequestsScopeContext);
  const { profile } = useContext(CurrentProfile);
  const { disabled, activeTabs, closeTab } = useContext(RequestsMenuContext);
  const isMobile = useIsMobileWidth();
  const isRootPage = router.pathname === "/request-management";
  const access = useContext(RequestsAccessContext);

  const goToKeyRoute = async (pathname: string, preserveQuery = true) => {
    await router.push({
      pathname: pathname,
      query: preserveQuery ? router.query : undefined,
    });
  };

  const defaultTitle = currentRole === Role.Unknown ? t("requests.titleShort") : t("requests.titleLong");

  const tabs = useMemo(() => {
    const defaultTabs: TabItem[] = [];

    if (currentRole === Role.WorkplaceManager || currentRole === Role.PropertyManager || access.requestManager) {
      defaultTabs.push({
        title: t("requests.tab.allRequests") as string,
        key: "all",
        disabled,
      });
    }
    if (access.requestAssignee) {
      defaultTabs.push({
        title: t("requests.tab.assignedRequests") as string,
        key: "assigned",
        disabled,
      });
    }

    defaultTabs.push({
      title: t("requests.tab.myRequests") as string,
      key: "my",
      disabled,
    });

    const active = isMobile
      ? []
      : activeTabs.map((tab) => ({
          title: tab.reference,
          key: `/request-management/request/${tab.uuid}`,
          disabled,
          onClose: async () => {
            closeTab(tab.uuid);

            if (tab.uuid === router.query.uuid) {
              await goToKeyRoute("/request-management");
            }
          },
        }));

    return [...defaultTabs, ...active];
  }, [
    currentRole,
    access.requestManager,
    access.requestAssignee,
    t,
    disabled,
    activeTabs,
    profile?.companyV2?.name,
    closeTab,
    router,
    isMobile,
  ]);

  const handleTabSelect = async (key: string) => {
    const isScopeTab = isRequestPageScope(key);
    const lastVisitedScope = getLastVisitedScope();

    // if user is on landing page either change scope or go request details page immediately
    if (isRootPage) {
      if (isScopeTab) {
        setScope(key);
      } else {
        await goToKeyRoute(key);
      }

      return;
    }

    if (isScopeTab) {
      // if we are going back from details page to landing page, override last visited scope
      setLastVisitedScope(key);
      // only preserve filters query params if we are returning to the last visited scope
      // (i.e.: My requests -> request details -> click back or close tab or click on "My requests")
      await goToKeyRoute("/request-management", lastVisitedScope === key);
      return;
    }

    // this only happens when we switch between opened request tabs
    await goToKeyRoute(key);
  };

  return (
    <>
      <Head>
        <title>{title ?? defaultTitle}</title>
      </Head>
      <div className="cont">
        <SubTopMenu btmPadding={false} topPadding={false} className="top-menu">
          {isMobile && !isRootPage && (
            <Link
              href={{
                pathname: "/request-management",
                query: router.query,
              }}
              passHref
              legacyBehavior
            >
              <a className="mr-3 ml-3">
                <RiArrowLeftLine size={16} color={colors.grayscale[60]} />
              </a>
            </Link>
          )}
          <h1 className="mb-3 font-weight-bold">{heading ?? defaultTitle}</h1>
          {currentRole === Role.PropertyManager && ((isMobile && isRootPage) || !isMobile) && (
            <div className="actions d-flex">
              <Link
                href={{
                  pathname: "/request-management/settings",
                  query: router.query,
                }}
                passHref
                legacyBehavior
              >
                <ButtonLink variant="ghost" size="md">
                  <RiSettings3Line size={16} />
                  {!isMobile && t("common.settings")}
                </ButtonLink>
              </Link>
            </div>
          )}
        </SubTopMenu>
        {(!isMobile || isRootPage) && (
          <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
            <Tabs
              items={tabs}
              selected={isRootPage ? currentScope : router.asPath}
              tabLinks
              preventPropagateClick={true}
              onSelect={(key) => {
                handleTabSelect(key).catch(console.log);
              }}
            />
          </SubTopMenu>
        )}
        {children}
      </div>
      <style jsx>{`
        .cont {
          background: ${colors.white};
          min-height: calc(100vh - 65px);
        }

        @media (max-width: ${breakpoints.lg}px) {
          .cont h1 {
            margin-bottom: 0 !important;
          }
          .cont :global(.top-menu) {
            width: 100%;
            justify-content: flex-start !important;
          }
          .cont .actions {
            margin-left: auto;
          }
        }
      `}</style>
    </>
  );
};
