import type { ApolloError } from "@apollo/client";
import { NoAccessPage, useBcUuidContext, useSiteContext } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";
import type { FormikHelpers } from "formik";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { ResourceCreateAndEditForm } from "./ResourceCreateAndEditForm";
import { ResourceCreateAndEditLoading } from "./ResourceCreateAndEditLoading";
import { ResourceActiveBookingWarning } from "../../ResourceActiveBookingWarning";
import {
  BookableResourceStatus,
  BookableResourcesDocument,
  useBookableResourceEditQuery,
  useUpdateResourceMutation,
} from "../../../../generated/gateway-client";
import type { FormValues } from "../../../../lib/formValidation";
import { toResourceEditInput, toResourceFormValues } from "../../../../lib/resourceEditForm";

export const ResourceEditForm: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const router = useRouter();
  const toast = useToast();
  const { uuid: siteUuid, featureModules } = useSiteContext();
  const { updateBcUuid, bcUuid } = useBcUuidContext();
  const { uuid } = router.query as { uuid: string };

  const { data, loading, error, refetch } = useBookableResourceEditQuery({
    variables: { uuid },
    fetchPolicy: "network-only",
  });
  const resource = data?.bookableResource;

  useEffect(() => {
    if (resource != null && (bcUuid == null || bcUuid[resource.uuid] !== resource.name)) {
      updateBcUuid(resource.uuid, resource.name);
    }
  }, [resource, bcUuid, updateBcUuid]);

  const initialValues = useMemo(
    () => (resource != null ? toResourceFormValues(resource, siteUuid, featureModules.visitors.enabled) : null),
    [resource, siteUuid, featureModules.visitors.enabled],
  );

  const [updateResourceMutation] = useUpdateResourceMutation();
  const submit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setSubmitting(true);

    try {
      const { data: mutationData } = await updateResourceMutation({
        refetchQueries: [{ query: BookableResourcesDocument, variables: { destinationUuid: siteUuid } }],
        variables: { uuid: resource?.uuid ?? "", input: toResourceEditInput(values) },
      });

      const title = mutationData?.updateBookableResource.name;
      const status = mutationData?.updateBookableResource.status;
      if (status === BookableResourceStatus.Draft) {
        toast.neutral(t("bookings.resources.hasBeenSavedToDraft", { title: title ?? "" }));
      } else {
        toast.positive(t("bookings.resources.hasBeenSavedAndPublished", { title: title ?? "" }));
      }

      await router.push("/bookings/resources");
    } catch (e: unknown) {
      console.error(e);
      toast.negative(tError(e as ApolloError));
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return <ResourceCreateAndEditLoading />;
  }
  if (error != null) {
    return <span>{tError(error)}</span>;
  }
  if (resource?.viewerPermissions?.canManageBookings === false) {
    <NoAccessPage />;
  }
  if (resource == null || initialValues == null) {
    return <span>{t("bookings.resources.unableToLoadResource")}</span>;
  }

  return (
    <>
      <ResourceActiveBookingWarning resource={resource} />
      <ResourceCreateAndEditForm
        initialValues={initialValues}
        resourceUuid={resource.uuid}
        resourceName={resource.name}
        resourceOwnerCompany={resource.company}
        submit={submit}
        refetch={refetch}
      />
    </>
  );
};
