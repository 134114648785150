import React, { useCallback } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ColumnView, Tooltip, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import {
  RiAddCircleLine,
  RiAddLine,
  RiArrowDownLine,
  RiArrowUpLine,
  RiDeleteBinLine,
  RiEditLine,
} from "@equiem/react-admin-ui/icons";

import { StatusTypeIcon } from "../../components/StatusTypeIcon";

import { useStatusData } from "./hooks/useStatusData";
import { SettingsTabs } from "./SettingsTabs";

export const Status: React.FC = () => {
  const theme = useTheme();
  const { withConfirmation } = useConfirmer();
  const { t } = useTranslation();
  const showError = useShowError();
  const {
    NEW_ID,
    mutationLoading,
    orderingLoading,
    deleteLoading,
    createLoading,
    updateLoading,
    createStatus,
    deleteStatus,
    updateStatus,
    setShowNewStatus,
    editingStatus,
    setEditingStatus,
    selectedType,
    setSelectedType,
    statuses,
    statusTypes,
    statusesLoading,
    reorder,
  } = useStatusData();

  const handleReset = useCallback(() => {
    setShowNewStatus(false);
    setEditingStatus(null);
  }, [setShowNewStatus, setEditingStatus]);

  const statusLastIndex = statuses.length - 1;

  return (
    <>
      <div className="container">
        <SettingsTabs>
          <>
            <h3 className="font-weight-bold pb-3">{t("requests.status.configureStatuses")}</h3>
            <ColumnView.Container>
              <ColumnView.Column
                className="scol"
                id="status-type"
                title={t("requests.status.statusType")}
                empty={statusTypes.length === 0 || statusesLoading}
                loading={statusesLoading}
              >
                {statusTypes.map((st) => (
                  <ColumnView.Item
                    className="status-type"
                    value={st.title}
                    icon={<StatusTypeIcon type={st.id} />}
                    key={st.id}
                    onSelect={() => {
                      setSelectedType(st.id);
                      setShowNewStatus(false);
                      setEditingStatus(null);
                    }}
                    selected={selectedType === st.id}
                  />
                ))}
              </ColumnView.Column>
              <ColumnView.Column
                className="scol"
                id="status"
                title={t("requests.status.statuses")}
                empty={statuses.length === 0}
                emptySettings={{
                  message:
                    selectedType == null
                      ? t("requests.status.pleaseSelectAStatusType")
                      : t("requests.status.noStatusHasBeenAdded"),
                  action: (
                    <>
                      {selectedType != null && (
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setShowNewStatus(true);
                          }}
                        >
                          <RiAddLine /> {t("requests.status.addStatus")}
                        </Button>
                      )}
                    </>
                  ),
                }}
                actions={[
                  <Tooltip title={t("requests.status.addStatus")} key="add">
                    <Button
                      variant="ghost"
                      className="add-new-status"
                      onClick={() => {
                        setShowNewStatus(true);
                      }}
                      disabled={selectedType == null}
                      aria-label={t("requests.status.addStatus")}
                      round
                    >
                      <RiAddCircleLine size={16} />
                    </Button>
                  </Tooltip>,
                ]}
              >
                {statuses.map((status, index) => (
                  <ColumnView.Item
                    className="status"
                    value={status.title}
                    key={status.id}
                    isNew={status.id === NEW_ID}
                    isEditing={editingStatus === status.id}
                    onReset={handleReset}
                    loading={createLoading || updateLoading}
                    onChange={(value) => {
                      if (status.id === NEW_ID) {
                        createStatus(status.type, value)
                          .catch(showError)
                          .finally(() => {
                            handleReset();
                          });
                      } else {
                        updateStatus(status.type, status.id, value)
                          .catch(showError)
                          .finally(() => {
                            handleReset();
                          });
                      }
                    }}
                    actions={[
                      {
                        title: t("common.up"),
                        icon: RiArrowUpLine,
                        onClick: () => reorder("up", status.id),
                        disabled: index === 0 || mutationLoading,
                        loading: orderingLoading,
                        className: "item-up",
                      },
                      {
                        title: t("common.down"),
                        icon: RiArrowDownLine,
                        onClick: () => reorder("down", status.id),
                        disabled: index === statusLastIndex || mutationLoading,
                        loading: orderingLoading,
                        className: "item-down",
                      },
                      {
                        title: t("common.edit"),
                        disabled: mutationLoading,
                        icon: RiEditLine,
                        onClick: () => setEditingStatus(status.id),
                      },
                      {
                        title: t("common.delete"),
                        disabled: mutationLoading,
                        icon: RiDeleteBinLine,
                        loading: deleteLoading,
                        color: theme.colors.status.danger.primary,
                        onClick: withConfirmation({
                          onConfirm: () => {
                            deleteStatus(status.id).catch(showError);
                          },
                          title: t("common.deleteConfirmationTitleWithTitle", { title: status.title }) as string,
                          message: t("common.deleteConfirmationWithTitle", { title: status.title }) as string,
                          confirmButtonText: t("common.yesDelete"),
                          confirmButtonVariant: "danger",
                        }),
                      },
                    ]}
                  />
                ))}
              </ColumnView.Column>
            </ColumnView.Container>
          </>
        </SettingsTabs>
      </div>
      <style jsx>{`
        .container :global(.scol) {
          height: calc(100vh - 300px);
        }
        @media (max-width: ${theme.breakpoints.md}px) {
          .container :global(.scol) {
            height: calc(100vh - 250px);
          }
        }
      `}</style>
    </>
  );
};
