import React, { forwardRef, useContext, useMemo, useState } from "react";
import type { ChangeEventHandler } from "react";

import { useTheme } from "../../../contexts/Theme";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { Text } from "../../Text/Text";
import { Tooltip } from "../../Tooltip/Tooltip";

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = Omit<React.ButtonHTMLAttributes<HTMLInputElement>, "value"> & {
  label: React.ReactNode;
  value?: boolean;
  description?: React.ReactNode;
  tooltipText?: string | null;
  rightButton?: React.ReactNode;
};

const OptionalTooltip: React.FC<React.PropsWithChildren<{ title?: string | null }>> = ({ title, children }) => {
  if (title == null) {
    return <>{children}</>;
  }

  return (
    <Tooltip title={title} placement="bottom">
      {children}
    </Tooltip>
  );
};

export const FormEnclosedRadioButton = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      description,
      value: propsValue,
      tooltipText,
      rightButton,
      onChange,
      className,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const { hasError, id, hintId } = useContext(FormGroupContext);
    const { colors } = useTheme();
    const [localValue, setLocalValue] = useState<boolean>(propsValue ?? false);

    const value = useMemo(() => propsValue ?? localValue, [propsValue, localValue]);

    const inputId = useMemo(() => props.id ?? id ?? props.name, [id, props.id, props.name]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setLocalValue(e.target.checked);
      if (onChange != null) {
        onChange(e);
      }
    };

    return (
      <>
        <label
          className={`label ${value ? "label--active" : ""} ${disabled ? "label--disabled" : ""} ${
            hasError ? "label--error" : ""
          } ${className ?? ""}`.trim()}
          htmlFor={inputId}
        >
          <div className="control">
            <OptionalTooltip title={tooltipText}>
              <input
                {...props}
                ref={ref}
                id={inputId}
                aria-describedby={hintId}
                type="radio"
                checked={value}
                disabled={disabled}
                onChange={handleChange}
              />
            </OptionalTooltip>
          </div>
          <div className="info">
            <Text
              variant={description != null ? "heading" : "text"}
              size={description != null ? "small" : "medium"}
              className="m-0"
            >
              {label}
            </Text>
            <Text variant="text" size="extra-small" className="description m-0">
              {description}
            </Text>
          </div>
          {rightButton}
        </label>
        <style jsx>{`
          input[type="radio"] {
            width: 16px;
            height: 16px;
            background: #ffffff;
            border: 1px solid ${colors.grayscale[20]};
            border-radius: 50%;
            appearance: none;
            position: relative;
            margin: 0.25rem;
            cursor: pointer;
          }

          input[type="radio"]:checked {
            border-color: ${colors.blue[60]};
          }

          input[type="radio"]:checked:before {
            content: "";
            position: absolute;
            transform: translate(50%, 50%);
            top: 0;
            left: 0;
            width: 50%;
            height: 50%;
            border-radius: 50%;
            background-color: ${colors.blue[60]};
          }

          .label {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            max-width: 100%;
            user-select: none;
            border-radius: 4px;
            padding: ${description != null ? "1rem" : "0.5rem 1rem 0.5rem 0.5rem"};
            border: 1px solid ${colors.transparent.black[10]};
            gap: ${description != null ? "0.5rem" : "0.25rem"};
          }

          .label:not(.label--active):hover {
            cursor: pointer;
            background-color: ${colors.transparent.black[5]};
          }

          .label--error,
          .label-error input {
            border-color: ${colors.status.danger.primary};
          }

          .label--active {
            background-color: ${colors.blue[10]};
            border-color: ${colors.blue[60]};
          }
          .label--active input {
            cursor: default;
          }

          .label--disabled,
          .label--disabled:not(.label--active):hover {
            cursor: auto;
            background-color: ${colors.grayscale[3]};
            border-color: ${colors.grayscale[20]};
          }
          .label--disabled.label--active {
            background-color: ${colors.grayscale[10]};
            border-color: ${colors.grayscale[60]};
          }
          .label--disabled input {
            cursor: not-allowed;
          }

          .control {
            display: flex;
          }

          .info {
            flex: 1;
            min-width: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
          }
          .info :global(.description) {
            color: ${colors.transparent.black[60]};
          }
        `}</style>
      </>
    );
  },
);

FormEnclosedRadioButton.displayName = "FormEnclosedRadioButton";
