import React, { useContext, useEffect, useMemo, useState } from "react";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useRouter } from "next/router";

import { ButtonTray, CurrentRole, Role, sortByAlphabet, stringIsEmpty, SubTopMenu, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, ProgressCircle, Text, useTheme, useToast } from "@equiem/react-admin-ui";

import {
  useBarrierControlConfigCodeTypeLazyQuery,
  useBarrierControlConfigPrefixLazyQuery,
  useCurrentDestinationBuildingsQuery,
  useUpdateBuildingMutation,
} from "../../../generated/visitors-client";
import { Menu } from "../components/NestedMenu";

import { BuildingSettingsForm } from "./BuildingSettingsForm";
import type { BuildingSettingsValues } from "./BuildingSettingsValues";
import { getValidationSchema } from "./validationSchema";

export const BuildingSettings: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const toast = useToast();
  const router = useRouter();
  const showError = useShowError();
  const { currentRole } = useContext(CurrentRole);

  const { data: buildingsData, loading: buildingsLoading } = useCurrentDestinationBuildingsQuery();
  const [fetchCodeType, { data: codeType }] = useBarrierControlConfigCodeTypeLazyQuery();
  const [fetchPrefix, { data: prefix }] = useBarrierControlConfigPrefixLazyQuery();
  const [updateBuilding] = useUpdateBuildingMutation();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedBuilding, setSelectedBuilding] = useState<string>("");

  useEffect(() => {
    if (currentRole !== Role.PropertyManager) {
      void router.replace("/visitor-management/settings");
    }
  }, [currentRole, router]);

  const buildings = useMemo(() => {
    const destBuildings = buildingsData?.viewer.currentDestination?.destination.buildings ?? [];

    const allBuildings = sortByAlphabet(
      destBuildings.map((building) => ({
        uuid: building.uuid,
        name: building.name,
      })),
      "name",
    );

    if (allBuildings.length === 1 && selectedBuilding.length === 0) {
      setSelectedBuilding(allBuildings[0].uuid);
    }

    return allBuildings;
  }, [buildingsData, selectedBuilding]);

  useEffect(() => {
    if (selectedBuilding.length > 0) {
      void fetchCodeType({ variables: { buildingUuid: selectedBuilding } });
      void fetchPrefix({ variables: { buildingUuid: selectedBuilding } });
    }
  }, [selectedBuilding, fetchCodeType, fetchPrefix]);

  const initialValues: BuildingSettingsValues = useMemo(() => {
    const selectedBuildingData = buildingsData?.viewer.currentDestination?.destination.buildings?.find(
      (bulding) => bulding.uuid === selectedBuilding,
    );
    return {
      emailFooterText: selectedBuildingData?.visitorManagement?.emailFooterText ?? "",
      emailFooterImage:
        selectedBuildingData?.visitorManagement?.emailFooterImage != null &&
        selectedBuildingData.visitorManagement.emailFooterImageKey != null
          ? {
              key: selectedBuildingData.visitorManagement.emailFooterImageKey,
              url: selectedBuildingData.visitorManagement.emailFooterImage,
              title: "image",
              alt: "title",
              thumbnail_key: selectedBuildingData.visitorManagement.emailFooterImageKey,
            }
          : null,
      emailHeaderImage:
        selectedBuildingData?.visitorManagement?.emailHeaderImage != null &&
        selectedBuildingData.visitorManagement.emailHeaderImageKey != null
          ? {
              key: selectedBuildingData.visitorManagement.emailHeaderImageKey,
              url: selectedBuildingData.visitorManagement.emailHeaderImage,
              title: "image",
              alt: "title",
              thumbnail_key: selectedBuildingData.visitorManagement.emailHeaderImageKey,
            }
          : null,
      passAccessStartTime: selectedBuildingData?.visitorManagement?.passAccessStartTime?.toString() ?? "0",
      passAccessEndTime: selectedBuildingData?.visitorManagement?.passAccessEndTime?.toString() ?? "0",
      passPrintingConfiguration: {
        marginTop: selectedBuildingData?.visitorManagement?.passPrintingConfiguration?.marginTop ?? 0,
      },
    };
  }, [selectedBuilding, buildingsData]);

  const handleBuildingSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBuilding(e.target.value);
  };

  const handleCancel = () => {
    setShowModal(false);
    void router.push("/visitor-management/settings");
  };

  const handleSubmit = async (values: BuildingSettingsValues, actions: FormikHelpers<BuildingSettingsValues>) => {
    try {
      await updateBuilding({
        variables: {
          uuid: selectedBuilding,
          input: {
            emailFooterText: values.emailFooterText,
            // Select element returns a string in runtime, so we need to convert it to a number
            passAccessStartTime: parseInt(values.passAccessStartTime),
            passAccessEndTime: parseInt(values.passAccessEndTime),
            passPrintingConfiguration: {
              marginTop: values.passPrintingConfiguration.marginTop,
            },
            emailFooterImageKey: stringIsEmpty(values.emailFooterImage?.key) ? null : values.emailFooterImage?.key,
            emailHeaderImageKey: stringIsEmpty(values.emailHeaderImage?.key) ? null : values.emailHeaderImage?.key,
          },
        },
        refetchQueries: ["Companies"],
      });

      toast.positive(t("visitors.settings.changesSaved"));
    } catch (e: unknown) {
      showError(e);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <div className="page-container">
        <SubTopMenu btmPadding={false} topPadding={false}>
          <h1 className="font-weight-bold mb-2">{t("common.settings")}</h1>
        </SubTopMenu>
        <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
          <Menu />
        </SubTopMenu>
        <div className="d-flex flex-column p-7 main-container">
          <Text variant="heading" size="medium" className="mb-6">
            {t("visitors.settings.manageBuildingSettings")}
          </Text>
          <EqForm.Group
            label={t("visitors.common.building")}
            required
            showTooltip={true}
            tooltipText={t("visitors.settings.buildingTooltip")}
          >
            <EqForm.Select id="buildingSelect" onChange={handleBuildingSelect} value={selectedBuilding}>
              <option value="" disabled>
                {buildingsLoading
                  ? `${t("common.loading")}...`
                  : buildings.length === 0
                  ? t("common.noneAvailable")
                  : `${t("common.pleaseSelect")}...`}
              </option>
              {buildings.map((building) => (
                <option key={building.uuid} value={building.uuid}>
                  {building.name}
                </option>
              ))}
            </EqForm.Select>
          </EqForm.Group>
          {selectedBuilding.length > 0 && (
            <Formik<BuildingSettingsValues>
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={getValidationSchema(codeType?.barrierControlConfigCodeType.type != null, t)}
            >
              {({ isSubmitting, submitForm, setTouched, dirty }) => (
                <>
                  <BuildingSettingsForm
                    codeType={codeType?.barrierControlConfigCodeType.type}
                    prefix={prefix?.barrierControlConfigPrefix}
                  />
                  <ButtonTray>
                    <Button
                      variant="ghost"
                      size="md"
                      className="mr-5"
                      disabled={isSubmitting}
                      onClick={() => setShowModal(true)}
                    >
                      {t("common.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      size="md"
                      disabled={!dirty}
                      onClick={() => {
                        setTouched(
                          {
                            passPrintingConfiguration: {
                              marginTop: true,
                            },
                          },
                          true,
                        ).catch(console.error);

                        submitForm().catch(showError);
                      }}
                    >
                      {isSubmitting && <ProgressCircle size="xs" className="mr-2" />}
                      {t("common.save")}
                    </Button>
                  </ButtonTray>
                </>
              )}
            </Formik>
          )}
        </div>
      </div>
      <Modal.Dialog centered={true} show={showModal} title="Are you sure?" hideOnEsc={true} size="sm">
        <Modal.Header closeButton={false} noBorder={true} />
        <Modal.Body>{t("visitors.settings.cancelChangesBody")}</Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" className="mr-2" onClick={() => setShowModal(false)}>
            {t("visitors.appointmentForm.cancelNo")}
          </Button>
          <Button variant="danger" className="mr-2" onClick={handleCancel}>
            {t("common.yesCancel")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        .page-container {
          height: 100%;
          width: 100%;
          background: ${theme.colors.white};
        }
        .main-container {
          max-width: 664px;
        }
      `}</style>
    </>
  );
};
