/* eslint complexity: ["error", 99] */
import React from "react";
import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Activity, Avatar, Tag, useTheme } from "@equiem/react-admin-ui";
import { RiAccountCircleFill, RiCalendarEventLine, RiMapPinLine } from "@equiem/react-admin-ui/icons";

import { StatusTag } from "../../../components/StatusTag";
import {
  type ActivitiesQuery,
  ActivityAudience,
  ActivityChangeFieldType,
  ReqMgtStatusType,
} from "../../../generated/requests-client";
import {
  assignee,
  attachmentsRemoved,
  attachmentsUpdated,
  category,
  completed,
  description,
  firstReporter,
  location,
  reported,
  reporterUpdated,
  subCategoryAdded,
  subCategoryRemoved,
  subCategoryUpdated,
  watchers,
} from "../typeguard/activityRuntypes";

const NameDisplay: React.FC<{ "data-eq-test"?: string; "grey"?: boolean; "children": React.ReactNode }> = ({
  "data-eq-test": dataEqTest,
  grey = false,
  children,
}) => {
  const { colors } = useTheme();
  return (
    <span data-eq-test={dataEqTest} style={{ whiteSpace: "nowrap", color: grey ? colors.grayscale["50"] : undefined }}>
      {children}
    </span>
  );
};

type Activity = NonNullable<ActivitiesQuery["activities"]["edges"][number]["node"]>;

export const ActivityDisplay: React.FC<{ activity: Activity }> = ({ activity }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const theme = useTheme();
  const { timezone } = useSiteContext();
  const date = new Date(activity.timestamp);

  // if first name is missing (ie profile service down) set both lastName and avatar to null so that it displays “Someone”
  // and displays (S) where the avatar goes. <Activity /> handles missing avatar nicely :)
  const profile =
    activity.user?.firstName != null
      ? {
          firstName: activity.user.firstName,
          lastName: activity.user.lastName ?? "",
          avatar: activity.user.avatar,
        }
      : {
          firstName: t("common.someone"),
          lastName: "",
        };

  const formatDateTime = (timestamp: DateTime) =>
    `${formatters.dateshort(timestamp, i18n.language)} ${formatters.timeshort(timestamp, i18n.language)}`;

  if (activity.comment != null) {
    return (
      <Activity
        profile={profile}
        title={
          activity.audience === ActivityAudience.Internal
            ? t("requests.activity.toInternalTeam")
            : t("requests.activity.toEveryone")
        }
        date={date}
        message={activity.comment}
      />
    );
  }

  if (category.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.categoryChanged")}
        date={date}
        previousValue={<Tag>{activity.change.from.category.name}</Tag>}
        value={<Tag>{activity.change.to.category.name}</Tag>}
      />
    );
  }

  if (subCategoryUpdated.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.subCategoryChanged")}
        date={date}
        previousValue={<Tag>{activity.change.from.subCategory.name}</Tag>}
        value={<Tag>{activity.change.to.subCategory.name}</Tag>}
      />
    );
  }

  if (subCategoryAdded.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.subCategoryAdded")}
        date={date}
        value={<Tag>{activity.change.to.subCategory.name}</Tag>}
      />
    );
  }

  if (subCategoryRemoved.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.subCategoryRemoved")}
        date={date}
        previousValue={<Tag>{activity.change.from.subCategory.name}</Tag>}
        value={<span>{t("common.none")}</span>}
      />
    );
  }

  // no enum support in runtypes :( https://github.com/pelotom/runtypes/issues/66
  if (
    activity.change?.type === ActivityChangeFieldType.RequestStatus &&
    activity.change.__typename === "ActivitySingleChange" &&
    activity.change.from?.__typename === "ActivityChangeReqMgtStatusValue" &&
    activity.change.from.status != null &&
    activity.change.to?.__typename === "ActivityChangeReqMgtStatusValue" &&
    activity.change.to.status != null
  ) {
    if (activity.change.to.status.type === ReqMgtStatusType.Completed) {
      return (
        <Activity
          profile={profile}
          title={t("requests.activity.requestClosed")}
          date={date}
          previousValue={<StatusTag name={activity.change.from.status.name} type={activity.change.from.status.type} />}
          value={
            <>
              <StatusTag name={activity.change.to.status.name} type={activity.change.to.status.type} />
              <p className="mb-0">
                <RiCalendarEventLine />
                {` ${formatDateTime(DateTime.fromJSDate(date))}`}
              </p>
            </>
          }
        />
      );
    }

    return (
      <Activity
        profile={profile}
        title={t("requests.activity.statusChanged")}
        date={date}
        previousValue={<StatusTag name={activity.change.from.status.name} type={activity.change.from.status.type} />}
        value={<StatusTag name={activity.change.to.status.name} type={activity.change.to.status.type} />}
      />
    );
  }

  if (attachmentsUpdated.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.attachmentAdded", { name: activity.change.to.attachments.key })}
        date={date}
      />
    );
  }

  if (attachmentsRemoved.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.attachmentRemoved", { name: activity.change.to.attachments.key })}
        date={date}
      />
    );
  }

  if (location.guard(activity.change)) {
    const fromBuilding = activity.change.from.space.buildingLevel.building.name;
    const fromLevel = activity.change.from.space.buildingLevel.name;
    const fromSpace = activity.change.from.space.name;
    const toBuilding = activity.change.to.space.buildingLevel.building.name;
    const toLevel = activity.change.to.space.buildingLevel.name;
    const toSpace = activity.change.to.space.name;

    return (
      <Activity
        profile={profile}
        title={t("requests.activity.locationChanged")}
        date={date}
        previousValue={
          <span data-eq-test="location-from">
            {`${fromBuilding}, ${fromLevel}, ${fromSpace}`}
            <br />
          </span>
        }
        value={<span data-eq-test="location-to">{`${toBuilding}, ${toLevel}, ${toSpace}`}</span>}
        icon={RiMapPinLine}
      />
    );
  }

  if (assignee.guard(activity.change)) {
    const { from, to } = activity.change;
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.assigneeChanged")}
        date={date}
        previousValue={
          from != null ? (
            <NameDisplay data-eq-test="name-from">
              <Avatar
                imageUrl={from.profile.avatar}
                firstName={from.profile.firstName}
                lastName={from.profile.lastName}
                size="small"
                className="mr-3"
              />
              {from.profile.firstName} {from.profile.lastName}
            </NameDisplay>
          ) : (
            <NameDisplay data-eq-test="name-from" grey>
              <RiAccountCircleFill color={theme.colors.grayscale["40"]} className="mr-3" size="24px" />
              {t("requests.unassigned")}
            </NameDisplay>
          )
        }
        value={
          <NameDisplay data-eq-test="name-to">
            <Avatar
              imageUrl={to.profile.avatar}
              firstName={to.profile.firstName}
              lastName={to.profile.lastName}
              size="small"
              className="mr-3"
            />
            {to.profile.firstName} {to.profile.lastName}
          </NameDisplay>
        }
      />
    );
  }

  if (reporterUpdated.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.reporterChanged")}
        date={date}
        previousValue={
          <NameDisplay data-eq-test="name-from">
            <Avatar
              imageUrl={activity.change.from.profile.avatar}
              firstName={activity.change.from.profile.firstName}
              lastName={activity.change.from.profile.lastName}
              size="small"
              className="mr-3"
            />
            {activity.change.from.profile.firstName} {activity.change.from.profile.lastName}
          </NameDisplay>
        }
        value={
          <NameDisplay data-eq-test="name-to">
            <Avatar
              imageUrl={activity.change.to.profile.avatar}
              firstName={activity.change.to.profile.firstName}
              lastName={activity.change.to.profile.lastName}
              size="small"
              className="mr-3"
            />
            {activity.change.to.profile.firstName} {activity.change.to.profile.lastName}
          </NameDisplay>
        }
      />
    );
  }

  if (description.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("requests.activity.descriptionChanged")}
        date={date}
        previousValue={activity.change.from.value}
        value={activity.change.to.value}
      />
    );
  }

  if (reported.guard(activity.change) || completed.guard(activity.change)) {
    const title =
      activity.change.field === "reported"
        ? t("requests.activity.dateReportedChanged")
        : t("requests.activity.dateCompletedChanged");
    const fromDateTime = DateTime.fromMillis(activity.change.from.dateTime, { zone: timezone });
    const toDateTime = DateTime.fromMillis(activity.change.to.dateTime, { zone: timezone });

    return (
      <Activity
        profile={profile}
        title={title}
        date={date}
        previousValue={
          <span data-eq-test="datetime-from">
            <RiCalendarEventLine />
            {` ${formatDateTime(fromDateTime)}`}
            <br />
          </span>
        }
        value={
          <span data-eq-test="datetime-to">
            <RiCalendarEventLine />
            {` ${formatDateTime(toDateTime)}`}
          </span>
        }
      />
    );
  }

  if (firstReporter.guard(activity.change)) {
    return <Activity profile={profile} title={t("requests.activity.requestOpened")} date={date} />;
  }

  if (watchers.guard(activity.change)) {
    const oldWatchers = activity.change.fromValues.map((value) => value.profile);
    const newWatchers = activity.change.toValues.map((value) => value.profile);
    const removed = oldWatchers.filter((watcher) => !newWatchers.some(({ uuid }) => watcher.uuid === uuid));
    const added = newWatchers.filter((watcher) => !oldWatchers.some(({ uuid }) => watcher.uuid === uuid));

    return (
      <>
        {added.map(({ uuid, firstName, lastName, avatar }) => (
          <Activity
            profile={{ firstName, lastName, avatar }}
            title={t("requests.activity.watcherAdded")}
            date={date}
            key={uuid}
          />
        ))}
        {removed.map(({ uuid, firstName, lastName, avatar }) => (
          <Activity
            profile={{ firstName, lastName, avatar }}
            title={t("requests.activity.watcherRemoved")}
            date={date}
            key={uuid}
          />
        ))}
      </>
    );
  }

  // unsupported activity: log info to browser console, uncomment `// activity,` to debug locally ;)
  console.info({
    message: "unsupported activity",
    field: activity.change?.field,
    type: activity.change?.type,
    __typename: activity.change?.__typename,
    // activity,
  });

  return null;
};
